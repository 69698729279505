import react from 'react';
import Syncle from '../../shared/Syncle.mjs';
import useNavigate from '../../hooks/use-navigate.jsx';
import UsernodeCommitAssembly from '../fragments/UsernodeCommitAssembly.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { GameStateContext } from '../../app-contexts.jsx';
import { stripZeroValues } from '../../shared/model.mjs';

/**
 * Page for editing a server data node as a set of atomic edits that are
 * saved explicitly all at once.
 */
export default function AtomicServerDataEditor({
    defaultValue,
    expectedType,
    missing = <p>This node no longer exists.</p>,
    path,
    spec,
    vmContext,
}) {
    const { model, sendToServer } = react.useContext(GameStateContext);
    const navigate = useNavigate();

    const currentServerSyncle = react.useMemo(
        () => new Syncle(model.get(path)),
        [model, path],
    );

    const currentDisplaySyncle = react.useMemo(
        () =>
            currentServerSyncle.value
                ? currentServerSyncle
                : new Syncle(defaultValue),
        [currentServerSyncle, defaultValue],
    );

    const onCancel = react.useCallback(
        function onCancel() {
            navigate(-1);
        },
        [navigate],
    );

    const onCommit = react.useCallback(
        function onCommit(newPojoValue) {
            sendToServer({
                type: 'Edit',
                edits: [
                    {
                        op: 'set',
                        key: path,
                        value: stripZeroValues(newPojoValue),
                    },
                ],
            });
            navigate(-1);
        },
        [currentServerSyncle, navigate, sendToServer],
    );

    return useVolatileDataWrapper(
        {
            expectedType,
            currentDisplaySyncle,
            onCancel,
            onCommit,
            spec,
            vmContext,
        },
        currentDisplaySyncle,
        missing,
        ({
            expectedType,
            currentDisplaySyncle,
            onCancel,
            onCommit,
            spec,
            vmContext,
        }) => (
            <UsernodeCommitAssembly
                {...{
                    expectedType,
                    initialValue: currentDisplaySyncle.toPojo(),
                    onCancel,
                    onCommit,
                    spec,
                    vmContext,
                }}
            />
        ),
    );
}
