import NotificationPreferences from '../../components/pages/NotificationPreferences.jsx';

import { useRouteError } from 'react-router-dom';

export default {
    path: '/preferences/notifications',
    Component: NotificationPreferences,
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: () => 'Notifications',
        upUrl: '/',
    },
};
