import icons from '../../utils/icons.jsx';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { Popover } from 'react-tiny-popover';

const ModuleTileBox = styled.button`
    display: grid;
    grid-template-rows: 1fr min-content;
    overflow: hidden;
    padding: 3px;

    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(0, 0, 0, 0.5);
    background-color: rgb(0, 0, 0, 0.1);
    width: 75px;
    height: 75px;

    & > p {
        font-size: small;
        text-align: center;
        line-height: 100%;
        padding-top: 5px;
    }

    & > svg {
        width: 100%;
        height: 100%;
    }
`;

const ModuleTilePopover = styled.div`
    max-width: calc(100vw * 0.7);
    min-width: 0;
    background-color: ${(props) => props.theme.colors.card};
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.35);
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 5px;
`;

export default function ModuleTile({ data }) {
    data = shipUtil.unmessy(data);

    const [showPopover, setShowPopover] = react.useState();
    const Icon = icons[`icons.jsx/plugin`];

    return (
        <Popover
            isOpen={showPopover}
            content={<ModuleDescriptionPopover data={data} />}
            onClickOutside={() => setShowPopover(false)}
        >
            <ModuleTileBox onClick={() => setShowPopover(!showPopover)}>
                <Icon />
                <p
                    css={css`
                        overflow: hidden;
                        overflow-wrap: break-word;
                        hyphens: auto;
                    `}
                >
                    {data.name}
                </p>
            </ModuleTileBox>
        </Popover>
    );
}

function ModuleDescriptionPopover({ data }) {
    const effectsAndRequirements = {};

    for (const [k, v] of Object.entries(data.effects ?? {})) {
        if (!effectsAndRequirements[k]) {
            effectsAndRequirements[k] = {};
        }

        effectsAndRequirements[k].effects = v;
    }

    for (const [k, v] of Object.entries(data.requirements ?? {})) {
        if (!effectsAndRequirements[k]) {
            effectsAndRequirements[k] = {};
        }

        effectsAndRequirements[k].requirements = v;
    }

    return (
        <ModuleTilePopover>
            <h2>{data.name}</h2>
            {Object.entries(effectsAndRequirements).map(
                ([k, { effects, requirements }]) => (
                    <EffectGroup
                        key={k}
                        label={k}
                        effects={effects}
                        requirements={requirements}
                    />
                )
            )}
        </ModuleTilePopover>
    );
}

function EffectGroup({ label, effects = [], requirements = {} }) {
    return (
        <react.Fragment>
            <h3>{label}</h3>
            <ul>
                {Object.entries(requirements).map(([k, v]) => {
                    const propName = shipUtil.humanSynthesizedPropName(k);

                    return (
                        <li key={k}>
                            {`${shipUtil.humanAbsoluteEffect(
                                -v,
                                k
                            )} ${propName}`}
                        </li>
                    );
                })}
                {effects.map((e, i) => {
                    const propName = shipUtil.humanSynthesizedPropName(
                        e.property
                    );

                    return (
                        <react.Fragment key={i}>
                            {e.base && (
                                <li>{`${shipUtil.humanAbsoluteEffect(
                                    e.base,
                                    e.property
                                )} ${propName}`}</li>
                            )}
                            {e.factor && (
                                <li>{`${shipUtil.humanFactorEffect(
                                    e.factor
                                )} ${propName}`}</li>
                            )}
                            {e.bonus && (
                                <li>{`${shipUtil.humanAbsoluteEffect(
                                    e.bonus,
                                    e.property
                                )} bonus ${propName}`}</li>
                            )}
                        </react.Fragment>
                    );
                })}
            </ul>
        </react.Fragment>
    );
}
