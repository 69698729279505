import OverlayLayout from './OverlayLayout.jsx';
import react from 'react';
import styled from '@emotion/styled';
import useSize from '@react-hook/size';

import { css } from '@emotion/react';
import { When } from 'react-if';

const fadeoutCss = css`
    mask-image: linear-gradient(to top, rgb(0, 0, 0, 0.1) 0px, white 40px);
`;

const OverflowIndicatorLimiter = styled(OverlayLayout)`
    display: grid;
    overflow: hidden;
    min-height: 0;
`;

const OverflowIndicatorLayer = styled.div`
    overflow: hidden;
    grid-area: 1 / 1;
`;

const DefaultOverlayLabel = styled.p`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--cardcolor);
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
`;

const OverflowIndicator = react.forwardRef(function OverflowIndicator(
    {
        children,
        className,
        overflowCss = fadeoutCss,
        overlay = <DefaultOverlay />,
    },
    ref,
) {
    const container = ref ?? react.useRef();
    const child = react.useRef();

    if (typeof overlay === 'string') {
        overlay = <DefaultOverlay label={overlay} />;
    }

    const [, containerHeight] = useSize(container);
    const [, childHeight] = useSize(child);

    const overflowing = childHeight > containerHeight;

    return (
        <OverflowIndicatorLimiter className={className} ref={container}>
            <OverflowIndicatorLayer css={overflowing ? [overflowCss] : []}>
                <div ref={child}>{children}</div>
            </OverflowIndicatorLayer>
            <When condition={overflowing}>
                <OverflowIndicatorLayer>{overlay}</OverflowIndicatorLayer>
            </When>
        </OverflowIndicatorLimiter>
    );
});

function DefaultOverlay({ label = 'More' }) {
    return <DefaultOverlayLabel>{label}</DefaultOverlayLabel>;
}

export default OverflowIndicator;
