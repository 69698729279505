import * as shipUtil from './utils.mjs';

const tags = {
    local: {
        jobClaimBlockers(job, ship, { model }) {
            if (!job.get('/from')) {
                return undefined;
            }

            if (
                ship.get('/location/type') === 'docked' &&
                ship.getRef('/location/where')?.$ref ===
                    job.getRef('/from')?.$ref
            ) {
                return undefined;
            }

            return {
                homeStationName: shipUtil.name(model, job.getReferent('/from')),
                jobDescription: shipUtil.humanJobDescription(job.get(), model),
            };
        },
        jobClaimTagToErrorMessage(model, job, tagData) {
            return (
                `Failed to claim job ` +
                `"${
                    tagData?.jobDescription ??
                    shipUtil.humanJobDescription(job, model)
                }"` +
                `. Job is tagged 'local', so it must be claimed while ` +
                `docked at its home station: ` +
                `${tagData?.homeStationName ?? shipUtil.name(model, job.from)}.`
            );
        },
    },
};

function applyTags(ctx, tagNames = [], methodName, ...args) {
    const responses = tagNames
        .map(({ value: t }) => [t, tags[t][methodName]])
        .map(([t, m]) => [t, m ?? (() => undefined)])
        .map(([t, m]) => [t, m(...args, ctx)])
        .reduce((accum, [t, r]) => {
            if (r !== undefined) {
                accum[t] = r;
            }

            return accum;
        }, {});

    return {
        responses,
        get count() {
            return Object.keys(this.responses).length;
        },
        isEmpty() {
            return this.count === 0;
        },
    };
}

const root = {
    jobClaimTagToErrorMessage(model, job, tag, tagData) {
        return tags[tag].jobClaimTagToErrorMessage(model, job, tagData);
    },
    jobClaimBlockers(ctx, job, ship) {
        return applyTags(
            ctx,
            job.get('/tags', []),
            'jobClaimBlockers',
            job,
            ship
        );
    },
};

export default root;
