import OverflowIndicator from '../../OverflowIndicator.jsx';
import * as shipUtils from '../../../../utils/utils.mjs';
import styled from '@emotion/styled';
import usePrettyPrint from '../../../../hooks/use-pretty-print.jsx';

import { ValidationMessage } from '../../standard.jsx';
import { When } from 'react-if';

const ArrSumList = styled.ol`
    list-style-type: disc;
    padding-left: 15px;
`;

const ArrSumItem = styled.li`
    text-align: left;
`;

const Container = styled(OverflowIndicator)`
    max-height: 100px;
`;

export default function ArraySummaryNode({
    path,
    spec,
    data,
    touched,
    validationErrors,
}) {
    const prettyPrint = usePrettyPrint();

    function elTouched(path) {
        return validationErrors[path] && touched.has(path);
    }

    return (
        <Container>
            <ArrSumList>
                {data.map((d, i) => {
                    const elPath = shipUtils.extendJsonPath(path, i);

                    return (
                        <ArrSumItem key={`${i}`}>
                            {prettyPrint(null, spec.elements, d)}
                            <When condition={elTouched(elPath)}>
                                <ValidationMessage>
                                    {`Must be ${validationErrors[elPath]?.expected}.`}
                                </ValidationMessage>
                            </When>
                        </ArrSumItem>
                    );
                })}
            </ArrSumList>
            <When condition={data.length === 0}>
                <p>{spec.$emptyLabel ?? 'Empty'}</p>
            </When>
        </Container>
    );
}
