import * as appContexts from '../../../app-contexts.jsx';
import Logs from '../../../components/pages/Logs.jsx';
import * as shipUtil from '../../../utils/utils.mjs';

import { useContext } from 'react';
import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/ships/:shipId/log',
    Component: () => {
        const { shipId } = useParams();
        const { model } = useContext(appContexts.GameStateContext);
        const shipData = model.get(`/${shipId}`);

        // Something a little weird here since our game model can't
        // distinguish between empty array and null. We have to
        // decide which we "mean" based on whether the ship exists
        // so that we can correctly display "empty" vs "not found".
        return (
            <Logs
                entries={shipData ? shipUtil.unmessy(shipData.log ?? []) : null}
                path={`/${shipId}/log`}
            />
        );
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: (params, model) =>
            (model.get(`/${params.shipId}`)?.name ?? params.shipId) + ' Log',
        upUrl: (params) => `/ships/${params.shipId}`,
    },
};
