import LiveUsernodeEditor from '../fragments/LiveUsernodeEditor.jsx';
import * as shipUtil from '../../utils/utils.mjs';
import Syncle from '../../shared/Syncle.mjs';
import useAccumulateCalls from '../../hooks/use-accumulate-calls.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { GameStateContext } from '../../app-contexts.jsx';
import { useCallback, useContext, useState } from 'react';

/**
 * Page for editing a server data node live with auto-save.
 */
export default function LiveServerDataEditor({
    extraComponents,
    missing,
    parentPath,
    path,
    spec,
}) {
    const { model, sendToServer } = useContext(GameStateContext);

    // Optimistic masks created during sendToServer() below handle keeping the
    // model fluid while network calls are in-flight, but since we debounce
    // rapid changes to the model, we accumulate additional changes _between_
    // network calls here and then merge them into the displayed value.
    const [accumulatedEdits, setAccumulatedEdits] = useState([]);

    const accumulate = useCallback((a, v) => [...a, ...v], []);
    const sendBatch = useCallback(
        (edits) => {
            setAccumulatedEdits([]);
            sendToServer?.({ type: 'Edit', edits }).catch(console.error);
        },
        [sendToServer, setAccumulatedEdits],
    );

    const queueMessage = useAccumulateCalls('500ms', [], accumulate, sendBatch);

    function onEdits(edits) {
        setAccumulatedEdits([...accumulatedEdits, ...edits]);
        queueMessage(
            edits.map((e) => ({
                ...e,
                key: `${path}${e.key}`,
            })),
        );
    }

    return useVolatileDataWrapper(
        { accumulatedEdits, model, onEdits, spec },
        model.get(parentPath),
        missing,
        ({ accumulatedEdits, model, onEdits, spec }) => {
            const sValue = new Syncle(model.get(path));

            for (const edit of accumulatedEdits) {
                shipUtil.applyEdit(sValue, edit);
            }

            return (
                <LiveUsernodeEditor
                    {...{
                        extraComponents,
                        onEdits,
                        spec,
                        value: sValue,
                    }}
                />
            );
        },
    );
}
