import CenterLayout from '../fragments/CenterLayout.jsx';
import CopyToClipboard from '../fragments/CopyToClipboard.jsx';
import LiveServerDataEditor from './LiveServerDataEditor.jsx';
import react from 'react';
import styled from '@emotion/styled';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { specs } from '../../specs.mjs';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import {
    GameStateContext,
    UsernodeDialogContext,
} from '../../app-contexts.jsx';
import { useContext, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { InvisibleButton } from '../fragments/standard.jsx';
import { TiDelete } from 'react-icons/ti';
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill } from 'react-icons/bs';

export default function NotificationPreferences() {
    const { session } = useContext(GameStateContext);

    return useVolatileDataWrapper(
        { accountId: session?.accountId },
        session?.accountId,
        <p>Must log in</p>,
        NotificationServerDataEditor,
    );
}

function NotificationServerDataEditor({ accountId }) {
    if (!accountId) {
        return;
    }

    return (
        <LiveServerDataEditor
            missing={<p>No such user.</p>}
            path={`/${accountId}/preferences/notifications`}
            parentPath={`/${accountId}`}
            spec={specs.accountPreferences.notifications}
            extraComponents={{
                discord: {
                    $element: (
                        <div
                            css={(theme) => css`
                                display: grid;
                                grid-template-columns: 1fr;
                                padding-top: ${theme.metrics
                                    .targetableClearance};
                            `}
                        >
                            <ConnectedDiscordTile />
                        </div>
                    ),
                },
            }}
        />
    );
}

function ConnectedDiscordTile() {
    const discordDetailRequestInFlight = react.useRef();
    const { model, session, sendToServer } = useContext(GameStateContext);
    const usernodeDialog = useContext(UsernodeDialogContext);
    const [discordUserInfo, setDiscordUserInfo] = useState();

    const discordUserId = model.get(
        `/${session?.accountId}/secrets/discordUser`,
    );

    useEffect(() => {
        (async () => {
            if (!discordDetailRequestInFlight.current) {
                if (!discordUserId) {
                    setDiscordUserInfo();
                } else if (
                    !discordUserInfo &&
                    !discordDetailRequestInFlight.current
                ) {
                    discordDetailRequestInFlight.current = true;
                    try {
                        setDiscordUserInfo(
                            await sendToServer({ type: 'FetchDiscordDetails' }),
                        );
                    } catch (e) {
                        console.error('???', e);
                    }
                }
            }
        })().catch(console.error);
    }, [
        discordDetailRequestInFlight,
        discordUserId,
        discordUserInfo,
        sendToServer,
        setDiscordUserInfo,
    ]);

    let tileContent, tileOnClick;

    if (!session?.accountId) {
        tileContent = <p>No logged in account.</p>;
    } else if (!discordUserId) {
        tileOnClick = () =>
            usernodeDialog.displayInfo(<JoinInstructions />, {
                title: 'Link Discord Account',
            });

        tileContent = <p>Link Discord account...</p>;
    } else if (!discordUserInfo) {
        tileContent = <ClipLoader />;
    } else {
        tileContent = (
            <div
                css={css`
                    display: grid;
                    grid-template-columns: 50px 1fr;
                `}
            >
                <div
                    css={css`
                        border-radius: 50%;
                        overflow: hidden;
                        width: 50px;
                        height: 50px;
                    `}
                >
                    <img
                        width={50}
                        height={50}
                        src={discordUserInfo.avatarUrl}
                    />
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        padding-left: 10px;
                        font-weight: bold;
                    `}
                >
                    {discordUserInfo.displayName}
                </div>
            </div>
        );
    }

    const Component = tileOnClick ? InvisibleButton : styled.div``;
    const tile = (
        <Component
            css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-left: 15px;
                width: calc(max(70%, 40px));
                height: 80px;
                background-color: rgb(230, 230, 230);
                border-color: rgb(150, 150, 150);
                border-width: 2px;
                border-style: solid;
                border-radius: 5px;
            `}
            onClick={tileOnClick}
        >
            {tileContent}
        </Component>
    );

    return (
        <div
            css={css`
                display: flex;
            `}
        >
            <div
                css={css`
                    flex-grow: 1;
                    flex-basis: 0;
                `}
            />
            {tile}
            <If condition={discordUserId}>
                <Then>
                    <InvisibleButton
                        css={css`
                            display: flex;
                            justify-content: space-around;
                            align-items: center;

                            color: rgb(150, 150, 150);
                            font-size: 1.5rem;
                            flex-grow: 1;
                            flex-basis: 0;
                        `}
                        onClick={async () => {
                            await sendToServer({ type: 'UnlinkDiscord' });
                        }}
                    >
                        <TiDelete />
                    </InvisibleButton>
                </Then>
                <Else>
                    <div
                        css={css`
                            flex-grow: 1;
                            flex-basis: 0;
                        `}
                    />
                </Else>
            </If>
        </div>
    );
}

function JoinInstructions() {
    const { model, sendToServer, session } = useContext(GameStateContext);
    const accountId = session.accountId;
    const discordLinkToken = model.get(
        `/${accountId}/secrets/tokens/discordLink/token`,
    );
    const discordUserId = model.get(
        `/${session.accountId}/secrets/discordUser`,
    );

    useEffect(() => {
        if (!discordUserId && !discordLinkToken) {
            sendToServer({ type: 'InitiateDiscordLink' });
        }
    }, [discordLinkToken, discordUserId, sendToServer]);

    return (
        <If condition={discordUserId}>
            <Then>
                <CenterLayout>
                    Discord account successfully linked!
                </CenterLayout>
            </Then>
            <Else>
                <Instructions discordLinkToken={discordLinkToken} />
            </Else>
        </If>
    );
}

function EqualRow({ children }) {
    return (
        <li
            css={css`
                height: 7rem;
            `}
        >
            <div>{children}</div>
        </li>
    );
}

function Instructions({ discordLinkToken }) {
    return (
        <div
            css={css`
                padding-top: 50px;
            `}
        >
            <EqualRow>
                <Paragraph margin={<Bs1CircleFill />}>
                    Join the Aegis Discord:
                </Paragraph>
                <Paragraph
                    margin={
                        <CopyToClipboard text='https://discord.gg/Rm6quuuD29' />
                    }
                >
                    <a
                        css={(theme) => css`
                            display: block;
                            color: blue;
                            font-weight: bold;
                            font-size: small;
                            padding-top: ${theme.metrics.targetableClearance};
                            padding-bottom: ${theme.metrics
                                .targetableClearance};
                        `}
                        href='https://discord.gg/Rm6quuuD29'
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        {'https://discord.gg/Rm6quuuD29'}
                    </a>
                </Paragraph>
            </EqualRow>
            <EqualRow>
                <Paragraph margin={<Bs2CircleFill />}>
                    Start a new DM with the Aegis Ring Notification Bot
                </Paragraph>
            </EqualRow>
            <EqualRow>
                <Paragraph margin={<Bs3CircleFill />}>
                    Chat this to the bot:
                </Paragraph>
                <Paragraph
                    margin={
                        discordLinkToken ? (
                            <CopyToClipboard
                                text={`/aegis-link ${discordLinkToken}`}
                            />
                        ) : null
                    }
                >
                    <If condition={discordLinkToken}>
                        <Then>
                            <div
                                css={(theme) => css`
                                    padding-top: ${theme.metrics
                                        .targetableClearance};
                                    padding-bottom: ${theme.metrics
                                        .targetableClearance};
                                `}
                            >
                                <pre
                                    css={css`
                                        background-color: rgb(220, 220, 220);
                                        padding: 3px;
                                    `}
                                >{`/aegis-link ${discordLinkToken}`}</pre>
                            </div>
                        </Then>
                        <Else>
                            <ClipLoader />
                        </Else>
                    </If>
                </Paragraph>
            </EqualRow>
        </div>
    );
}

function Paragraph({ margin, children }) {
    return (
        <div
            css={css`
                display: grid;
                grid-template-columns: 50px 1fr;
            `}
        >
            <CenterLayout>{margin}</CenterLayout>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    height: 100%;
                `}
            >
                <div>{children}</div>
            </div>
        </div>
    );
}
