import ms from 'ms';

import { useCallback, useRef } from 'react';

function toMs(x) {
    return typeof x === 'number' ? x : ms(x);
}

export default function useAccumulateCalls(
    interval,
    initialFn,
    accumFn,
    batchFn,
    { debounceOpts } = {},
) {
    const doInitial = useCallback(
        () => (typeof initialFn === 'function' ? initialFn() : initialFn),
        [initialFn],
    );

    const accumulated = useRef(doInitial());
    const timer = useRef();

    return useCallback(
        (next) => {
            accumulated.current = accumFn(accumulated.current, next);

            if (!timer.current) {
                timer.current = setTimeout(() => {
                    try {
                        batchFn(accumulated.current);
                    } catch (e) {
                        console.log('Error processing batch', e);
                    }

                    accumulated.current = doInitial();
                    timer.current = null;
                }, toMs(interval));

                if (debounceOpts?.leading) {
                    batchFn(accumulated.current);
                    accumulated.current = doInitial();
                }
            }
        },
        [accumFn, batchFn, debounceOpts, doInitial, interval],
    );
}
