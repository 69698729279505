import LiveServerDataEditor from '../../components/pages/LiveServerDataEditor.jsx';
import * as specs from '../../specs.mjs';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/corporations/:corpId/preferences',
    Component: () => {
        const { corpId } = useParams();
        return (
            <LiveServerDataEditor
                missing={<p>No such corporation.</p>}
                path={`/${corpId}/preferences`}
                parentPath={`/${corpId}`}
                spec={specs.corporationPreferences}
            />
        );
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: () => 'Corporate Preferences',
        upUrl: '/',
    },
};
