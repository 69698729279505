import react from 'react';

const SectionContext = react.createContext();

const builders = {
    1: ({ children, className }) => <h1 className={className}>{children}</h1>,
    2: ({ children, className }) => <h2 className={className}>{children}</h2>,
    3: ({ children, className }) => <h3 className={className}>{children}</h3>,
    4: ({ children, className }) => <h4 className={className}>{children}</h4>,
    5: ({ children, className }) => <h5 className={className}>{children}</h5>,
    6: ({ children, className }) => <h6 className={className}>{children}</h6>,
};

export function Headline({ children, className }) {
    const [level, headline] = react.useContext(SectionContext);

    const H = builders[level];
    const result = <H className={className}>{children}</H>;

    headline.current = result;

    return result;
}

export default function Section({ children, className, ...otherProps }) {
    const [parentLevel, parentHeadline] = react.useContext(SectionContext) ?? [
        1,
        { current: true },
    ];

    const myHeadline = react.useRef();

    if (!parentHeadline.current) {
        throw new Error('Parent section context has no headline!');
    }

    return (
        <SectionContext.Provider value={[parentLevel + 1, myHeadline]}>
            <section className={className} {...otherProps}>
                {children}
            </section>
        </SectionContext.Provider>
    );
}
