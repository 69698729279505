import react from 'react';
import ShipTile from '../fragments/ship-tile';
import styled from '@emotion/styled';
import useRegistration from '../../hooks/use-registration.jsx';

import { GameStateContext } from '../../app-contexts.jsx';
import { LIList } from '../fragments/ListItem.jsx';
import { useMemo } from 'react';

const ShipsContainer = styled.div``;

export default function Ships({ corpId }) {
    const { model } = react.useContext(GameStateContext);
    const ships = useMemo(
        () =>
            model
                ?.getMatches?.({
                    kind: 'ship',
                    owner: { $rootId: `/${corpId}` },
                })
                ?.map((id) => [id, model.get(`/${id}`)])
                ?.sort(([id1, { name: name1 }], [id2, { name: name2 }]) =>
                    name1 < name2 ? -1 : name1 > name2 ? 1 : id1 < id2 ? -1 : 1,
                ) ?? [],
        [corpId, model],
    );

    const annotations = useMemo(
        () => buildAnnotations(ships, model),
        [ships, model],
    );

    useRegistration('map', { annotations });

    return (
        <ShipsContainer>
            <LIList>
                {ships.map(([id, data]) => (
                    <ShipTile key={id} id={id} data={data} />
                ))}
            </LIList>
        </ShipsContainer>
    );
}

function initializeName(n) {
    return n
        .split(' ')
        .map(([i]) => i)
        .join('');
}

function buildAnnotations(ships, model) {
    const result = [];

    for (const s of ships.map(([, d]) => d)) {
        if (s.location?.type === 'docked') {
            const station = model.get(s.location.where);
            const label = station?.name
                ? `${initializeName(s.name)} @ ${station.name}`
                : initializeName(s.name);

            result.push({
                location: s.location.where,
                label: label,
            });
        } else if (s.location?.type === 'traveling') {
            const station = model.get(s.location.to);
            const label = station?.name
                ? `${initializeName(s.name)} -> ${station.name}`
                : initializeName(s.name);

            result.push({
                location: s.location.to,
                label: label,
            });

            result.push({
                start: s.location.from,
                end: s.location.to,
                style: 'travel',
            });
        }
    }

    return result;
}
