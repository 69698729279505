import ArraySummaryNode from './ArraySummaryNode.jsx';

import { isTouchDevice } from '../../../../utils/utils.mjs';

export default function buildArraySummaryNode(
    spec,
    value,
    expectedType,
    createElement,
    onChange,
    ctx,
) {
    if (!Array.isArray(value)) {
        value = [];
    }

    async function onClick() {
        const [, , breadcrumbs] = ctx.displayContext.buildDisplayRoot(value);

        const title = breadcrumbs.join(' > ');
        const querySpec = {
            $array: {
                elements: {
                    ...spec.elements,
                },
            },
        };

        const result = await ctx.queryUser(querySpec, value, {
            context: ctx.context,
            expectedType: ctx.expectedType,
            lexicalContextStack: ctx.lexicalContextStack,
            title,
        });

        if (result !== undefined) {
            onChange(result);
        }
    }

    return [
        // eslint-disable-next-line react/jsx-key
        <ArraySummaryNode
            spec={spec}
            data={value}
            path={ctx.path}
            validationErrors={ctx.validationErrors}
            touched={ctx.touched}
        />,
        {
            note: isTouchDevice() ? 'Tap to Edit' : 'Click to Edit',
            structured: true,
            onClick,
        },
        value,
    ];
}
