import useValue from './use-value.jsx';

import { createContext, useEffect, useMemo, useRef, useState } from 'react';

export const registryContext = createContext();

export default function useRegistryRoot() {
    const bus = useRef(new EventTarget());
    const db = useRef({});

    const registry = useMemo(
        () => ({
            useRegistration(rName, data) {
                const instance = useValue(data);

                useEffect(() => {
                    if (!db.current[rName]) {
                        db.current[rName] = new Set();
                    }

                    const registration = [instance];

                    db.current[rName].add(registration);
                    bus.current.dispatchEvent(new Event(rName));

                    return () => db.current[rName].delete(registration);
                }, [rName, instance]);
            },

            useRegistry(rName) {
                const [values, setValues] = useState([]);

                useEffect(() => {
                    const updateValue = () => {
                        setValues(
                            [...(db.current[rName] ?? new Set()).values()].map(
                                ([x]) => x,
                            ),
                        );
                    };
                    bus.current.addEventListener(rName, updateValue);
                    return () =>
                        bus.current.removeEventListener(rName, updateValue);
                }, [rName]);

                return values;
            },
        }),
        [bus, db],
    );

    return registry;
}
