import baseLayout from './routes/base-layout.jsx';
import * as reactRouter from 'react-router-dom';

import { Navigate } from 'react-router-dom';

export default function buildRouter(fn = reactRouter.createBrowserRouter) {
    return fn([
        baseLayout,
        {
            path: '*',
            Component: () => {
                return <Navigate to='/ships' replace />;
            },
            ErrorBoundary: () => {
                const e = reactRouter.useRouteError();
                console.error(e);
                return <p>{e.message}</p>;
            },
        },
    ]);
}
