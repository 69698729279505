import Modal from 'react-modal';
import AegisLogo from '../../assets/aegis-dark.svg?react';
import Link from './Link.jsx';
import Section, { Headline as BaseHeadline } from './Section.jsx';

import { css } from '@emotion/react';

import { AiOutlineNotification } from 'react-icons/ai';

export default function Sidebar({ onRequestClose }) {
    return (
        <Modal
            onRequestClose={() => onRequestClose()}
            isOpen={true}
            css={(theme) => css`
                height: 100%;
                width: 85%;
                background-color: ${theme.colors.card};
                box-shadow: 5px 0px 15px rgb(0, 0, 0, 0.4);
            `}
        >
            <Section>
                <div
                    css={(theme) => css`
                        background-color: ${theme.colors.primary};
                        padding-bottom: 10px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <AegisLogo
                            css={css`
                                max-height: unset;
                                margin: 7px;
                            `}
                        />
                        <BaseHeadline
                            css={css`
                                color: black;
                                margin: 0;
                                padding: 0;
                            `}
                        >
                            Aegis Exoplanet Company
                        </BaseHeadline>
                    </div>
                </div>
                <ul>
                    <Item
                        onRequestClose={onRequestClose}
                        Icon={AiOutlineNotification}
                        label='Notifications'
                        linkTo='/preferences/notifications'
                    />
                </ul>
            </Section>
        </Modal>
    );
}

function Item({ className, onRequestClose, Icon, label, linkTo }) {
    return (
        <Link
            className={className}
            onClick={(e) => {
                onRequestClose(linkTo);
                e.preventDefault();
                return true;
            }}
        >
            <li
                css={(theme) => css`
                    display: flex;
                    align-items: center;
                    padding-top: ${theme.metrics.targetableClearance};
                    padding-bottom: ${theme.metrics.targetableClearance};
                `}
            >
                <Icon
                    css={(theme) => css`
                        font-size: 2rem;
                        color: rgb(175, 175, 175);
                        padding-left: ${theme.metrics.targetableClearance};
                        padding-right: ${theme.metrics.targetableClearance};
                    `}
                />
                <span
                    css={css`
                        font-weight: bold;
                    `}
                >
                    {label}
                </span>
            </li>
        </Link>
    );
}
