import * as appContexts from '../../../../app-contexts.jsx';
import Jobs from '../../../../components/pages/Jobs.jsx';
import * as shipUtil from '../../../../utils/utils.mjs';
import * as tasks from '../../../../hooks/task-initiators.jsx';
import useNavigate from '../../../../hooks/use-navigate.jsx';

import { ErrorBoundary } from 'react-error-boundary';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';

export default {
    path: '/jobs',
    Component: () => {
        const taskInitiator = tasks.useNavigateInitiator();

        const [params] = useSearchParams();
        const { terminal } = useContext(appContexts.GameStateContext);
        const navigate = useNavigate();

        const rawAction = useMemo(
            () => shipUtil.jsonOrNothing(params.get('action') ?? ''),
            [params],
        );

        const fn = useCallback(
            (id) => {
                terminal(
                    shipUtil.deepTransform(rawAction, (v) => {
                        if (typeof v === 'string') {
                            return v.replace(
                                /\{\{jobId\}\}/g,
                                id?.$ref?.substring(1),
                            );
                        }

                        return v;
                    }),
                );

                navigate(
                    shipUtil.jsonOrNothing(params.get('returnTo') ?? '') ?? -1,
                );
            },
            [terminal, rawAction, navigate, params],
        );

        return (
            <Jobs
                onSelect={rawAction ? fn : undefined}
                taskInitiator={taskInitiator}
            />
        );
    },
    errorElement: <ErrorBoundary />,
    handle: {},
};
