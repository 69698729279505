import styled from '@emotion/styled';

import { ValidationMessage } from '../../standard.jsx';

export const MyValidationMessage = styled(ValidationMessage)`
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 7px;
    margin-bottom: 5px;
`;

export const TupleNodeSpacer = styled.div`
    padding: 10px;
`;

export const TupleNodeContainer = styled.ol`
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;

    & > * {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        max-width: unset;
    }
`;
