import AtomicServerDataEditor from '../../../components/pages/AtomicServerDataEditor.jsx';
import * as shipUtil from '../../../utils/utils.mjs';
import * as specs from '../../../specs.mjs';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/ships/:shipId/orders/:orderId',
    Component: () => {
        const { orderId, shipId } = useParams();

        return (
            <AtomicServerDataEditor
                defaultValue={
                    orderId === 'new'
                        ? shipUtil.buildDNode('wait', {
                              duration: 9000,
                          })
                        : null
                }
                missing={<p>This order no longer exists.</p>}
                path={
                    orderId === 'new'
                        ? `/${shipId}/orders/-`
                        : `/${shipId}/orders/${orderId}`
                }
                spec={specs.shipOrder}
            />
        );
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: (params, model) =>
            (model.get(`/${params.shipId}`)?.name ?? params.shipId) + ' Order',
        upUrl: (params) => `/ships/${params.shipId}/orderQueue`,
    },
};
