import ShipOrderQueue from '../../../components/pages/ShipOrderQueue.jsx';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/ships/:shipId/orderQueue',
    Component: () => {
        const { shipId } = useParams();
        return <ShipOrderQueue shipId={shipId} />;
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: (params, model) =>
            (model.get(`/${params.shipId}`)?.name ?? params.shipId) +
            ' Order Queue',
        upUrl: (params) => `/ships/${params.shipId}`,
    },
};
