import react from 'react';
import * as reactRouter from 'react-router-dom';
import * as shipUtil from '../utils/utils.mjs';
import useWhiteboard from './use-whiteboard.jsx';

import { useNavigate as useRrdNavigate } from 'react-router-dom';
import { wbToString } from './use-whiteboard.jsx';

export default function useNavigate(whiteboard, preserveWbKeys) {
    const navigate = useRrdNavigate();
    const [wbVars] = useWhiteboard(whiteboard);
    const preserved = {};

    for (const k of shipUtil.coerceToArray(preserveWbKeys)) {
        if (k in wbVars) {
            preserved[k] = wbVars[k];
        }
    }

    const preservedToString = wbToString(preserved);
    const qs = preservedToString ? `?${preservedToString}` : '';

    const { pathname } = reactRouter.useLocation();

    return react.useCallback(
        (to, { replace, state } = {}) => {
            navigate(
                shipUtil.isNil(to)
                    ? `${pathname}${qs}`
                    : typeof to === 'number'
                    ? to
                    : `${to}${qs}`,
                {
                    replace,
                    state: {
                        ...state,
                        back: pathname,
                    },
                }
            );
        },
        [navigate, pathname, qs]
    );
}
