import icons from '../../utils/icons.jsx';
import Link from '../fragments/Link.jsx';
import ListItem from '../fragments/ListItem.jsx';
import react from 'react';
import * as reactRouter from 'react-router-dom';
import styled from '@emotion/styled';

import { GameStateContext } from '../../app-contexts.jsx';
import { LIList } from '../fragments/ListItem.jsx';

const StationsItem = styled.li`
    list-style-type: none;
`;

export default function Stations() {
    const { model } = react.useContext(GameStateContext);
    const stations = react.useMemo(
        () =>
            (model.getMatches({ kind: 'station' }) ?? []).map((id) => [
                id,
                model.get(`/${id}`),
            ]) ?? [],
        [model],
    );
    const location = reactRouter.useLocation();

    return (
        <LIList>
            {stations.map(([id, data]) => (
                <StationsItem key={id}>
                    <Link
                        to={`/stations/${id}${location.search}`}
                        state={{ back: location.pathname }}
                    >
                        <Station id={id} data={data} />
                    </Link>
                </StationsItem>
            ))}
        </LIList>
    );
}

function Station({ id, data }) {
    const Icon = icons['icons.jsx/station'];
    const { model } = react.useContext(GameStateContext);

    return (
        <ListItem
            title={data.name}
            info={[<p key={0}>{model.get(`/${id}/faction/name`) ?? ''}</p>]}
            icon={<Icon />}
        />
    );
}
