import LinesEllipsis from 'react-lines-ellipsis';
import Link from '../fragments/Link.jsx';
import react from 'react';
import * as reactRouter from 'react-router-dom';
import Section, { Headline } from '../fragments/Section.jsx';
import styled from '@emotion/styled';
import useWhiteboard from '../../hooks/use-whiteboard.jsx';
import Select from 'react-select';
import TitlePortal from '../fragments/TitlePortal.jsx';
import TopRightPortal from '../fragments/TopRightPortal.jsx';

import { css } from '@emotion/react';
import { GameStateContext } from '../../app-contexts.jsx';
import { PropertyTable, Entry } from '../fragments/PropertyTable.jsx';
import { FloatingButton, Tab, TabStrip } from '../fragments/standard.jsx';
import { When } from 'react-if';
import { LiaToolsSolid } from 'react-icons/lia';

const EntrypointContainer = styled(Section)`
    position: relative;
    display: grid;
    grid-template-rows: min-content 1fr;
    height: 100%;
    overflow: scroll;
`;

const EntrypointContent = styled.div`
    grid-area: 2 / 1;

    display: grid;
    position: relative;
    overflow: scroll;
    height: 100%;
`;

export default function EntrypointLayout() {
    const { model } = react.useContext(GameStateContext);
    const { whiteboard } = reactRouter.useOutletContext() ?? {};

    const [wb, setWb] = useWhiteboard(whiteboard);

    const ships = react.useMemo(
        () =>
            (model?.getMatches?.({ kind: 'ship' }) ?? [])
                .map((id) => [id, model.get(`/${id}`)])
                .filter(([, { readPermission }]) => readPermission)
                .sort(([id1, { name: name1 }], [id2, { name: name2 }]) =>
                    name1 < name2 ? -1 : name1 > name2 ? 1 : id1 < id2 ? -1 : 1,
                ),
        [model],
    );

    const representedCorporations = react.useMemo(
        () =>
            ships.reduce((accum, [, { owner }]) => {
                if (owner) {
                    if (!accum[owner?.$ref]) {
                        accum[owner?.$ref] = {
                            ...model.get(owner),
                            shipCount: 0,
                        };
                    }

                    accum[owner?.$ref].shipCount++;
                }

                return accum;
            }, {}),
        [model, ships],
    );

    react.useEffect(() => {
        if (!representedCorporations[`/${wb.corp}`]) {
            if (Object.keys(representedCorporations).length > 0) {
                setWb(
                    {
                        corp: Object.keys(representedCorporations)[0].substring(
                            1,
                        ),
                    },
                    { replace: true },
                );
            }
        }
    }, [representedCorporations, setWb, wb]);

    const corpData = representedCorporations[`/${wb.corp}`];

    const selectOptions = representedCorporations
        ? Object.entries(representedCorporations).map(([k, v]) => ({
              value: k.substring(1),
              label: v.name,
          }))
        : [];

    return (
        <EntrypointContainer>
            <Headline
                css={css`
                    display: flex;
                    align-items: center;
                    align-self: stretch;
                `}
            >
                <TitlePortal>
                    <Select
                        placeholder='Loading...'
                        noOptionsMessage={() => 'No corporations'}
                        isSearchable={false}
                        options={selectOptions}
                        value={selectOptions.find(
                            ({ value }) => value === wb.corp,
                        )}
                        onChange={({ value }) => setWb({ corp: value })}
                        components={{
                            Option: SelectOptionComponentNoWrap,
                            SingleValue: SelectOptionComponent,
                        }}
                        styles={{
                            control: (s) => ({
                                ...s,
                                backgroundColor: 'unset',
                                borderStyle: 'none',
                                borderWidth: 0,
                            }),
                            dropdownIndicator: (s) => ({
                                ...s,
                                color: 'black',
                            }),
                            indicatorSeparator: (s) => ({
                                ...s,
                                display: 'none',
                            }),
                            noOptionsMessage: (s) => ({
                                ...s,
                                fontSize: '1rem',
                            }),
                            valueContainer: (s) => ({
                                ...s,
                                fontSize: 0,
                                minWidth: '0',
                            }),
                        }}
                    />
                </TitlePortal>
                <TopRightPortal>
                    <Link to={`/corporations/${wb.corp}/preferences`}>
                        <FloatingButton>
                            <LiaToolsSolid />
                        </FloatingButton>
                    </Link>
                </TopRightPortal>
            </Headline>
            <div
                css={css`
                    grid-area: 1 / 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <When condition={corpData}>
                    <div
                        css={css`
                            position: relative;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            align-self: stretch;
                            height: 2rem;
                        `}
                    >
                        <PropertyTable aria-label='Corporation Overview'>
                            <Entry label='Credits'>
                                {new Intl.NumberFormat().format(
                                    corpData?.credits,
                                )}
                            </Entry>
                            <Entry label='Fleet'>
                                {corpData?.shipCount}{' '}
                                {corpData?.shipCount === 1 ? 'ship' : 'ships'}
                            </Entry>
                        </PropertyTable>
                    </div>
                </When>
                <TabStrip css={css``}>
                    <TabTo path='/ships' text='Ships' />
                    <TabTo path='/jobs' text='Jobs' />
                    <TabTo path='/stations' text='Stations' />
                </TabStrip>
            </div>
            <EntrypointContent>
                <reactRouter.Outlet context={{ whiteboard }} />
            </EntrypointContent>
        </EntrypointContainer>
    );
}

function primaryIf(path) {
    if (window.location.pathname === path) {
        return 'primary';
    }

    return undefined;
}

function SelectOptionComponentNoWrap(props) {
    return <SelectOptionComponent {...props} wrap={true} />;
}

function SelectOptionComponent({ children, innerRef, innerProps, isSelected }) {
    return (
        <div
            css={(theme) => css`
                display: flex;
                font-size: 1.2rem;
                line-height: 1.2rem;
                align-items: center;
                column-gap: 5px;
                padding-left: 3px;
                background-color: ${isSelected
                    ? theme.colors.primary
                    : 'unset'};
                min-width: 0;
                height: 2.5rem;
                font-weight: bold;
            `}
            ref={innerRef}
            {...innerProps}
        >
            <LinesEllipsis text={children} maxLine={2} />
        </div>
    );
}

function TabTo({ text, path }) {
    const location = reactRouter.useLocation();

    let result = <Tab variant={primaryIf(path)}>{text}</Tab>;

    if (location.pathname !== path) {
        result = <Link to={`${path}${location.search}`}>{result}</Link>;
    }

    return result;
}
