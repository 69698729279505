import react from 'react';
import * as shipUtil from '../../../../utils/utils.mjs';
import styled from '@emotion/styled';
import usePrettyPrint from '../../../../hooks/use-pretty-print.jsx';

import { AlertIco } from '../../standard.jsx';

export const Placeholder = styled.a`
    background-color: rgb(250, 250, 250);
    border-color: rgb(128, 128, 128);
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    box-shadow: inset 2px 2px 5px rgb(0, 0, 0, 0.2);

    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;

    line-height: calc(1rem + 15px);
    border-bottom-width: 3px;
    border-bottom-color: ${(props) => props.theme.colors.primary};
`;

export const Shorthand = styled.div`
    padding-left: 1.5rem;
    text-indent: -1.5rem;
`;

export const TopLevelContent = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`;

export const DiscrimNodeTypeSelect = styled.select``;

export function ShorthandDiscriminatorNode({
    onPivotClick,
    onPlaceholderClick,
    spec,
    path,
    data,
    touched,
    type,
    validationErrors,
}) {
    const prettyPrint = usePrettyPrint();

    function containedValidationError(path) {
        if ((touched.has(path) || touched.has('')) && validationErrors[path]) {
            return validationErrors[path];
        }

        let result;
        for (const errorPath of touched) {
            if (errorPath.startsWith(path) && validationErrors[errorPath]) {
                result = errorPath;
                break;
            }
        }

        return result;
    }

    return (
        <Shorthand>
            {spec[type].$shorthand.map((el) => {
                if (el.pivot) {
                    const errorMessage =
                        touched.has(path) && validationErrors[path]
                            ? validationErrors[path]
                            : null;

                    return (
                        <react.Fragment key='$pivot'>
                            <Placeholder onClick={() => onPivotClick(el.pivot)}>
                                {errorMessage && <AlertIco />}
                                {el.pivot}
                            </Placeholder>
                        </react.Fragment>
                    );
                }

                if (el.placeholder) {
                    const errorMessage = containedValidationError(
                        `/${el.placeholder}`,
                    );

                    if (!spec?.[type]?.[el.placeholder]) {
                        throw new Error(
                            'No such field: ' +
                                el.placeholder +
                                '\n\n' +
                                shipUtil.debugString(spec?.[type]),
                        );
                    }

                    const subshorthand = shipUtil.isNil(data?.[el.placeholder])
                        ? '<choose>'
                        : prettyPrint(
                              el.placeholder,
                              spec[type][el.placeholder],
                              data?.[el.placeholder],
                          );

                    return (
                        <react.Fragment key={el.placeholder}>
                            <Placeholder
                                role='button'
                                variant={errorMessage ? 'invalid' : 'normal'}
                                onClick={() =>
                                    onPlaceholderClick(type, el.placeholder)
                                }
                            >
                                {errorMessage && <AlertIco />}
                                {subshorthand}
                            </Placeholder>
                        </react.Fragment>
                    );
                }

                return el;
            })}
        </Shorthand>
    );
}
