import hash from 'object-hash';
import icons from '../../utils/icons.jsx';
import InfoButton from '../fragments/InfoButton.jsx';
import Link from '../fragments/Link.jsx';
import Model from '../../shared/model.mjs';
import OptionalGroup from '../fragments/OptionalGroup.jsx';
import react from 'react';
import ResourceBoundSubsystem from '../fragments/ResourceBoundSubsystem.jsx';
import simulateOrder from '../../shared/simulate-order.mjs';
import * as specs from '../../specs.mjs';
import styled from '@emotion/styled';
import * as shipUtil from '../../utils/utils.mjs';
import tags from '../../shared/tags.mjs';
import useNavigate from '../../hooks/use-navigate.jsx';
import useRegistration from '../../hooks/use-registration.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';
import VerticalMainLayout from '../fragments/VerticalMainLayout.jsx';

import { css } from '@emotion/react';
import { decompile as decompileTantive } from '../../utils/tantive.mjs';
import { searchSpec as jobsSearchSpec } from './utils/Jobs/jobs.jsx';
import { GameStateContext } from '../../app-contexts.jsx';
import { Reorder, useDragControls } from 'framer-motion';
import { GrDrag } from 'react-icons/gr';
import {
    Button,
    FloatingButton,
    HelpArticle,
    ValidationMessage,
} from '../fragments/standard.jsx';
import { GoPlus } from 'react-icons/go';
import {
    buildAnnotations as buildShipPageAnnotations,
    missingMessage,
} from './utils/Ship/ship.jsx';
import { Popover } from 'react-tiny-popover';
import { Else, If, Then, When } from 'react-if';

const Container = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
    display: grid;
`;

const AddButtonLink = styled(Link)`
    position: absolute;
    right: 20px;
    bottom: 20px;
`;

const OrderQueueTile = styled.div`
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    align-items: center;
`;

const OrderQueueTileIcon = styled.div`
    height: 50px;
    width: 50px;

    & svg {
        height: 100%;
        width: 100%;
    }
`;

const OrderQueueTileDragHandle = styled.div`
    display: flex;
    align-items: center;
    touch-action: none;
`;

const OrderQueueTileClickableArea = styled(Link)`
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 0;
`;

export default function ShipOrderQueue(props) {
    const { shipId } = props;
    const { model, sendToServer } = react.useContext(GameStateContext);
    const shipData = model.get(`/${shipId}`);
    const mapAnnotations = buildShipPageAnnotations(shipId, model);

    const shipOrders = react.useMemo(
        () => model.handle(`/${shipId}/orders`),
        [model, shipId],
    );
    const shipOrdersValue = react.useMemo(
        () => shipOrders.get('', [], shipOrders),
        [shipOrders],
    );
    const shipOrdersHash = react.useMemo(
        () => hash(shipOrdersValue),
        [shipOrdersValue],
    );

    const [simulatedState, { warnings = {} } = {}] = useSimulatedState(
        shipId,
        shipOrdersHash,
    );

    const [orderSequence, setOrderSequence] = react.useState([]);

    react.useEffect(() => {
        setOrderSequence(shipOrdersValue.map(({ id }) => id));
    }, [shipOrdersValue.map(({ id }) => id).join(',')]);

    let locationRef =
        shipData?.location?.type === 'traveling'
            ? shipData.location?.from?.$ref
            : shipData?.location?.type === 'docked'
              ? shipData.location?.where?.$ref
              : undefined;
    let lastLocationRef = locationRef;
    let stopNumber = 1;
    for (const id of orderSequence) {
        if (locationRef !== lastLocationRef) {
            const data = model.get(locationRef);

            mapAnnotations.push({
                location: { $ref: locationRef },
                label: `(${stopNumber}) ${data?.name ?? ''}`,
            });

            mapAnnotations.push({
                start: { $ref: lastLocationRef },
                end: { $ref: locationRef },
                style: 'arrow',
            });

            lastLocationRef = locationRef;
            stopNumber++;
        }

        const order = shipOrders.get(`/${id}`);
        if (order?.travel) {
            locationRef = order.travel?.to?.$ref;
        }
    }

    if (locationRef !== lastLocationRef) {
        mapAnnotations.push({
            location: { $ref: locationRef },
            label: `${stopNumber}`,
        });

        mapAnnotations.push({
            start: { $ref: lastLocationRef },
            end: { $ref: locationRef },
            style: 'arrow',
        });

        lastLocationRef = locationRef;
        stopNumber++;
    }

    function reorder(newOrders) {
        const edits = [];
        const workingOrder = [...orderSequence];
        for (let i = 0; i < newOrders.length; i++) {
            const actual = newOrders[i];

            if (actual !== workingOrder[i]) {
                edits.push({
                    op: 'reorder',
                    key: `/${shipId}/orders`,
                    target: actual,
                    pivot: workingOrder[i - 1] ?? null,
                    direction: 'after',
                });

                const originalPosition = workingOrder.findIndex(
                    (id) => id === actual,
                );
                workingOrder.splice(originalPosition, 1);
                workingOrder.splice(i, 0, actual);
            }
        }

        setOrderSequence([...newOrders]);

        sendToServer({ type: 'Edit', edits });
    }

    const navigate = useNavigate(props.whiteboard, ['corp']);

    function onStationClick(id) {
        const params = new URLSearchParams({
            returnTo: `"/ships/${shipId}/orderQueue"`,
            action: JSON.stringify(
                shipUtil.buildDNode('appendOrder', {
                    ship: shipId,
                    queue: 'active',
                    order: shipUtil.buildDNode('claimJob', {
                        which: {
                            $ref: '/{{jobId}}',
                        },
                        triggers: {
                            before: [
                                shipUtil.buildDNode('if', {
                                    condition: shipUtil.buildDNode('neq', {
                                        left: shipUtil.buildDNode('fromShip', {
                                            get: shipUtil.buildDNode(
                                                'dockedStation',
                                            ),
                                        }),
                                        right: shipUtil.buildDNode(
                                            'startLocation',
                                        ),
                                    }),
                                    action: [
                                        shipUtil.buildDNode(
                                            'insertOrderBefore',
                                            {
                                                order: shipUtil.buildDNode(
                                                    'travel',
                                                    {
                                                        to: shipUtil.buildDNode(
                                                            'startLocation',
                                                        ),
                                                    },
                                                ),
                                            },
                                        ),
                                    ],
                                }),
                            ],
                            onSuccess: [
                                shipUtil.buildDNode('insertOrderAfter', {
                                    order: shipUtil.buildDNode('load', {
                                        cargo: shipUtil.buildDNode('fromJob', {
                                            get: shipUtil.buildDNode('cargo'),
                                        }),
                                    }),
                                }),
                                shipUtil.buildDNode('appendOrder', {
                                    order: shipUtil.buildDNode('travel', {
                                        to: shipUtil.buildDNode('fromJob', {
                                            get: shipUtil.buildDNode(
                                                'destLocation',
                                            ),
                                        }),
                                    }),
                                }),
                                shipUtil.buildDNode('appendOrder', {
                                    order: shipUtil.buildDNode('unload', {
                                        cargo: shipUtil.buildDNode('fromJob', {
                                            get: shipUtil.buildDNode('cargo'),
                                        }),
                                    }),
                                }),
                                shipUtil.buildDNode('appendOrder', {
                                    order: shipUtil.buildDNode('finishJob', {
                                        which: shipUtil.buildDNode(
                                            'literalJob',
                                            {
                                                job: {
                                                    $ref: `/{{jobId}}`,
                                                },
                                            },
                                        ),
                                        abandonOnFail: false,
                                    }),
                                }),
                            ],
                        },
                    }),
                }),
            ),
            s: JSON.stringify(
                decompileTantive(
                    {
                        text: '',
                        order: {
                            order: shipUtil.buildDNode('cargoCt'),
                            direction: shipUtil.buildDNode('ascending'),
                        },
                        filters: [
                            shipUtil.buildDNode('anyOf', {
                                alternatives: [
                                    shipUtil.buildDNode('destLocationIs', {
                                        destination: {
                                            $ref: `/${id}`,
                                        },
                                    }),
                                    shipUtil.buildDNode('startLocationIs', {
                                        start: {
                                            $ref: `/${id}`,
                                        },
                                    }),
                                ],
                            }),
                        ],
                    },
                    model,
                    jobsSearchSpec,
                ),
            ),
        });

        navigate(`/jobs/?${params.toString()}`);
    }

    useRegistration('map', {
        annotations: mapAnnotations,
        eventHandlers: { onStationClick },
    });

    return useVolatileDataWrapper(
        {
            orderSequence,
            reorder,
            shipId,
            shipOrders,
            simulatedState,
            warnings,
        },
        shipData ? orderSequence : null,
        missingMessage,
        ({
            orderSequence,
            reorder,
            shipId,
            shipOrders,
            simulatedState,
            warnings,
        }) => (
            <VerticalMainLayout
                footer={
                    <AnticipatedState
                        shipId={shipId}
                        simulatedState={simulatedState}
                    />
                }
            >
                <Container>
                    <QueueContents
                        shipId={shipId}
                        shipOrders={shipOrders}
                        orderSequence={orderSequence}
                        reorder={reorder}
                        warnings={warnings}
                    />
                    <AddButtonLink to={`/ships/${shipId}/orders/new`}>
                        <FloatingButton size='large' variant='primary'>
                            <GoPlus />
                        </FloatingButton>
                    </AddButtonLink>
                </Container>
            </VerticalMainLayout>
        ),
    );
}

function useSimulatedState(shipId, orderHash) {
    const { click, model } = react.useContext(GameStateContext);
    const shipData = model.get(`/${shipId}`);

    const [simulatedModel, { click: endClick, warnings } = {}] = react.useMemo(
        () =>
            runOrderSimulation(
                shipId,
                model.get(`/${shipId}/orders`) ?? [],
                model,
                click,
            ),
        [orderHash, shipId],
    );

    if (!shipData?.groundShipResources) {
        return [];
    }

    const loadoutResources = simulatedModel
        ? shipUtil.synthesizeShipProps(shipId, simulatedModel)
        : null;

    return [
        simulatedModel
            ? {
                  model: simulatedModel,
                  resources: loadoutResources,
              }
            : null,
        { click: endClick, warnings },
    ];
}

function AnticipatedState({ shipId, simulatedState }) {
    return (
        <OptionalGroup
            title={
                <react.Fragment>
                    <span>Anticipated State&nbsp;</span>
                    <InfoButton
                        info={
                            <HelpArticle>
                                <p>
                                    <strong>Anticipated state</strong> of this
                                    ship at the point in time where the{' '}
                                    <strong>Add&nbsp;(+)</strong> button would
                                    add a new order, based on the orders listed
                                    here.
                                </p>
                                <p>
                                    State will include any orders that would be
                                    inserted by &quot;Insert Order Before&quot;,
                                    &quot;Insert Order After&quot;, or
                                    &quot;Prepend Order&quot; automations.
                                    Orders added by &quot;Append Order&quot;
                                    automations are not considered, since such
                                    orders would execute after an order inserted
                                    by the <strong>Add&nbsp;(+)</strong> button.
                                </p>
                                <p>
                                    Anticipated state takes into account only
                                    this ship&apos;s orders and assumes all such
                                    orders succeed.
                                </p>
                            </HelpArticle>
                        }
                    />
                </react.Fragment>
            }
        >
            <If condition={simulatedState}>
                <Then>
                    <ResourceBoundSubsystem
                        alternativeModel={simulatedState?.model}
                        environment='stored'
                        groundResources={simulatedState?.resources}
                        hideModules={true}
                        stacksOrPath={`/${shipId}/storage`}
                    />
                </Then>
                <Else>
                    <ValidationMessage>
                        Simulation did not complete. Too many orders.
                    </ValidationMessage>
                </Else>
            </If>
        </OptionalGroup>
    );
}

function OrderTileContextMenu({ hide, orderId, order, shipId }) {
    const { terminal } = react.useContext(GameStateContext);

    const doDelete = react.useCallback(async () => {
        try {
            hide();
            await terminal(
                shipUtil.buildDNode('deleteOrder', {
                    ship: shipId,
                    order: orderId,
                }),
            );
        } catch (e) {
            console.error(e);
        }
    }, [orderId, shipId, terminal]);

    return (
        <ul
            css={(theme) => css`
                display: grid;
                width: 100vw;
                background-color: ${theme.colors.card};
                box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.35);
                overflow: hidden;
                border-style: solid;
                border-width: 1px;
                border-color: rgba(0, 0, 0, 0.3);
            `}
        >
            <li
                css={css`
                    display: grid;
                    padding: 1px;
                `}
            >
                <Button
                    onClick={doDelete}
                    size='large'
                    css={css`
                        display: grid;
                    `}
                >
                    <p
                        css={css`
                            overflow: hidden;
                            text-wrap: nowrap;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <icons.Delete
                            css={css`
                                flex-grow: 0;
                                flex-shrink: 0;
                            `}
                        />
                        <span>Delete &quot;</span>
                        <span
                            css={css`
                                font-style: oblique;
                                text-overflow: ellipsis;
                                min-width: 0;
                                overflow: hidden;
                            `}
                        >
                            <OrderSummaryText orderData={order} />
                        </span>
                        <span>&quot;</span>
                    </p>
                </Button>
            </li>
        </ul>
    );
}

function OrderTile({ orderId, order, shipId, warnings }) {
    const dragControls = useDragControls();

    const { model } = react.useContext(GameStateContext);
    const [showContextPopover, setShowContextPopover] = react.useState();
    const [orderType] = shipUtil.dnode(order, 'unknown');
    const Icon =
        icons[
            specs.shipOrder.$descrim[orderType]?.$icon ?? 'icons.jsx/unknown'
        ];

    let warningMessage;
    if (warnings?.length > 0) {
        const warningToDisplay = warnings[0];
        if (warningToDisplay.details?.reason === 'tag_violation') {
            warningMessage = tags.jobClaimTagToErrorMessage(
                model,
                model.get(`/${warningToDisplay.details?.jobId}`),
                ...(Object.entries(
                    warningToDisplay.details?.violations ?? {},
                )[0] ?? []),
            );
        } else {
            warningMessage = warningToDisplay.message;
        }
    }

    return (
        <Reorder.Item
            value={orderId}
            dragListener={false}
            dragControls={dragControls}
        >
            <Popover
                isOpen={showContextPopover}
                content={
                    <OrderTileContextMenu
                        hide={() => setShowContextPopover(false)}
                        orderId={orderId}
                        order={order}
                        shipId={shipId}
                    />
                }
                onClickOutside={() => setShowContextPopover(false)}
            >
                <OrderQueueTile
                    onContextMenu={(e) => {
                        setShowContextPopover(true);
                        e.preventDefault();
                        return false;
                    }}
                >
                    <OrderQueueTileDragHandle
                        onPointerDown={(e) => {
                            dragControls.start(e);
                            e.preventDefault();
                            return false;
                        }}
                    >
                        <GrDrag />
                        <OrderQueueTileIcon>
                            <Icon />
                        </OrderQueueTileIcon>
                    </OrderQueueTileDragHandle>
                    <OrderQueueTileClickableArea
                        to={`/ships/${shipId}/orders/${orderId}`}
                        state={{ back: window.location.pathname }}
                    >
                        <OrderSummaryText orderData={order} />
                        <When condition={warningMessage}>
                            <ValidationMessage
                                css={css`
                                    font-size: small;
                                    margin: 5px;
                                `}
                            >
                                {`Anticipated failure: ${warningMessage}`}
                            </ValidationMessage>
                        </When>
                    </OrderQueueTileClickableArea>
                </OrderQueueTile>
            </Popover>
        </Reorder.Item>
    );
}

function QueueContents({
    shipId,
    shipOrders,
    orderSequence,
    reorder,
    warnings,
}) {
    return (
        <If condition={orderSequence.length === 0}>
            <Then>
                <p>&lt;empty&gt;</p>
            </Then>
            <Else>
                <div
                    css={css`
                        overflow: scroll;
                        padding-bottom: 100px;
                    `}
                >
                    <Reorder.Group
                        axis='y'
                        values={orderSequence}
                        onReorder={reorder}
                    >
                        {orderSequence.map((id) => (
                            <OrderTile
                                key={`${shipId}_${id}`}
                                orderId={id}
                                order={shipOrders.get(`/${id}`)}
                                shipId={shipId}
                                warnings={warnings[id]?.filter(
                                    ({ urgent }) => urgent,
                                )}
                            />
                        ))}
                    </Reorder.Group>
                </div>
            </Else>
        </If>
    );
}

function OrderSummaryText({ orderData }) {
    const { model } = react.useContext(GameStateContext);
    return shipUtil.humanOrder(orderData, specs.shipOrder, model);
}

function runOrderSimulation(shipId, orders, model, click) {
    const workingModel = new Model(model);
    workingModel.push(`/${shipId}/orders`, 'dummy');

    const warnings = {};
    let i = 100;

    while (workingModel.get(`/${shipId}/orders/0`) !== 'dummy' && i > 0) {
        const curOrderId = workingModel.get(`/${shipId}/orders`)[0].id;

        const [orderWarnings, { elapsedClicks }] = simulateOrder(
            shipId,
            workingModel,
        );

        click += elapsedClicks;

        if (orderWarnings === true) {
            break;
        }

        if (orderWarnings.length > 0) {
            warnings[curOrderId] = orderWarnings;
        }

        i--;
    }

    return [i === 0 ? null : workingModel, { click, warnings }];
}
