import CenterLayout from './CenterLayout.jsx';
import FadeLoader from 'react-spinners/FadeLoader';

import { Overlayer } from './standard.jsx';

export default function LoadingOverlay({
    children = <p>Loading...</p>,
    under,
}) {
    return (
        <Overlayer
            over={
                <CenterLayout>
                    <FadeLoader />
                    {children}
                </CenterLayout>
            }
            under={under}
        />
    );
}
