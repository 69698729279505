import react from 'react';
import * as reactRouter from 'react-router-dom';

export function wbToString(wb) {
    const usp = new URLSearchParams();
    for (const [k, v] of Object.entries(wb)) {
        usp.set(k, JSON.stringify(v));
    }
    return usp.toString();
}

export default function useWhiteboard(external) {
    const [searchParams, setSearchParams] = reactRouter.useSearchParams();
    const result = react.useMemo(
        () => {
          return [
            external
                ? external[0]
                : Object.fromEntries(
                      [...searchParams.entries()]
                          .map(([k, v]) => {
                              let parsedV;
                              try {
                                  parsedV = JSON.parse(v);
                              } catch (e) {
                                  if (v.trim() === '') {
                                      parsedV = undefined;
                                  } else if (/^(\d*\.)?\d+$/.test(v.trim())) {
                                      parsedV = parseFloat(v.trim());
                                  } else if (v === 'true') {
                                      parsedV = true;
                                  } else if (v === 'false') {
                                      parsedV = false;
                                  } else {
                                      parsedV = v;
                                  }
                              }

                              return [k, parsedV];
                          })
                          .filter(([, v]) => v !== undefined)
                  ),
            external
                ? external[1]
                : (merge, opts) => {
                      const newParams = {
                          ...Object.fromEntries(searchParams.entries()),
                      };

                      for (const [k, v] of Object.entries(merge)) {
                          if (v === undefined) {
                              delete newParams[k];
                          } else {
                              newParams[k] = JSON.stringify(v);
                          }
                      }

                      setSearchParams(new URLSearchParams(newParams), opts);
                  },
        ];
      },
        [external, searchParams, setSearchParams]
    );

    return result;
}
