import { ThemeProvider } from '@emotion/react';

const theme = {
    colors: {
        background: 'rgb(248, 248, 248)',
        card: '#fff',
        primary: '#9999cc',
        secondary: '#0088ff',
        text: 'rgb(68, 68, 68)',
        deprioritizedText: 'rgb(68, 68, 68, .5)',
        disabledText: 'rgb(68, 68, 68, .3)',
    },
    metrics: {
        targetableClearance: '15px',
    },
};

export default function GameTheme({ children }) {
    return <ThemeProvider {...{ theme }}>{children}</ThemeProvider>;
}
