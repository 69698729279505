import * as shipUtil from '../utils/utils.mjs';

import { registryContext } from './use-registry-root.jsx';
import { useContext } from 'react';

export default function useRegistry(rName) {
    if (typeof rName !== 'string') {
        throw new Error(
            'Registry name must be a string: ' + shipUtil.debugString(rName),
        );
    }

    const { useRegistry: useInnerReg } = useContext(registryContext);
    return useInnerReg(rName);
}
