import { BiCoinStack } from 'react-icons/bi';
import { css } from '@emotion/react';

export default function CredSym({ children, className }) {
    const formattedPrice = new Intl.NumberFormat().format(children);

    return (
        <span
            className={className}
            css={css`
                white-space: nowrap;
            `}
        >
            <BiCoinStack />
            {formattedPrice}
        </span>
    );
}
