import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaTrashAlt } from 'react-icons/fa';
import { GiAbstract004, GiPathDistance } from 'react-icons/gi';
import { GrConnect } from 'react-icons/gr';
import { MdAddToPhotos } from 'react-icons/md';
import { PiPlugs, PiPlugsConnected } from 'react-icons/pi';
import {
    TbCircleDotted,
    TbPackageExport,
    TbPackageImport,
} from 'react-icons/tb';
import { LuClipboardCheck } from 'react-icons/lu';

const icons = new Proxy(
    {
        claim: MdAddToPhotos,
        delete: FaTrashAlt,
        finishJob: LuClipboardCheck,
        install: PiPlugsConnected,
        loadCargo: TbPackageImport,
        plugin: GrConnect,
        station: GiAbstract004,
        travel: GiPathDistance,
        unloadCargo: TbPackageExport,
        uninstall: PiPlugs,
        wait: AiOutlineClockCircle,
    },
    {
        get: (target, field) => {
            if (target[field.toLowerCase()]) {
                return target[field.toLowerCase()];
            }

            if (!field.startsWith('icons.jsx/')) {
                return;
            }

            const [, actualField] = field.split('/');

            const lowerCase =
                actualField.charAt(0).toLowerCase() + actualField.substring(1);

            return target[lowerCase] ?? TbCircleDotted;
        },
    }
);

export default icons;
