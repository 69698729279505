import react from 'react';
import useRegistration from '../../hooks/use-registration.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { GameStateContext } from '../../app-contexts.jsx';

export default function Job(props) {
    const { jobId } = props;
    const { model } = react.useContext(GameStateContext);
    const jobDetails = model.get(`/${jobId}`);
    const fromDetails = model.get(`/${jobId}/from`);
    const toDetails = model.get(`/${jobId}/to`);

    const annotations = jobDetails
        ? [
              {
                  start: jobDetails.from,
                  end: jobDetails.to,
                  style: 'arrow',
                  parameters: {
                      color: 'rgb(0, 98, 255)',
                  },
              },
              {
                  location: jobDetails.from,
                  label: fromDetails.name,
              },
              {
                  location: jobDetails.to,
                  label: toDetails.name,
              },
          ]
        : [];

    useRegistration('map', { annotations });

    return useVolatileDataWrapper(
        props,
        jobDetails,
        <p>This job no longer exists.</p>,
        ({ data: jobDetails }) => (
            <table>
                <tbody>
                    <tr>
                        <td>From</td>
                        <td>{fromDetails?.name ?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>To</td>
                        <td>{toDetails?.name ?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Cargo</td>
                        <td>
                            <ul>
                                {(jobDetails?.cargo ?? []).map((e, i) => {
                                    const cargoId = e.value[0].value;
                                    const cargoAmt = e.value[1].value;

                                    const cargoDetails = model.get(cargoId);

                                    return (
                                        <div key={i}>
                                            <p>
                                                {cargoDetails?.name ??
                                                    'Unknown'}
                                            </p>
                                            <p>{cargoAmt}</p>
                                        </div>
                                    );
                                })}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        ),
    );
}
