import Job from '../components/pages/Job.jsx';
import react from 'react';
import useNavigate from '../hooks/use-navigate.jsx';

import { UsernodeDialogContext } from '../app-contexts.jsx';

export function useDialogInitiator() {
    const queryUser = react.useContext(UsernodeDialogContext);

    return {
        jobDetail(jId) {
            queryUser.displayInfo(<Job jobId={jId} />);
        },
    };
}

export function useNavigateInitiator() {
    const navigate = useNavigate();
    const result = react.useMemo(
        () => ({
            jobDetail(jId) {
                navigate(`/jobs/${jId}`);
            },
        }),
        [navigate]
    );

    return result;
}
