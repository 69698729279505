import Digest from '../../components/pages/Digest.jsx';

import { GameStateContext } from '../../app-contexts.jsx';
import { useContext, useEffect, useState } from 'react';
import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/digests/:digestId',
    Component: () => {
        const { digestId } = useParams();
        const { sendToServer, session } = useContext(GameStateContext);

        const [digest, setDigest] = useState();
        useEffect(() => {
            if (!session) {
                setDigest(null);
            }

            (async () => {
                try {
                    const digest = await sendToServer?.({
                        type: 'FetchDigest',
                        digestId,
                    });
                    setDigest(digest?.content);
                } catch (e) {
                    console.error(e);
                    setDigest(null);
                }
            })();
        }, [digestId, sendToServer, session, setDigest]);

        return <Digest data={digest} />;
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: () => 'Digest',
        upUrl: '/',
    },
};
