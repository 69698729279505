import DebugPopover from '../../DebugPopover.jsx';
import styled from '@emotion/styled';

import { ValidationMessage } from '../../standard.jsx';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MyValidationMessage = styled(ValidationMessage)`
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 7px;
    margin-bottom: 5px;
`;

export const SimpleFieldTable = styled.div`
    display: flex;
    flex-direction: column;

    border-color: rgb(0, 0, 0, 0.25);
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;

    overflow: hidden;

    margin-top: 30px;
    margin-bottom: 25px;

    max-width: unset;
    min-width: unset;
    width: unset;
`;

export const SimpleFieldRow = styled.div`
    height: 45px;
    display: flex;

    & > * {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;

        width: unset;
        max-width: unset;
        min-width: 0;
    }
`;

export const SimpleFieldRowHeader = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const SimpleFieldData = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    & > * {
        grd-area: 1 / 1 / 1 / 1;
    }
`;

export const SimpleFieldLabel = styled(DebugPopover)`
    display: flex;
    justify-content: space-around;
    align-self: center;
    font-weight: 500;
`;
