import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';
import react from 'react';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { Button } from '../fragments/standard.jsx';
import { GameStateContext } from '../../app-contexts.jsx';
import { RiAlertFill } from 'react-icons/ri';
import { Else, If, Then, When } from 'react-if';
import { missingMessage } from './utils/Ship/ship.jsx';

const LogPageEntryCard = styled.div`
    display: flex;
    align-items: center;

    border-style: solid;
    border-color: rgb(0, 0, 0, 0.5);
    border-width: 1px;
`;

const LogPageAlertIco = styled(RiAlertFill)`
    --size: 30px;

    max-width: var(--size);
    min-width: var(--size);
`;

const LogPageMessage = styled.div``;

const LogPageAckButton = styled(Button)`
    float: right;
`;

export default function LogPage(props) {
    const { entries, path } = props;

    return useVolatileDataWrapper(props, entries, missingMessage, (props) => (
        <ol>
            {props.data?.map((entry, i) => (
                <LogEntry key={i} entry={entry} path={`${path}/${i}`} />
            ))}
        </ol>
    ));
}

export function LogEntry({ entry, path }) {
    const { model, sendToServer } = react.useContext(GameStateContext);

    const message = shipUtil.logEntryToString(entry, model);

    function toggleAck() {
        sendToServer(
            {
                type: 'Edit',
                edits: [
                    {
                        op: 'set',
                        key: `${path}/acknowledged`,
                        value: !entry.acknowledged,
                    },
                ],
            },
            { [`${path}/acknowledged`]: !entry.acknowledged },
        );
    }

    return (
        <LogPageEntryCard>
            <When condition={entry.urgent}>
                <LogPageAlertIco />
            </When>
            <LogPageMessage>
                {message}
                <If condition={entry.acknowledged}>
                    <Then>
                        <LogPageAckButton onClick={toggleAck}>
                            Unacknowledge
                        </LogPageAckButton>
                    </Then>
                    <Else>
                        <LogPageAckButton variant='primary' onClick={toggleAck}>
                            Acknowledge
                        </LogPageAckButton>
                    </Else>
                </If>
            </LogPageMessage>
        </LogPageEntryCard>
    );
}
