import DebugPopover from './DebugPopover.jsx';
import styled from '@emotion/styled';
import Section from './Section.jsx';
import ShipErrorBoundary from './ShipErrorBoundary.jsx';
import VerticalMainLayout from './VerticalMainLayout.jsx';

import { css } from '@emotion/react';
import { Headline } from './Section.jsx';
import { InvisibleButton } from './standard.jsx';
import { Else, If, Then } from 'react-if';

const OptionalGroupBreakoutButton = styled(InvisibleButton)`
    grid-area: 1 / 1;

    display: grid;
    overflow: hidden;
`;
const OptionalGroupContainer = styled(Section)`
    display: grid;
    overflow: hidden;
`;
const OptionalGroupContent = styled.div`
    display: grid;
    overflow: hidden;

    margin-top: calc(
        (1rem / 2) + ${(props) => props.theme.metrics.targetableClearance}
    );

    border-width: 1px;
    border-style: solid;
    border-radius: 5px;

    background-color: ${(props) =>
        props.backgroundColor ?? props.theme.colors.card};

    padding-top: ${(props) => props.theme.metrics.targetableClearance};
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
`;
const OptionalGroupContentScroller = styled.div`
    overflow: scroll;
`;
const OptionalGroupHeadline = styled(Headline)`
    --labelPadding: 5px;

    /* Firefox mobile needs at least 3px for full clearance. */
    --clearBorderOffset: 3px;

    --cardColor: ${(props) => props.backgroundColor ?? props.theme.colors.card};

    margin-left: calc(
        ${(props) => props.theme.metrics.targetableClearance} -
            var(--labelPadding)
    );

    padding-left: var(--labelPadding);
    padding-right: var(--labelPadding);

    font-size: 1rem;
    font-weight: normal;

    background: linear-gradient(
        to bottom,
        rgb(0, 0, 0, 0),
        rgb(0, 0, 0, 0) calc(50% - var(--clearBorderOffset)),
        var(--cardColor) calc(50% - var(--clearBorderOffset)),
        var(--cardColor)
    );
`;
const OptionalGroupHeadlineLayer = styled.div`
    grid-area: 1 / 1;
`;
const OptionalGroupLabel = styled.label`
    position: relative;
    display: flex;
    z-index: 1;

    padding-top: ${(props) => props.theme.metrics.targetableClearance};
    padding-bottom: 5px;
`;
const OptionalGroupNote = styled.p`
    font-size: small;
    background-color: rgb(0, 0, 0, 0.2);
    width: fit-content;
    align-self: flex-end;
    margin-left: auto;
    margin-right: 10px;
    padding-left: 7px;
    padding-right: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const headlineVariants = {
    embedded: css``,
    top: css`
        font-weight: 700;
    `,
};

const frameVariants = {
    embedded: css`
        border-color: rgb(0, 0, 0, 0.5);
    `,
    top: css`
        border-color: black;
        box-shadow: 3px 3px 5px rgb(0, 0, 0, 0.2);
    `,
};

export default function OptionalGroup({
    checked,
    children,
    className,
    debugText,
    debugValue,
    onCheckedChange,
    onContentClick,
    note,
    title,
    variant = 'top',
}) {
    const expanded = children !== undefined;

    const wrappedTitle = debugText ? (
        <DebugPopover popover={debugText}>{title}</DebugPopover>
    ) : (
        title
    );

    // We separate this out so we can wrap the whole thing in a breakout button
    // if we've got an onContentClick.
    const contentsInFrameWithNote = (
        <VerticalMainLayout
            css={css`
                display: ${expanded ? 'visible' : 'none'};
                grid-area: 1 / 1;

                padding-left: 4px;
                padding-right: 4px;
                ${note ? '' : 'padding-bottom: 8px'};
            `}
            footer={note && <OptionalGroupNote>{note}</OptionalGroupNote>}
        >
            <OptionalGroupContent css={frameVariants[variant]}>
                <OptionalGroupContentScroller>
                    <ShipErrorBoundary json={debugValue}>
                        {children}
                    </ShipErrorBoundary>
                </OptionalGroupContentScroller>
            </OptionalGroupContent>
        </VerticalMainLayout>
    );

    return (
        <OptionalGroupContainer className={className}>
            <OptionalGroupHeadlineLayer>
                <OptionalGroupLabel
                    css={(theme) => css`
                        ${expanded
                            ? ''
                            : `padding-bottom: ${theme.metrics.targetableClearance};`}
                    `}
                >
                    <input
                        css={css`
                            visibility: ${checked === undefined
                                ? 'hidden'
                                : 'visible'};
                        `}
                        type='checkbox'
                        checked={checked}
                        onChange={onCheckedChange}
                    />
                    <OptionalGroupHeadline
                        css={[
                            headlineVariants[variant],
                            children ? {} : { background: 'none' },
                            title ? {} : { visibility: 'hidden' },
                        ]}
                    >
                        {wrappedTitle}
                    </OptionalGroupHeadline>
                </OptionalGroupLabel>
            </OptionalGroupHeadlineLayer>
            <If
                condition={
                    /* <If /> calls the function if passed directly. :shrug: */
                    !!onContentClick
                }
            >
                <Then>
                    <OptionalGroupBreakoutButton onClick={onContentClick}>
                        {contentsInFrameWithNote}
                    </OptionalGroupBreakoutButton>
                </Then>
                <Else>{contentsInFrameWithNote}</Else>
            </If>
        </OptionalGroupContainer>
    );
}
