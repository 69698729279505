import * as specs from '../../../../specs.mjs';
import * as shipUtil from '../../../../utils/utils.mjs';

export const missingMessage = <p>This ship no longer exists.</p>;

export function buildAnnotations(shipId, model) {
    const result = [];

    const shipData = shipUtil.unmessy(model.get(`/${shipId}`) ?? {});
    const shipProps = shipUtil.synthesizeShipProps(shipData ?? {}, model);

    if (shipData.location?.type === 'docked') {
        result.push({
            label: model.get(shipData.location.where)?.name,
            location: shipData.location.where,
        });
    } else if (shipData.location?.type === 'traveling') {
        result.push({
            start: shipData.location.from,
            end: shipData.location.to,
            style: 'travel',
        });

        result.push({
            label: model.get(shipData.location.from)?.name,
            location: shipData.location.from,
        });

        result.push({
            label: model.get(shipData.location.to)?.name,
            location: shipData.location.to,
        });
    }

    if (shipData.location?.type === 'docked' && shipProps.operatingRange) {
        result.push({
            location: shipData.location.where,
            radius: shipProps.operatingRange,
            style: 'dotted',
            color: 'rgb(0, 0, 0, .5)',
        });
    }

    return result;
}

export function currentActionText(shipData = {}, model) {
    const orderData = shipData?.orders?.[0];

    if (!orderData) {
        return 'Idle';
    }

    return shipUtil.humanOrder(orderData, specs.shipOrder, model);
}
