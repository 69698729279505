import DOMPurify from 'dompurify';
import styled from '@emotion/styled';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { marked } from 'marked';

const DigestList = styled.ol`
    overflow: scroll;

    list-style-type: disc;
    padding-left: 15px;

    & > li {
        margin-bottom: 7px;
    }

    & a {
        color: blue;
        text-decoration: underline;
    }

    & a:visited {
        color: purple;
        text-decoration: underline;
    }
`;

export default function Digest(props) {
    const { data } = props;

    return useVolatileDataWrapper(
        props,
        data,
        <p>This digest no longer exists.</p>,
        ({ data: digest }) => (
            <DigestList>
                {digest?.map((d, i) => (
                    <li
                        key={i}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(marked.parse(d)),
                        }}
                    />
                ))}
            </DigestList>
        ),
    );
}
