import JsonView from 'react18-json-view';
import * as Sentry from '@sentry/react';
import styled from '@emotion/styled';

import 'react18-json-view/src/style.css';

import { If, Then, Else } from 'react-if';

const ErrorText = styled.p`
    font-size: small;
    color: red;
`;

export default function ShipErrorBoundary({
    children,
    className,
    force,
    json,
}) {
    return (
        <If condition={force}>
            <Then>
                <ErrorDisplay
                    className={className}
                    message={force}
                    json={json}
                />
            </Then>
            <Else>
                <Sentry.ErrorBoundary
                    className={className}
                    fallback={({ error }) => (
                        <ErrorDisplay message={error.message} json={json} />
                    )}
                >
                    {children}
                </Sentry.ErrorBoundary>
            </Else>
        </If>
    );
}

function ErrorDisplay({ className, json, message }) {
    return (
        <div className={className} data-render-failure="true">
            <ErrorText>{'✗ ' + message}</ErrorText>
            <JsonView src={json} />
        </div>
    );
}
