import CenterLayout from './CenterLayout.jsx';
import ModuleTile from './ModuleTile.jsx';
import react from 'react';
import resourceBoundSubsystem from '../../shared/resource-bound-subsystem.mjs';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { GameStateContext } from '../../app-contexts.jsx';
import { Entry as PTEntry, PropertyTable } from './PropertyTable.jsx';
import { Else, If, Then, When } from 'react-if';

const RBSDefaultEmpty = styled.p`
    font-size: small;
    font-style: oblique;

    &::after {
        content: '>';
    }

    &::before {
        content: '<';
    }
`;

const RBSGrid = styled.ul`
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 20px;

    padding-bottom: 10px;
    padding-top: 10px;
`;

const RBSModuleEntry = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
`;

const RBSNoBreak = styled.span`
    white-space: nowrap;
`;

const RBSRequirement = styled.li`
    display: flex;
    flex-wrap: wrap;
    font-size: small;
    column-gap: 4px;
    align-items: center;
    justify-content: space-around;
`;

const RBSRequirements = styled.ul`
    padding-top: 5px;

    width: 100px;
    line-height: 75%;
`;

export default function ResourceBoundSubsystem({
    alternativeModel,
    empty = <RBSDefaultEmpty>empty</RBSDefaultEmpty>,
    environment,
    groundResources = {},
    hiddenProps = [],
    hideModules = false,
    visibleProps = [],
    stacksOrPath,
}) {
    if (typeof empty === 'string') {
        empty = <RBSDefaultEmpty>{empty}</RBSDefaultEmpty>;
    }

    const { model: realModel } = react.useContext(GameStateContext);
    const model = alternativeModel ?? realModel;

    const stacks =
        (typeof stacksOrPath === 'string'
            ? shipUtil.unmessy(model.get(stacksOrPath))
            : stacksOrPath) ?? [];

    const moduleData = stacks.map(([{ $ref }, count]) => [
        {
            id: $ref,
            ...shipUtil.unmessy(model.get($ref)),
        },
        count,
    ]);

    moduleData.sort(([{ name: n1 }], [{ name: n2 }]) =>
        n1 < n2 ? -1 : n1 > n2 ? 1 : 0
    );

    const subsystem = resourceBoundSubsystem(
        model,
        stacksOrPath,
        environment,
        groundResources
    );

    const resourcesToString = {};
    for (const k of Object.keys(subsystem.availableResources)) {
        const { surplus = 0 } = subsystem.resources[k] ?? {};
        const surplusAsString = shipUtil.humanQuantity(
            surplus,
            shipUtil.synthesizedPropUnits(k),
            { round: true }
        );

        resourcesToString[k] = surplusAsString;
    }

    return (
        <div>
            <PropertyTable>
                {Object.entries(subsystem.availableResources)
                    .filter(([k]) =>
                        visibleProps.length > 0
                            ? visibleProps.includes(k)
                            : !hiddenProps.includes(k)
                    )
                    .map(([k]) => (
                        <PTEntry
                            key={k}
                            label={shipUtil.humanSynthesizedPropName(k)}
                        >
                            {resourcesToString[k]}
                        </PTEntry>
                    ))}
            </PropertyTable>
            <When condition={!hideModules}>
                <If condition={moduleData.length > 0}>
                    <Then>
                        <RBSGrid>
                            {moduleData.map(([m, ct], i) => (
                                <RBSModuleEntry key={i}>
                                    <ModuleTile data={m} />
                                    <p
                                        css={css`
                                            font-size: small;
                                            font-weight: bold;
                                            visibility: ${ct === 1
                                                ? 'hidden'
                                                : 'visible'};
                                        `}
                                    >{`x${ct}`}</p>
                                    <ModuleRequirements
                                        data={m}
                                        environment={environment}
                                        factor={ct}
                                    />
                                </RBSModuleEntry>
                            ))}
                        </RBSGrid>
                    </Then>
                    <Else>
                        <CenterLayout>{empty}</CenterLayout>
                    </Else>
                </If>
            </When>
        </div>
    );
}

function ModuleRequirements({ data, environment, factor = 1 }) {
    return (
        <RBSRequirements>
            {Object.entries(data.requirements?.[environment] ?? {}).map(
                ([k, v]) => (
                    <RBSRequirement key={k}>
                        <RBSNoBreak>
                            {shipUtil.humanQuantity(
                                v * factor,
                                shipUtil.synthesizedPropUnits(k)
                            )}
                        </RBSNoBreak>
                        <RBSNoBreak>
                            {shipUtil.abbreviate(
                                shipUtil.humanSynthesizedPropName(k)
                            )}
                        </RBSNoBreak>
                    </RBSRequirement>
                )
            )}
        </RBSRequirements>
    );
}
