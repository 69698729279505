import MarketSummary from '../fragments/MarketSummary.jsx';
import OptionalGroup from '../fragments/OptionalGroup.jsx';
import react from 'react';
import ResourceBoundSubsystem from '../fragments/ResourceBoundSubsystem.jsx';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';
import Teaser from '../fragments/Teaser.jsx';
import useRegistration from '../../hooks/use-registration.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';
import useWhiteboard from '../../hooks/use-whiteboard.jsx';

import { GameStateContext } from '../../app-contexts.jsx';

const StationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function Station(props) {
    const { stationId, whiteboard } = props;
    const { model } = react.useContext(GameStateContext);
    const [wbVars] = useWhiteboard(whiteboard);

    const stationData = shipUtil.unmessy(model.get(`/${stationId}`));

    const warehouse = [];

    if (wbVars.corp) {
        const moduleAccum = {};
        for (const s of stationData?.corps?.[wbVars.corp]?.storage ?? []) {
            if (s.$ref && shipUtil.hasKind(model.get(s), 'module')) {
                if (!moduleAccum[s.$ref]) {
                    moduleAccum[s.$ref] = 0;
                }

                moduleAccum[s.$ref]++;
            }
        }

        const moduleStacks = Object.entries(moduleAccum)
            .map(([ref, ct]) => [model.get(ref), ct])
            .map(([moduleData, ct]) => [
                moduleData,
                {
                    count: ct,
                    requirements: {
                        ...moduleData?.requirements?.stored,
                    },
                },
            ]);

        moduleStacks.sort(([{ name: name1 }], [{ name: name2 }]) =>
            name1 < name2 ? -1 : name2 > name1 ? 1 : 0,
        );

        for (const s of moduleStacks) {
            warehouse.push(s);
        }
    }

    const corpProps =
        wbVars.corp &&
        shipUtil.perCorpStationStats(model, stationId, wbVars.corp);

    const market = stationData?.market ?? [];

    useRegistration('map', {
        annotations: stationData
            ? [
                  {
                      label: stationData?.name ?? stationId,
                      location: { $ref: `/${stationId}` },
                  },
              ]
            : undefined,
    });

    return useVolatileDataWrapper(
        props,
        stationData,
        <p>This station no longer exists.</p>,
        ({ data: stationData }) => (
            <StationContainer>
                <Warehouse
                    corpProps={corpProps}
                    data={stationData?.corps?.[wbVars.corp]?.storage}
                />
                <Teaser
                    title='Market'
                    linkTo={`/stations/${stationId}/marketOrders`}
                    noteVerb='Explore'
                    overflowOverlay={`${market.length} total orders`}
                    preserveWbKeys='corp'
                    whiteboard={whiteboard}
                    data={stationData?.market}
                >
                    <MarketSummary data={market} />
                </Teaser>
            </StationContainer>
        ),
    );
}

function Warehouse({ corpProps = {}, data = [] }) {
    return (
        <OptionalGroup title='Warehouse'>
            <ResourceBoundSubsystem
                environment='stored'
                groundResources={corpProps.availableResources}
                hiddenProps={['mass']}
                stacksOrPath={data}
            />
        </OptionalGroup>
    );
}
