import clone from 'clone';
import jsonPointer from 'json-pointer';
import Mask from './mask.mjs';
import Mustache from 'mustache';
import * as shipUtil from './utils.mjs';

const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890';
export function generateId(prefix) {
    let result = '';
    while (result.length < 11) {
        result += alpha.charAt(Math.floor(Math.random() * alpha.length));
    }

    return `${prefix}_${result}`;
}

/*
 * A container for manipulating and querying game entities. Each entity has as
 * string id that maps to a non-zero-valued game entity value. A game entity
 * whose value becomes a zero-value is delted. A game entity value is JSON-like
 * with two refinements:
 *
 * 1) All arrays conform to "game entity value arrays", in which each element is
 *    itself an indirect "slot" object with an `id` field and a `value` field.
 *    This permits slots to maintain their identity even as they are shifted
 *    within the array. Game entity value paths like /foo/bar/3 thus correspond
 *    to practical representational paths like /foo/bar/3/value. Because slots
 *    have ids in addition to indexes, game entity value paths are also
 *    permitted to address array elements by id, e.g., /foo/bar/ae_123.
 *    Non-game-entity-value arrays are converted to game entity value arrays
 *    when they are inserted into the model. However, the inverse transformation
 *    is not performed. Retrieving an array from a model returns the
 *    game-entity-value-array-structured array. All of this is kinda a mess and
 *    I regret doing it this way, but here we are. :)
 * 2) `null`, `undefined`, `0`, `""`, `[]`, and `{}` are all considered zero
 *    values and zero values never appear in a game entity value. Setting an
 *    array element to `[]` (or any other zero value) is thus equivalent to
 *    deleting that element's `value` field, and similary setting an object
 *    field entry to a zero value is equivalent to deleting that field from the
 *    object. Note that such changes can cascade--removing the final field from
 *    an object may cause it to be the empty object, itself a zero value, which
 *    will in turn case it to be removed from its parent object. Game entities
 *    can be removed in this way.
 */
export default class Model {
    adjustments = [];
    events = [];

    dirtyListeners = [];

    // Note that mask omits array id layers and corresponds to client keys.
    mask = new Mask();

    constructor(v = {}, rule, nodeId) {
        if (v instanceof Model) {
            this.value = clone(v.value ?? {});
        } else {
            this.value = stripZeroValues(cloneJson(v ?? {}));
        }

        this.rule = rule;
        this.nodeId = nodeId;
    }

    add(path, value, offset = 0) {
        const pathParts = jsonPointer.parse(path);
        const parentPath = jsonPointer.compile(
            pathParts.slice(0, pathParts.length - 1),
        );

        let curParent = this.get(parentPath);

        if (typeof curParent !== 'object' || curParent === null) {
            curParent = [];
        }

        if (Array.isArray(curParent)) {
            const newNode = {
                id: generateId('ae'),
                value,
            };

            let newParent;
            const index = pathParts[pathParts.length - 1];
            if (index === '-') {
                newParent = [...curParent, newNode];
            } else {
                let i;
                if (/^\d+$/.test(index)) {
                    i = Number.parseInt(index);
                } else {
                    i = curParent.findIndex(({ id }) => id === index);

                    if (i === -1) {
                        i = curParent.length;
                    }
                }

                i += offset;

                newParent = [
                    ...curParent.slice(
                        0,
                        Math.min(curParent.length, Math.max(i, 0)),
                    ),
                    newNode,
                    ...curParent.slice(Math.min(curParent.length, i)),
                ];
            }

            this.set(parentPath, newParent);
        } else {
            this.set(path, value);
        }
    }

    addAdjustment(a) {
        this.adjustments.push(a);

        for (const l of this.dirtyListeners) {
            l('dirty', this);
        }
    }

    addDirtyListener(l) {
        this.dirtyListeners.push(l);
    }

    applyAdjustments() {
        for (const a of this.adjustments) {
            this.applyAdjustment(a);
        }

        this.adjustments = [];
    }

    applyAdjustment(a) {
        a.fn.call(this, this.get(a.path));
    }

    applyDiff(diff) {
        for (const [key, value] of Object.entries(diff)) {
            try {
                this.set(key, value?.$undef ? undefined : value);
            } catch (e) {
                const e2 = new Error(
                    `Error applying ${key} ` + `${JSON.stringify(value)}`,
                );
                e2.cause = e;
                throw e2;
            }
        }
    }

    cfDec(path, amt = 1) {
        this.cfInc(path, -amt);
    }

    cfInc(path, amt = 1) {
        if (isNaN(amt)) {
            amt = 1;
        }

        if (typeof amt !== 'number' || isNaN(amt)) {
            throw new Error('amt must be a number: ' + amt);
        }

        path = normalizePath(path, this.value, { errorOnBadArrayId: true });
        this.addAdjustment({
            path,
            fn: function (v) {
                const curVal = this.get(path, 0, { arrayElement: true });
                let nextVal;
                if (curVal?.id?.startsWith?.('ae_')) {
                    nextVal = {
                        ...curVal,
                        value:
                            (typeof curVal.value === 'number'
                                ? curVal.value
                                : 0) + amt,
                    };
                } else {
                    nextVal = (typeof curVal === 'number' ? curVal : 0) + amt;
                }

                this.set(path, nextVal);
            },
        });
    }

    cfDecRefArrayElement(path, $ref, amt = 1) {
        this.cfIncRefArrayElement(path, $ref, -amt);
    }

    cfIncRefArrayElement(path, $ref, amt = 1) {
        if (typeof $ref !== 'string' && typeof $ref?.$ref !== 'string') {
            throw new Error(
                'Not a { $ref } or path: ' + shipUtil.debugString($ref),
            );
        }

        if ($ref?.$ref) {
            $ref = $ref.$ref;
        }

        if (!$ref.startsWith('/')) {
            $ref = `/${$ref}`;
        }

        // Sanity check.
        jsonPointer.parse($ref);

        path = normalizePath(path, this.value, { errorOnBadArrayId: true });
        this.addAdjustment({
            path,
            fn: function (v) {
                v = Array.isArray(v) ? [...v] : [];
                const index = v.findIndex(
                    ({ value }) => value?.[0]?.value?.$ref === $ref,
                );

                if (index !== -1) {
                    v[index] = {
                        ...v[index],

                        value: [
                            v[index].value[0],
                            {
                                ...v[index].value[1],
                                value: v[index].value[1].value + amt,
                            },
                        ],
                    };

                    if (v[index].value[1].value !== 0) {
                        this.set(path, v);
                    } else {
                        v.splice(index, 1);

                        if (v.length === 0) {
                            this.delete(path);
                        } else {
                            this.set(path, v);
                        }
                    }
                } else {
                    if (amt !== 0) {
                        this.push(path, [{ $ref }, amt]);
                    }
                }
            },
        });
    }

    cfPush(path, value) {
        const id = generateId('ae');
        value = cloneJson(value);

        path = normalizePath(path, this.value, { errorOnBadArrayId: true });
        this.addAdjustment({
            path: normalizePath(path, this.value),
            fn: function (v) {
                this.push(path, value, id);
            },
        });

        return id;
    }

    copy() {
        return new Model(this);
    }

    delete(path) {
        this.set(normalizePath(path, this.value), undefined);
    }

    dict() {
        const result = {};
        for (const key of Object.keys(this.mask.dict())) {
            result[key] = this.getRef(key);
        }
        return result;
    }

    ensure(path) {
        const value = this.get(path);

        if (!value) {
            throw new Error('No value at ' + path);
        }

        return sealed(value);
    }

    filter(path, p) {
        let curArray = this.get(path);

        if (!Array.isArray(curArray)) {
            curArray = [];
        }

        this.set(
            path,
            curArray.filter(({ id, value }, i) => p(value, i, id)),
        );
    }

    find(path, p) {
        let array = this.get(path);
        if (!Array.isArray(array)) {
            array = [];
        }

        return array.find(({ value }) => p(value));
    }

    fireEvent(e) {
        this.events.push(sealed(e));
    }

    get(path, d, options) {
        let value = this.getRef(path, d, options);
        while (value?.$ref) {
            value = this.getRef(value, d, options);
        }

        return value;
    }

    getDiff() {
        const result = {};
        for (const key of Object.keys(this.mask.dict())) {
            // Presently we just send whole arrays when any element changes.
            // This ensures the client always has array element ids before it
            // gets any child data, which is otherwise challenging to
            // orchestrate.
            const arrayPath = this.pathToParentArrayPath(key);

            result[arrayPath] = this.getRef(arrayPath, undefined, {
                arrayElement: true,
            }) ?? { $undef: true };
        }
        return result;
    }

    pathToParentArrayPath(path) {
        path = normalizePath(path, this.value ?? {}, { followLastRef: false });
        const pathComponents = jsonPointer.parse(path);

        const arrayPath = [];
        let node = this.value;
        while (
            node !== undefined &&
            pathComponents.length > 0 &&
            !Array.isArray(node)
        ) {
            const c = pathComponents.shift();

            arrayPath.push(c);
            node = node[pathComponents.shift()];
        }

        return jsonPointer.compile(arrayPath);
    }

    getMatches(query, ctx = {}) {
        const predicate = queryToPredicate(query, ctx);

        const matches = [];
        for (const key of Object.keys(this.value ?? {})) {
            if (predicate(this, key)) {
                matches.push(key);
            }
        }

        return matches;
    }

    // Returns the { $ref } or value at `path` (without following the final
    // { $ref }). Prefer getReferent() if you want to assert that the specified
    // `path` is either empty or specifically contains a { $ref }.
    getRef(path, d, options = {}) {
        if (path === undefined || path === null) {
            throw new Error('path is ' + path);
        }

        return get(this.value ?? {}, path, options.arrayElement) ?? d;
    }

    // This is pretty redundant with rootNodeId() and getRef(), except it
    // "does the right thing" (return undefined) if the value at the `path` does
    // not exist, and also asserts that, if there is a value at the `path`, it
    // is a { $ref } and it points to a root node.
    getReferent(path, d, options = {}) {
        const ref = this.getRef(path, d, options);

        if (ref && !ref?.$ref) {
            throw new Error(
                `No { $ref } at ${path}. Found: ` + shipUtil.debugString(ref),
            );
        }

        if (ref && jsonPointer.parse(ref.$ref).length !== 1) {
            throw new Error(`Referent not a root node: ${path}`);
        }

        return ref?.$ref?.substring(1);
    }

    getRefArrayElement(path, $ref, d) {
        if ($ref?.$ref) {
            $ref = $ref.$ref;
        }

        if (!$ref.startsWith('/')) {
            $ref = `/${$ref}`;
        }

        let a = this.get(path);

        let result;
        if (Array.isArray(a)) {
            result = a.find((el) => el?.value?.[0]?.value?.$ref === $ref)
                ?.value?.[1]?.value;
        }

        return sealed(result ?? d);
    }

    getSeat() {
        return '';
    }

    handle(prefixPath) {
        if (prefixPath?.$ref) {
            prefixPath = prefixPath.$ref;
        }

        const thisModel = this;
        return new Proxy(
            {},
            {
                get(target, prop, receiver) {
                    if (prop === 'getSeat') {
                        return () => thisModel.getSeat() + prefixPath;
                    }

                    return (path = '', ...args) =>
                        thisModel[prop].call(
                            thisModel,
                            prefixPath + path,
                            ...args,
                        );
                },
            },
        );
    }

    map(path, p) {
        let curArray = this.get(path);

        if (!Array.isArray(curArray)) {
            curArray = [];
        }

        this.set(
            path,
            curArray.map(({ id, value }, i) => ({
                id,
                value: p(value, i, id),
            })),
        );
    }

    markClean() {
        this.adjustments = [];
        this.mask.clear();

        for (const l of this.dirtyListeners) {
            l('clean', this);
        }
    }

    matches(path, query, ctx = {}) {
        const [rootId, ...rest] = jsonPointer.parse(path);
        if ((rest ?? []).length > 0) {
            throw new Error('Paths must identify root elements');
        }

        const predicate = queryToPredicate(query, ctx);
        return predicate(this, rootId);
    }

    overlay(target = {}) {
        for (const [key, value] of Object.entries(this.mask.dict())) {
            jsonPointer.set(target, key, this.get(key));
        }
    }

    pop(path, index = 0) {
        let curArray = this.get(path);

        if (!Array.isArray(curArray)) {
            curArray = [];
        }

        if (index.startsWith?.('ae_')) {
            index = curArray.findIndex(({ id }) => id === index);
        }

        if (index < 0 || index > curArray.length) {
            return;
        }

        const result = curArray[index];

        const newArray = [...curArray];
        newArray.splice(index, 1);

        this.set(path, newArray);

        return result?.value;
    }

    push(path, value, id = generateId('ae')) {
        let curArray = this.get(path);

        if (!Array.isArray(curArray)) {
            curArray = [];
        }

        this.set(path, [
            ...curArray,
            {
                id,
                value,
            },
        ]);

        return id;
    }

    query(...qs) {
        return Object.entries(this.value ?? {})
            .filter(([k, v]) => qs.every((q) => q(v, k)))
            .map(([k, v]) => [k, sealed(v)]);
    }

    removeFirst(path, p) {
        let curArray = this.get(path);

        if (!Array.isArray(curArray)) {
            curArray = [];
        }

        let deleted;
        const firstIndex = curArray.findIndex(({ value }) => p(value));
        if (firstIndex !== -1) {
            curArray = [...curArray];
            [{ value: deleted }] = curArray.splice(firstIndex, 1);
            this.set(path, curArray);
        }

        return deleted;
    }

    // Returns the id of the root element corresponding to the given path.
    // Equivalent to rootNodeRef().$ref.substring.(1).
    // Prefer getReferent() if you just want the id _at_ a particular path.
    rootNodeId(path = '/') {
        return path
            ? jsonPointer.parse(
                  normalizePath(path, this.value ?? {}, {
                      followLastRef: true,
                  }),
              )[0]
            : null;
    }

    // Returns the { $ref } of the root element corresponding to the given path.
    // Equivalent to { $ref: rootNodeId() }.
    // Prefer getReferent() if you just want the id _at_ a particular path.
    rootNodeRef(path = '/') {
        const id = this.rootNodeId(path);
        return id === null ? null : { $ref: `/${id}` };
    }

    set(path = '', subvalue) {
        try {
            this.mask.mark(normalizePath(path, this.value ?? {}), true);

            if (isZeroValue(subvalue)) {
                set.call(this, path, undefined);
            } else {
                set.call(this, path, cloneJson(subvalue));
            }
        } catch (e) {
            e.message = `setting ${path}: ${e.message}`;
            throw e;
        }
    }

    shallowCopy() {
        const result = new Model();
        result.adjustments = this.adjustments;
        result.events = this.events;
        result.mask = this.mask;
        result.value = this.value;
        result.rule = this.rule;
        result.nodeId = this.nodeId;
        return result;
    }

    shift(path) {
        let curArray = this.get(path);

        if (!Array.isArray(curArray)) {
            curArray = [];
        }

        this.set(path, curArray.length > 0 ? curArray.slice(1) : undefined);

        return sealed(curArray[0]?.value);
    }
}

export function cloneJson(o, path = []) {
    let result;

    switch (typeof o) {
        case 'object': {
            if (o === null) {
                result = null;
            } else if (Array.isArray(o)) {
                result = o.map((el, i) =>
                    el.id?.startsWith('ae_')
                        ? cloneJson(el, [...path, i])
                        : {
                              id: generateId('ae'),
                              value: cloneJson(el, [...path, i]),
                          },
                );
            } else {
                result = {};
                for (const [k, v] of Object.entries(o)) {
                    result[k] = cloneJson(v, [...path, k]);
                }
            }
            break;
        }
        case 'number': {
            if (isNaN(o) || o === Infinity || o === -Infinity) {
                throw new Error(
                    jsonPointer.compile(path) +
                        ' not jsony: ' +
                        o +
                        ' (' +
                        typeof o +
                        ')',
                );
            }

            result = o;
            break;
        }
        case 'function':
        case 'undefined': {
            throw new Error(
                jsonPointer.compile(path) +
                    ' not jsony: ' +
                    o +
                    ' (' +
                    typeof o +
                    ')',
            );
        }
        default: {
            result = o;
        }
    }

    return result === undefined ? defaultValue : result;
}

function get(model, path, arrayElement) {
    path = normalizePath(path, model);
    let o = operate({ model }, 'model');

    for (const c of jsonPointer.parse(path)) {
        o = operate(o.get(), c);
    }

    return sealed(arrayElement ? o.maybeGetArrayElement() : o.get());
}

function notObject(v) {
    return typeof v !== 'object' || v === null;
}

function set(path, v) {
    try {
        v = stripZeroValues(v);
        path = normalizePath(path, this.value ?? {}, {
            errorOnBadArrayId: true,
        });
        const operators = [operate({ model: this.value ?? {} }, 'model')];

        for (const c of jsonPointer.parse(path)) {
            const thisLevel = operators[operators.length - 1].get();
            operators.push(operate(thisLevel, c));
        }

        let bubble = v;
        do {
            bubble = operators[operators.length - 1].set(bubble);
            operators.pop();
        } while (operators.length > 0);

        this.value = bubble?.model;

        for (const l of this.dirtyListeners) {
            l('dirty', this);
        }
    } catch (e) {
        e.message = `setting ${path}: ${e.message}`;
        throw e;
    }
}

export function stripZeroValues(v) {
    if (isZeroValue(v)) {
        return;
    }

    if (typeof v !== 'object') {
        return v;
    }

    if (Array.isArray(v)) {
        return v.map((el) => stripZeroValues(el));
    }

    const newObj = Object.fromEntries(
        Object.entries(v)
            .map(([k, v]) => [k, stripZeroValues(v)])
            .filter(([, v]) => v !== undefined),
    );

    return isZeroValue(newObj) ? undefined : newObj;
}

export function isZeroValue(v) {
    return (
        v === undefined ||
        v === 0 ||
        v === false ||
        v === '' ||
        (Array.isArray(v) && v.length === 0) ||
        (typeof v === 'object' && Object.keys(v ?? {}).length === 0)
    );
}

// Lazy-evaluated syntactic sugar goes here. (Stuff resolved at apply-time.)
function operate(node, key) {
    if (Array.isArray(node)) {
        const index =
            typeof key === 'string' && key.startsWith('ae_')
                ? node.findIndex(({ id }) => id === key)
                : parseInt(key);

        return {
            label: `array ${key}`,
            get: () => node[index]?.value,
            maybeGetArrayElement: () => node[index],
            set: (v) => {
                if (isZeroValue(v)) {
                    delete (node[index] ?? {}).value;
                } else {
                    if (!v?.id?.startsWith('ae_')) {
                        const oldV = v;
                        v = {
                            id: node[index]?.id ?? generateId('ae'),
                            value: v,
                        };
                    }

                    if (isZeroValue(v.value)) {
                        delete v.value;
                    }

                    node[index] = v;
                }

                return node.length > 0 ? node : undefined;
            },
        };
    }

    if (typeof node === 'object' && node !== null) {
        return {
            label: `object ${key}`,
            get: () => node?.[key],
            maybeGetArrayElement: () => node?.[key],
            set: (v) => {
                if (isZeroValue(v)) {
                    delete node[key];
                } else {
                    node[key] = v;
                }

                return Object.keys(node).length > 0 ? node : undefined;
            },
        };
    }

    if (/^\d+$/.test(key)) {
        return {
            label: `missing array ${key}`,
            get: () => undefined,
            maybeGetArrayElement: () => undefined,
            set: (v) => {
                const result = [];

                v = {
                    id: generateId('ae'),
                    value: v,
                };

                if (isZeroValue(v.value)) {
                    delete v.value;
                }

                result[parseInt(key)] = v;

                return result;
            },
        };
    }

    return {
        label: `other ${key}`,
        get: () => undefined,
        maybeGetArrayElement: () => undefined,
        set: (v) => {
            return isZeroValue(v) ? {} : { [key]: v };
        },
    };
}

// Greedy-evaluated syntactic sugar goes here. (Stuff resolved at set-time.)
export function normalizePath(path, model, opts = {}) {
    const { followLastRef } = opts;

    if (typeof path === 'object' && path?.$ref) {
        path = path.$ref;
    }

    const components =
        typeof path === 'object'
            ? [...path]
            : (() => {
                  try {
                      return jsonPointer.parse(path);
                  } catch (e) {
                      throw new Error("couldn't parse " + path);
                  }
              })();

    const strPath = jsonPointer.compile(components);

    const oldPath = path;
    function deref(path, node) {
        let resolvedPath = path;
        while (typeof node === 'object' && node?.$ref) {
            resolvedPath = [
                ...jsonPointer.parse(normalizePath(node.$ref, model, opts)),
            ];
            node = get(model, resolvedPath);
        }

        return [node, resolvedPath];
    }

    let resolvedPath = [];
    let node = model;

    while (components.length > 0) {
        let nextComponent = components.shift();

        // We don't deref a final $ref (this is defined to mean the client
        // is attempting to reseat the $ref itself.)
        if (nextComponent !== '$ref' || components.length > 0) {
            [node, resolvedPath] = deref(resolvedPath, node);
        }

        const rpStr = jsonPointer.compile(resolvedPath);

        if (Array.isArray(node ?? []) && nextComponent === '-') {
            nextComponent = (node ?? []).length;
        }

        node = operate(node, nextComponent).get();
        resolvedPath.push(nextComponent);
    }

    if (followLastRef) {
        [node, resolvedPath] = deref(resolvedPath, node);
    }

    return jsonPointer.compile(resolvedPath);
}

function objMatch(candidate, query) {
    if (candidate === query) {
        return true;
    }

    if (typeof candidate !== 'object' || candidate === null) {
        return false;
    }

    for (const [key, value] of Object.entries(query)) {
        if (!objMatch(candidate[key], value)) {
            return false;
        }
    }

    return true;
}

function queryToPredicate(template, ctx, path = []) {
    let result;

    if (typeof template === 'object' && template !== null) {
        if (template.$rootId) {
            result = (model, rootId) => {
                const fullActualPath = jsonPointer.compile([rootId, ...path]);
                const queryPath = Mustache.render(template.$rootId, {
                    this: rootId,
                });

                return (
                    model.get(fullActualPath) !== undefined &&
                    model.rootNodeId(fullActualPath) ===
                        model.rootNodeId(queryPath)
                );
            };
        } else if (template.$var) {
            result = (model, rootId) =>
                queryToPredicate(ctx[template.$var], ctx, path)(model, rootId);
        } else if (template.$exists !== undefined) {
            result = (model, rootId) =>
                (model.get(jsonPointer.compile([rootId, ...path])) !==
                    undefined) ===
                template.$exists;
        } else {
            result = (model, rootId) =>
                Object.entries(template)
                    .map(([key, value]) =>
                        queryToPredicate(value, ctx, [...path, key]),
                    )
                    .every((subp) => subp(model, rootId));
        }
    } else {
        result = (model, rootId) => {
            const actual = model.get(jsonPointer.compile([rootId, ...path]));
            return (
                actual === template ||
                (Array.isArray(actual) &&
                    actual.map(({ value }) => value).includes(template))
            );
        };
    }

    return result;
}

export function sealed(o) {
    return typeof o === 'object' && o !== null
        ? new Proxy(o, {
              get(obj, prop) {
                  return sealed(obj[prop]);
              },

              set(obj, prop) {
                  throw new Error(`Sealed. Cannot set ${prop}.`);
              },
          })
        : o;
}
