import clone from 'clone';
import hash from 'object-hash';
import HorizontalMainLayout from '../fragments/HorizontalMainLayout.jsx';
import JobTile from '../fragments/JobTile.jsx';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';
import usePrettyPrint from '../../hooks/use-pretty-print.jsx';
import useRegistration from '../../hooks/use-registration.jsx';
import useWhiteboard from '../../hooks/use-whiteboard.jsx';
import VerticalMainLayout from '../fragments/VerticalMainLayout.jsx';
import Vm from '../../shared/vm.mjs';

import {
    compile as compileTantive,
    decompile as decompileTantive,
    TantiveError,
} from '../../utils/tantive.mjs';
import { css } from '@emotion/react';
import { Chip } from '../fragments/standard.jsx';
import { BsSortDown, BsSortDownAlt } from 'react-icons/bs';
import { IoOptionsOutline } from 'react-icons/io5';
import { LIViewportList } from '../fragments/ListItem.jsx';
import {
    GameStateContext,
    UsernodeDialogContext,
} from '../../app-contexts.jsx';
import { Else, If, Then, When } from 'react-if';
import { advancedSearchSpec, searchSpec } from './utils/Jobs/jobs.jsx';

const SearchOverlay = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 20px rgb(0, 0, 0, 0.4);
`;

const SearchInput = styled.input`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
`;

const SearchBarButton = styled.button`
    font-size: 1.5rem;
    height: unset;
`;

const defaultS = {
    text: '',
    filters: [],
    order: {
        direction: shipUtil.buildDNode('ascending'),
        order: shipUtil.buildDNode('cargoCt'),
    },
};

const Jobs = react.memo(function Jobs({
    map = true,
    onSelect,
    taskInitiator,
    whiteboard,
}) {
    const { model } = react.useContext(GameStateContext);
    const jobs = react.useMemo(
        () =>
            model.getMatches({
                kind: 'job',
                assignee: { $exists: false },
            }) ?? [],
        [model],
    );

    const [wbVars, updateWbVars] = useWhiteboard(whiteboard);
    const [annotations, setAnnotations] = react.useState([]);

    // originalSearch = as user specified in whiteboard (which could omit stuff
    //     and thus not meet searchSpec)
    // effectiveSearch = with defaults filled in as necessary, should thus meet
    //     searchSpec as long as everything non-default itself meets the spec
    const [effectiveSearch, originalSearch] = useSearch(model, wbVars);

    const setSearch = react.useCallback(
        (compiled) => {
            if (typeof compiled === 'function') {
                compiled = compiled(effectiveSearch);
            }

            const tantive = decompileTantiveNoError(
                {
                    ...defaultS,
                    ...compiled,
                },
                model,
                defaultS,
            );

            const newTantive = {};
            for (const k of Object.keys(compiled)) {
                if (k in tantive) {
                    newTantive[k] = tantive[k];
                }
            }

            updateWbVars({ s: newTantive });
        },
        [effectiveSearch, model, wbVars, updateWbVars],
    );

    const searchedJobs = react.useMemo(
        () =>
            Vm.tempVm(model, (vm) =>
                jobs.filter((jobId) => {
                    const matchesAllUserFilters = (
                        effectiveSearch.filters ?? []
                    ).every((f) =>
                        vm.evaluateSync(f, Vm.context(model.get(`/${jobId}`))),
                    );
                    const matchesText =
                        !effectiveSearch.text ||
                        shipUtil
                            .humanJobDescription(jobId, model)
                            .toLowerCase()
                            .includes(effectiveSearch.text.toLowerCase());

                    return matchesAllUserFilters && matchesText;
                }),
            ),
        [jobs, effectiveSearch],
    );

    const orderScorer = react.useMemo(
        () => buildOrderScorer(effectiveSearch?.order?.order),
        [effectiveSearch],
    );
    const jobsWithOrderScore = react.useMemo(
        () =>
            Vm.tempVm(model, (vm) => {
                const unsorted = searchedJobs.map((j) => {
                    const jobDetails = model.get(`/${j}`);

                    return [
                        j,
                        jobDetails
                            ? vm.evaluateSync(
                                  orderScorer,
                                  Vm.context(jobDetails),
                              )
                            : effectiveSearch?.order?.direction?.$d ===
                                'ascending'
                              ? Infinity
                              : -Infinity,
                    ];
                });
                unsorted.sort(([, s1], [, s2]) =>
                    effectiveSearch?.order?.direction?.$d === 'ascending'
                        ? s1 - s2
                        : s2 - s1,
                );
                return unsorted;
            }),
        [searchedJobs, orderScorer, model],
    );

    const annotationMap = react.useMemo(
        () => ({ map: { annotations } }),
        [annotations],
    );

    useRegistration('map', annotationMap?.map);

    return (
        <VerticalMainLayout
            css={css`
                height: 100%;
                overflow: hidden;
                position: relative;
            `}
            footer={
                <Search
                    onChange={setSearch}
                    search={effectiveSearch}
                    originalSearch={originalSearch}
                />
            }
        >
            <LIViewportList
                renderChild={([jId, score]) => (
                    <JobTile
                        mainAction={
                            onSelect
                                ? (id) => onSelect({ $ref: `/${id}` })
                                : () => {}
                        }
                        map={map}
                        key={jId}
                        id={jId}
                        score={score}
                        onAnnotate={setAnnotations}
                        sortAscending={
                            effectiveSearch?.order?.direction?.$d ===
                            'ascending'
                        }
                        taskInitiator={taskInitiator}
                    />
                )}
            >
                {jobsWithOrderScore}
            </LIViewportList>
        </VerticalMainLayout>
    );
});

export default Jobs;

function useSearch(model, wbVars) {
    let userS = wbVars.s ?? {};

    let effectiveS = react.useMemo(
        () => ({
            ...defaultS,
            ...userS,
        }),
        [userS],
    );

    let compiledEffectiveS;
    try {
        compiledEffectiveS = react.useMemo(
            () => compileTantive(effectiveS, model, searchSpec),
            [effectiveS, model, searchSpec],
        );
    } catch (e) {
        if (!(e instanceof TantiveError)) {
            shipUtil.unexpectedError(e);
        }

        console.error(e);
        userS = react.useMemo(() => {}, []);
        effectiveS = defaultS;
        compiledEffectiveS = react.useMemo(
            () => compileTantive(effectiveS, model, searchSpec),
            [effectiveS, model, searchSpec],
        );
    }
    const compiledUserS = react.useMemo(() => {
        const result = {};
        for (const k of Object.keys(userS)) {
            if (k in compiledEffectiveS) {
                result[k] = compiledEffectiveS[k];
            }
        }
        return result;
    }, [userS, compiledEffectiveS]);

    const result = react.useMemo(
        () => [compiledEffectiveS, compiledUserS],
        [compiledEffectiveS, compiledUserS],
    );

    return result;
}

function AdvancedSearchButton({ onClick }) {
    return (
        <SearchBarButton onClick={onClick}>
            <IoOptionsOutline />
        </SearchBarButton>
    );
}

function AdvancedSearchOverview({ search, setSearch, originalSearch }) {
    function toggleOrderDirection() {
        setSearch((v) => ({
            ...v,
            order: {
                ...v?.order,
                direction:
                    search?.order?.direction?.$d === 'ascending'
                        ? { $d: 'descending' }
                        : { $d: 'ascending' },
            },
        }));
    }

    function removeFilter(index) {
        setSearch((v) => {
            const newFilters = clone(v?.filters ?? []);
            newFilters.splice(index, 1);

            return {
                ...originalSearch,
                filters: newFilters,
            };
        });
    }

    const prettyPrint = usePrettyPrint();

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: start;
                padding: 2px;
            `}
        >
            <When condition={originalSearch?.order}>
                <Chip
                    id='searchOrder'
                    css={css`
                        background-color: rgb(235, 255, 255);
                        border-color: blue;
                        color: blue;
                    `}
                    element='button'
                    elementCss={css`
                        background-color: unset;
                        border-color: unset;
                        border-style: none;
                        color: unset;
                    `}
                    onClick={toggleOrderDirection}
                    onRemove={() =>
                        setSearch((v) => shipUtil.withoutKey(v, 'order'))
                    }
                    removable={true}
                    size='compact'
                >
                    <If
                        condition={search?.order?.direction?.$d === 'ascending'}
                    >
                        <Then>
                            <BsSortDownAlt />
                        </Then>
                        <Else>
                            <BsSortDown />
                        </Else>
                    </If>
                    <span>
                        {prettyPrint(
                            '',
                            advancedSearchSpec.order.order,
                            search?.order?.order,
                        )}
                    </span>
                </Chip>
            </When>
            {(search?.filters || []).map((f, i) => (
                <Chip
                    key={`filter_${hash([f, i])}`}
                    css={css`
                        background-color: rgb(255, 255, 235);
                        border-color: brown;
                        color: brown;
                    `}
                    onRemove={() => removeFilter(i)}
                    removable={true}
                    size='compact'
                >
                    {prettyPrint(
                        '',
                        advancedSearchSpec.filters.$array.elements,
                        f,
                    )}
                </Chip>
            ))}
        </div>
    );
}

function buildOrderScorer(order) {
    return order ? order : { $d: 'reward' };
}

function decompileTantiveNoError(usernode, model, fallback) {
    let result;
    try {
        result = decompileTantive(usernode, model, {
            ...advancedSearchSpec,
            text: { $string: { maxLength: 100 } },
        });
    } catch (e) {
        result = fallback;
    }

    return result;
}

function Search({ onChange, search, originalSearch }) {
    function onTextInputChange(e) {
        onChange((v) => ({
            ...v,
            text: e.target.value,
        }));
    }

    const usernodeDialog = react.useContext(UsernodeDialogContext);

    async function onAdvancedSearchClick() {
        const advancedOptions = await usernodeDialog(
            advancedSearchSpec,
            search,
            { context: 'job' },
        );

        if (advancedOptions) {
            onChange((s) => ({
                ...s,
                ...advancedOptions,
            }));
        }
    }

    return (
        <SearchOverlay>
            <When condition={needsAdvancedDisplay(search, originalSearch)}>
                <AdvancedSearchOverview
                    search={search}
                    setSearch={onChange}
                    originalSearch={originalSearch}
                />
            </When>
            <HorizontalMainLayout
                right={<AdvancedSearchButton onClick={onAdvancedSearchClick} />}
            >
                <SearchInput value={search.text} onChange={onTextInputChange} />
            </HorizontalMainLayout>
        </SearchOverlay>
    );
}

function needsAdvancedDisplay(search, originalSearch) {
    const stuff = Object.entries(originalSearch).filter(
        ([k, v]) => k !== 'text' && !(Array.isArray(v) && v.length === 0),
    );

    return stuff.length > 0;
}
