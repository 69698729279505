const keywords = {
    $cond: ['((x, y, z) => x ? y : z)', 3],
    $e: Math.E,
    $pi: Math.PI,
    $sqrt: ['Math.sqrt', 1],
};

const operators = {
    '^': [4, 'right'],
    '*': [3, 'left'],
    '/': [3, 'left'],
    '+': [2, 'left'],
    '-': [2, 'left'],
    '>=': [4, 'left'],
    '<=': [4, 'left'],
    '>': [4, 'left'],
    '<': [4, 'left'],
    '=': [3, 'left', '==='],
};

const opPattern = Object.keys(operators)
    .map((o) => `(?:\\${o})`)
    .join('|');

const lex = {
    exp: [
        /^(?:\-?\d*\.\d+|\-?\d+|\(|\$\w+)/,
        (m) => (m === '(' ? 'exp' : 'op'),
    ],
    op: [
        new RegExp(`^(?:\\(|\\)|\\,|${opPattern})`),
        (m) => (m === ')' ? 'op' : 'exp'),
    ],
};

const ws = /^([ \t]+|[\n\r])/;

export default function artithmetic(input) {
    let src = '';
    const stack = [];

    for (const [type, tok] of parse(input)) {
        if (type === 'number') {
            stack.push(`${tok}`);
        } else if (type === 'arg') {
            stack.push(`args[${tok - 1}]`);
        } else if (type === 'fn') {
            const [fn, argCt] = keywords[tok];
            const args = [];
            for (let i = 0; i < argCt; i++) {
                args.push(stack.pop());
            }
            args.reverse();

            stack.push(`${fn}(${args.join(', ')})`);
        } else {
            // An operator.
            const right = stack.pop();
            const left = stack.pop();

            if (tok === '^') {
                stack.push(`Math.pow(${left}, ${right})`);
            } else {
                stack.push(`(${left} ${tok} ${right})`);
            }
        }
    }

    return eval?.(`(...args) => ${stack.pop()}`);
}

function extExp(stack) {}

function tokenize(str) {
    let col = 1;
    let line = 1;
    let state = 'exp';
    const tokens = [];
    while (str.length > 0) {
        let [wsMatch] = str.match(ws) ?? [];
        while (wsMatch !== undefined) {
            if (wsMatch === '\n' || wsMatch === '\r') {
                col = 1;
                line++;
            }

            str = str.substring(wsMatch.length);

            [wsMatch] = str.match(ws) ?? [];
        }

        const [matcher, nextState] = lex[state];
        const [match] = str.match(matcher) ?? [];

        if (match === undefined) {
            if (str.length !== 0) {
                throw new Error(`Expected a "${state}" token at: ${str}`);
            }
        } else {
            str = str.substring(match.length);

            tokens.push(match);
            state = nextState(match);
        }
    }

    return tokens;
}

const cachedInputs = new Map();
function parse(input) {
    let parsed = cachedInputs.get(input);

    if (!parsed) {
        const tokens = tokenize(input);
        const args = [];

        const output = [];
        const stack = [];

        function startParen() {
            stack.push(['(']);
        }

        function endParen() {
            while (top(stack)[0] !== '(') {
                output.push(stack.pop());
            }

            const [lparen] = stack.pop();
            if (lparen !== '(') {
                throw new Error('mismatched parens: ' + input);
            }

            if (top(stack)?.[0] === 'fn') {
                output.push(stack.pop());
            }
        }

        while (tokens.length > 0) {
            const token = tokens.shift();
            if (/^(?:\-)?((\d*\.\d+)|\d+)$/.test(token)) {
                const v = parseFloat(token);

                if (isNaN(v) || v === Infinity || v === -Infinity) {
                    throw new Error('Invalid number: ' + token);
                }

                output.push(['number', parseFloat(token)]);
            } else if (token === '(') {
                startParen();
                startParen();
            } else if (token === ')') {
                endParen();
                endParen();
            } else {
                // An operator, keyword, or variable
                if (/^\$\w+$/.test(token)) {
                    // A keyword.

                    if (/^\d+$/.test(token.substring(1))) {
                        // An argument.
                        const argNum = parseInt(token.substring(1));

                        if (isNaN(argNum) || argNum < 1 || argNum >= 50) {
                            throw new Error(
                                'Invalid argument position: ' + token
                            );
                        }

                        args[argNum] = true;
                        output.push(['arg', argNum]);
                    } else {
                        if (!keywords[token]) {
                            throw new Error('Unknown keyword: ' + token);
                        }

                        const val = keywords[token];
                        if (Array.isArray(val)) {
                            // A function
                            stack.push(['fn', token]);
                        } else {
                            // A constant
                            output.push(['number', keywords[token]]);
                        }
                    }
                } else if (token === ',') {
                    endParen();
                    startParen();
                } else {
                    // An operator.
                    if (!operators[token]) {
                        throw new Error(`Unknown operator: "${token}"`);
                    }

                    while (
                        stack.length !== 0 &&
                        top(stack)[0] !== '(' &&
                        (precedence(top(stack)) > precedence(['op', token]) ||
                            (precedence(top(stack)) ===
                                precedence(['op', token]) &&
                                assoc(['op', token]) === 'left'))
                    ) {
                        output.push(stack.pop());
                    }

                    stack.push(['op', operators[token][2] ?? token]);
                }
            }
        }

        while (stack.length > 0) {
            if (top(stack)[0] === '(') {
                throw new Error('Unclosed left parenthesis.');
            }

            output.push(stack.pop());
        }

        parsed = output;
        cachedInputs.set(input, output);
    }

    return parsed;
}

function assoc([, o]) {
    return operators[o][1];
}

function precedence([, o]) {
    return operators[o][0];
}

function top(stack) {
    return stack[stack.length - 1];
}
