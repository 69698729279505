import react from 'react';
import styled from '@emotion/styled';

import { InvisibleButton } from './standard.jsx';
import { BsInfoCircle } from 'react-icons/bs';
import { Popover } from 'react-tiny-popover';

const InfoButtonPopover = styled.div`
    max-width: calc(100vw * 0.7);
    min-width: 0;
    background-color: ${(props) => props.theme.colors.card};
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.35);
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 5px;
`;

export default function InfoButton({ info }) {
    const [showPopover, setShowPopover] = react.useState();

    return (
        <Popover
            isOpen={showPopover}
            content={<InfoButtonPopover>{info}</InfoButtonPopover>}
            onClickOutside={() => setShowPopover(false)}
        >
            <InvisibleButton onClick={() => setShowPopover((show) => !show)}>
                <BsInfoCircle />
            </InvisibleButton>
        </Popover>
    );
}
