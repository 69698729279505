import CredSym from './CredSym.jsx';
import react from 'react';

import { Chip } from './standard.jsx';
import { css } from '@emotion/react';
import { Else, If, Then } from 'react-if';
import { GameStateContext } from '../../app-contexts.jsx';

export default function MarketSummary({ data = [] }) {
    return (
        <If condition={data.length === 0}>
            <Then>
                <Empty />
            </Then>
            <Else>
                <ul>
                    {data.map((subdata, i) => (
                        <li key={i}>
                            <Entry data={subdata} />
                        </li>
                    ))}
                </ul>
            </Else>
        </If>
    );
}

function Entry({ data: { quantity, item, price } = {} } = {}) {
    const { model } = react.useContext(GameStateContext);

    if (quantity !== 1) {
        throw new Error();
    }

    return (
        <react.Fragment>
            <Chip
                css={css`
                    font-weight: 600;
                    margin-right: 4px;
                `}
                variant='primary'
                size='compact'
            >
                for-sale
            </Chip>
            <span>{`${quantity} ${model.get(item)?.name}, `}</span>
            <CredSym>{price}</CredSym>
        </react.Fragment>
    );
}

function Empty() {
    return (
        <p
            css={css`
                text-align: center;
                font-style: oblique;

                &::after {
                    content: '>';
                }

                &::before {
                    content: '<';
                }
            `}
        >
            empty
        </p>
    );
}
