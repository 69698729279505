import Stations from '../../../../components/pages/Stations.jsx';

import { useRouteError, useSearchParams } from 'react-router-dom';

export default {
    path: '/stations',
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    Component: () => {
        const [params] = useSearchParams();
        return <Stations corpId={JSON.parse(params.get('corp') ?? 'null')} />;
    },
    handle: {},
};
