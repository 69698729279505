import DisplayContext from '../../subcomponents/usernode-editor/DisplayContext.mjs';
import react from 'react';
import * as shipUtil from '../../../../utils/utils.mjs';
import VerticalMainLayout from '../../VerticalMainLayout.jsx';
import jsonPointer from 'json-pointer';

import { buildUi } from './build-ui.jsx';
import { css } from '@emotion/react';
import { ValidationMessage } from '../../standard.jsx';
import {
    DiscrimNodeTypeSelect,
    ShorthandDiscriminatorNode,
    TopLevelContent,
} from './DiscriminatorNode.jsx';

const topLevelSelectCss = css`
    font-size: large;
    text-align: center;
`;

export default function buildDiscriminatorNode(
    spec,
    value,
    expectedType,
    onChange,
    ctx,
) {
    if (Object.keys(shipUtil.withoutAnnotations(spec)).length === 0) {
        throw new Error('zero $descrim options: ' + shipUtil.debugString(spec));
    }

    ctx = { ...ctx, displayContext: new DisplayContext(spec) };

    // Just the options that meet our expected type + context requirements.
    const constrainedSpec = shipUtil.constrainedDiscrim(
        spec,
        ctx.context,
        expectedType,
    );

    let [intendedDiscrimKey, subvalue] = shipUtil.specDiscrim(
        constrainedSpec,
        value,
    );

    if (spec[intendedDiscrimKey] && !constrainedSpec[intendedDiscrimKey]) {
        // The selected option has been constrained away. Let's clear our value
        // so we can provide an acceptible default.
        value = undefined;
        [intendedDiscrimKey, subvalue] = shipUtil.specDiscrim(
            constrainedSpec,
            value,
        );
    }

    if (!spec[intendedDiscrimKey]) {
        // Discrim key isn't specified by our spec?
        throw new Error(
            `Value (type ${intendedDiscrimKey}): \n\n` +
                `${shipUtil.debugString(value)}\n\nDoes not conform to spec: ` +
                `\n\n${shipUtil.debugString(spec)}`,
        );
    }

    if (shipUtil.isNil(subvalue) || Object.keys(subvalue).length === 0) {
        value = shipUtil.buildDNode(intendedDiscrimKey, {});
        [, subvalue] = shipUtil.specDiscrim(constrainedSpec, value);
    }

    const categories = buildCategories(constrainedSpec);
    const intendedCategory = getCategory(categories, intendedDiscrimKey);

    const sameCategorySpec = Object.fromEntries(
        Object.entries(constrainedSpec).filter(
            ([k]) =>
                k.startsWith('$') ||
                getCategory(categories, k) === intendedCategory,
        ),
    );

    const wbPrefix = `/nodeimpl/discrim${ctx.path}`;
    const whiteboard = ctx.flexState.get(wbPrefix) ?? {
        categories: {},
        types: {},
    };

    function changeDiscrim(newType) {
        const oldType = intendedDiscrimKey;
        const oldCategory = getCategory(categories, oldType);

        ctx.flexState.set(`${wbPrefix}/categories`, {
            ...ctx.flexState.get(`${wbPrefix}/categories`),
            [oldCategory]: oldType,
        });

        ctx.flexState.set(`${wbPrefix}/types`, {
            ...ctx.flexState.get(`${wbPrefix}/types`),
            [oldType]: subvalue,
        });

        onChange(shipUtil.buildDNode(newType, whiteboard.types[newType]));
    }

    let component, properties;
    if (spec?.[intendedDiscrimKey]?.$shorthand && intendedCategory === '') {
        // Shorthand-enabled value.

        [component, properties, value] = buildShorthandDiscriminatorNode(
            constrainedSpec,
            value,
            expectedType,
            changeDiscrim,
            (v, opts) => {
                console.log('v', v);
                onChange(shipUtil.buildDNode(intendedDiscrimKey, v), opts);
            },
            ctx,
        );
    } else {
        // Non-shorthand-enabled value.

        const dropdownOptions = [
            ...(categories[''] ?? []).map(([k, v]) => (
                <option
                    key={`${uri(ctx.path)}/type/${uri(k)}`}
                    value={`t_${k}`}
                >
                    {v.$label ?? k}
                </option>
            )),
            ...Object.keys(categories)
                .filter((k) => k !== '')
                .map((k) => (
                    <option
                        key={`${uri(ctx.path)}/cat/${uri(k)}`}
                        value={`c_${k}`}
                    >
                        {k}
                    </option>
                )),
        ];

        const [
            subSpec,
            {
                context: subContext,
                expectedType: subExpectedType,
                lexicalContextStack: newLexicalContextStack,
            },
        ] = shipUtil.effectiveSpec(
            ctx.context,
            expectedType,
            { $descrim: constrainedSpec },
            intendedDiscrimKey,
            ctx.lexicalContextStack,
        );

        if (intendedCategory === '') {
            // Discriminator keys in the default category appear in the
            // category selector dropdown alongside non-default categories
            // (whose corresponding keys are then only displayed once they are
            // selected.)

            [component, properties, subvalue] = buildUi(
                subSpec,
                subvalue,
                subExpectedType,
                (v, opts) => {
                    onChange(shipUtil.buildDNode(intendedDiscrimKey, v), opts);
                },
                {
                    ...ctx,

                    context: subContext,
                    displayContext:
                        ctx.displayContext.child(intendedDiscrimKey),
                    displayHierarchy: [
                        ...ctx.displayHierarchy,
                        'DiscriminatorNode',
                    ],
                    lexicalContextStack: newLexicalContextStack,
                    path: ctx.path,

                    touched: maybeGetRidOfDictValueLayer(ctx.touched),
                    validationErrors: maybeGetRidOfDictValueLayer(
                        ctx.validationErrors,
                    ),
                },
            );

            value = shipUtil.buildDNode(intendedDiscrimKey, subvalue);
        } else {
            // For discriminator keys outside the default category, we first
            // display a category dropdown, then an embedded dropdown
            // (or possibly shorthand) for the values from within that category.
            // Practically, we'll just display the category dropdown here, then
            // create a "virtual discriminator" to represent the values within
            // the category and let an additional call to buildUi() provided the
            // second dropdown or the shorthand.

            [component, properties, value] = buildUi(
                {
                    $descrim: {
                        ...sameCategorySpec,
                        $categorize: () => {},
                    },
                },
                value,
                expectedType,
                (v, opts) => {
                    onChange(v, opts);
                },
                {
                    ...ctx,
                    displayHierarchy: [
                        ...ctx.displayHierarchy,
                        'DiscriminatorNode',
                    ],
                    lexicalContextStack: newLexicalContextStack,
                },
            );

            properties.structured = true;
        }

        const selector = (
            <DiscrimNodeTypeSelect
                name={ctx.path}
                value={
                    intendedCategory === ''
                        ? `t_${intendedDiscrimKey}`
                        : `c_${intendedCategory}`
                }
                css={ctx.displayHierarchy.length <= 1 ? topLevelSelectCss : []}
                onChange={(e) => {
                    const value = e.target.value;
                    if (value.startsWith('c_')) {
                        const previousCategoryType =
                            whiteboard.categories[value.substring(2)] ??
                            categories[value.substring(2)][0][0];

                        changeDiscrim(previousCategoryType);
                    } else {
                        changeDiscrim(value.substring(2));
                    }
                }}
            >
                {dropdownOptions}
            </DiscrimNodeTypeSelect>
        );

        if (
            ctx.displayHierarchy.length === 0 ||
            properties.structured ||
            intendedCategory !== ''
        ) {
            component = (
                <VerticalMainLayout
                    key={`${ctx.path}_selector`}
                    header={selector}
                >
                    <TopLevelContent>{component}</TopLevelContent>
                </VerticalMainLayout>
            );
        } else {
            component = selector;
        }
    }

    if (ctx.validationErrors[''] && ctx.touched.has('')) {
        component = (
            <react.Fragment>
                {component}
                <ValidationMessage>
                    {`Must be ${ctx.validationErrors[''].expected}.`}
                </ValidationMessage>
            </react.Fragment>
        );
    }

    if (spec.$label) {
        properties.label = spec.$label;
    }

    return [component, properties, value];
}

function buildCategories(constrainedSpec) {
    const categorizeFn = constrainedSpec.$categorize ?? (() => null);

    const categories = {};
    for (const [key, value] of Object.entries(constrainedSpec).filter(
        ([k]) => !k.startsWith('$'),
    )) {
        let category;
        try {
            category = categorizeFn(value, key) ?? '';
        } catch (e) {
            throw new Error(
                '$categorize failed on ' +
                    shipUtil.debugString(value) +
                    ': ' +
                    e.message,
            );
        }

        if (!categories[category]) {
            categories[category] = [];
        }

        categories[category].push([key, value]);
    }

    const firstKey = Object.keys(categories)?.[0];
    if (Object.keys(categories).length === 1 && !categories['']) {
        categories[''] = categories[firstKey];
        delete categories[firstKey];
    }

    return categories;
}

function buildShorthandDiscriminatorNode(
    spec,
    value,
    expectedType,
    changeDiscrim,
    changeValue,
    ctx,
) {
    const [type, data] = shipUtil.dnode(value, Object.keys(spec)[0]);

    const onPlaceholderClick = async (pivot, field) => {
        const subExpectedType = getSpecField(
            spec,
            pivot,
            '$expectedTypes',
            field,
        );

        const result = await ctx.queryUser(
            getSpecField(spec, pivot, field),
            value?.[field],
            {
                context: ctx.context,
                expectedType: subExpectedType,
                lexicalContextStack: ctx.lexicalContextStack,
            },
        );

        console.log('result', result);

        if (result !== undefined) {
            changeValue({
                ...data,
                [field]: result,
            });
        }
    };

    const onPivotClick = async (pivot, label) => {
        const result = await ctx.queryUser(
            buildPivotSelectionSpec(spec, label),
            shipUtil.buildDNode(type, {}),
        );

        if (result !== undefined) {
            changeDiscrim(shipUtil.dnode(result)[0]);
        }
    };

    return [
        // eslint-disable-next-line react/jsx-key
        <ShorthandDiscriminatorNode
            data={value}
            {...{
                onPivotClick,
                onPlaceholderClick,
                spec,
                type,
                path: ctx.path,

                touched: maybeGetRidOfDictValueLayer(ctx.touched),
                validationErrors: maybeGetRidOfDictValueLayer(
                    ctx.validationErrors,
                ),
            }}
        />,
        {},
        value,
    ];
}

function buildPivotSelectionSpec(descrimSpec, label) {
    return {
        $descrim: Object.fromEntries(
            Object.entries(descrimSpec)
                .filter(([key]) => !key.startsWith('$'))
                .map(([key, value]) => [
                    key,
                    {
                        ...(label ? { $label: label } : { $label: key }),
                        ...shipUtil.maybeField(value, '$label'),
                    },
                ]),
        ),
    };
}

function getCategory(categories, q) {
    const [k] =
        Object.entries(categories).find(([, options]) =>
            options.some(([optionName]) => optionName === q),
        ) ?? [];

    if (k === undefined) {
        throw new Error(
            'No such value: ' + q + ' ' + shipUtil.debugString(categories),
        );
    }

    return k;
}

function getSpecField(s, ...fs) {
    for (const f of fs) {
        if (!s) {
            return undefined;
        }

        s = shipUtil.extendSpec(s);
        s = s[f];
    }

    return s;
}

// Discrim values may be encoded as { valField1: ..., valFieldN: .... }, or as
// { $v: { valField1: ..., valFieldN: ... } }. For the values themselves,
// shipUtil.dnode() takes care of unpacking this complexity for us, but a couple
// of our parameters (namely, "touched" and "validationErrors") come to us as
// jsonPointer "dict"-style objects, i.e.,
// { "/path/to/field1": ..., "path/to/fieldN": ... }. Given such a dict, trim
// potential "/$v" prefixes so we've got a consistent way of talking about value
// paths.
function maybeGetRidOfDictValueLayer(o) {
    if (typeof o !== 'object') {
        return o;
    }

    if (o instanceof Set) {
        return new Set(
            [...o].map((k) => {
                const parsedKey = jsonPointer.parse(k);
                parsedKey.shift();
                return jsonPointer.compile(parsedKey);
            }),
        );
    }

    const result = {};
    for (const [k, v] of Object.entries(o)) {
        const parsedKey = jsonPointer.parse(k);

        if (parsedKey?.[0] === '$v') {
            parsedKey.shift();
        }

        result[jsonPointer.compile(parsedKey)] = v;
    }

    return result;
}

var uri = encodeURIComponent;
