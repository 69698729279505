import Station from '../../../components/pages/Station.jsx';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/stations/:stationId',
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    Component: () => {
        const { stationId } = useParams();
        return <Station stationId={stationId} />;
    },
    handle: {
        title: (params, model) => model.get(`/${params.stationId}`)?.name,
        upUrl: '/stations',
    },
};
