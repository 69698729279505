import styled from '@emotion/styled';

import { BsCopy } from 'react-icons/bs';
import { CopyToClipboard as BaseCopyToClipboard } from 'react-copy-to-clipboard';
import { css } from '@emotion/react';
import { IconButton } from './standard.jsx';
import { Popover } from 'react-tiny-popover';
import { useState } from 'react';

const CTCPopoverText = styled.p`
    max-width: calc(100vw * 0.7);
    min-width: 0;
    background-color: ${(props) => props.theme.colors.card};
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.35);
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 5px;
`;

export default function CopyToClipboard({ text }) {
    const [showPopover, setShowPopover] = useState();

    return (
        <Popover
            positions={['right', 'left', 'bottom', 'top']}
            isOpen={showPopover}
            content={
                <CTCPopoverText>&#x2714; Copied to clipboard.</CTCPopoverText>
            }
        >
            <div>
                <BaseCopyToClipboard
                    text={text}
                    onCopy={() => {
                        setShowPopover(true);
                        setTimeout(() => setShowPopover(false), 1500);
                    }}
                >
                    <IconButton
                        css={(theme) =>
                            css`
                                color: ${theme.colors.deprioritizedText};
                            `
                        }
                    >
                        <BsCopy />
                    </IconButton>
                </BaseCopyToClipboard>
            </div>
        </Popover>
    );
}
