import * as React from "react";
const SvgAegisDark = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 131.678, height: 55.209, viewBox: "0 0 34.84 14.607", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "aegis-dark_svg__c", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M71.882 98.763h62.65v23.813h-62.65z", style: {
  fill: "#fff",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 1.92200005,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "aegis-dark_svg__b", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M71.882 98.763h62.65v23.813h-62.65z", style: {
  fill: "#fff",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 1.92200005,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "rotate(-17.027)" })), /* @__PURE__ */ React.createElement("clipPath", { id: "aegis-dark_svg__a", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M71.882 98.763h62.65v23.813h-62.65z", style: {
  fill: "#fff",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 1.92200005,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
} }))), /* @__PURE__ */ React.createElement("g", { transform: "translate(-80.433 -107.968)" }, /* @__PURE__ */ React.createElement("g", { "aria-label": "\\xE6", clipPath: "url(#aegis-dark_svg__a)", style: {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10.58333302px",
  lineHeight: 1.25,
  fontFamily: "sans-serif",
  letterSpacing: 0,
  wordSpacing: 0,
  fill: "#000",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 0.26458332
} }, /* @__PURE__ */ React.createElement("path", { d: "M95.435 115.273q0 1.414.372 2.418.373 1.005 1.054 1.65.645.595 1.526.88a6.6 6.6 0 0 0 1.947.273q1.377 0 2.691-.508 1.327-.522 2.146-1.166h.149v2.542q-.967.434-2.295.807-1.314.36-2.666.36-2.059 0-3.535-.646a5.9 5.9 0 0 1-2.406-1.922q-.26.235-.83.732-.572.495-1.191.843a8.5 8.5 0 0 1-1.7.732q-.917.297-2.344.297-1.86 0-3.175-1.203-1.302-1.203-1.302-3.088 0-1.538.67-2.48c.446-.637 1.099-.94 1.934-1.287.786-.323 1.888-.494 3.095-.61l1.098 1.487q-1.191.05-2.208.31-.98.249-1.588.819-.594.558-.595 1.588 0 1.178.72 1.748t2.182.57q1.228 0 2.419-.533a7 7 0 0 0 2.071-1.376 8 8 0 0 1-.384-1.476 12 12 0 0 1-.125-1.749l.112-1.835v-.757q0-.806-.285-1.327a2 2 0 0 0-.794-.818 3.3 3.3 0 0 0-1.19-.385 9 9 0 0 0-1.44-.112q-.978 0-2.095.26-1.104.262-2.22.708h-.161v-2.369a26 26 0 0 1 1.96-.422 14 14 0 0 1 2.53-.235q1.971 0 3.273.583 1.315.57 2.022 1.699a6.8 6.8 0 0 1 2.108-1.662q1.253-.645 2.803-.645 2.692 0 4.242 1.587c1.323 1.337 1.08 2.614 1.056 3.618-.332 2.113-2.966 2.454-2.966 2.454m-1.419-1.042c1.53-.326 2.73-1.396 2.337-2.574a3.4 3.4 0 0 0-.62-1.091 2.8 2.8 0 0 0-1.104-.757q-.67-.273-1.65-.273-1.65 0-2.765.93-1.117.93-1.427 2.667", style: {
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: 400,
  fontStretch: "normal",
  fontSize: "25.39999962px",
  fontFamily: "Verdana",
  InkscapeFontSpecification: "Verdana",
  strokeWidth: 0.26458332
} })), /* @__PURE__ */ React.createElement("ellipse", { cx: 131.55, cy: 90.638, clipPath: "url(#aegis-dark_svg__b)", rx: 18.416, ry: 9.865, style: {
  fill: "none",
  fillOpacity: 1,
  stroke: "#fff",
  strokeWidth: 1.92200005,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "rotate(17.027)" }), /* @__PURE__ */ React.createElement("circle", { cx: 109.254, cy: 118.57, r: 2.693, clipPath: "url(#aegis-dark_svg__c)", style: {
  fill: "#000",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 0.84925586,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
} })));
export default SvgAegisDark;
