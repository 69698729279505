import styled from '@emotion/styled';
import useSize from '@react-hook/size';

import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';
import { When } from 'react-if';

const ProgressBarCenterVertical = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const ProgressBarBase = styled.div`
    display: grid;
    position: relative;
    overflow: hidden;

    flex-grow: 1;

    background-color: white;
    border-color: rgb(150, 150, 150);
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
`;

const ProgressBarLayer = styled.div`
    grid-area: 1 / 1;
    overflow: hidden;
    position: relative;
`;

const ExpectedCompletion = styled.div`
    padding-left: 10px;
    box-shadow: -5px 0px 10px rgb(0, 0, 0, 0.25);
    position: absolute;
    right: 0;
`;

export default function ProgressBar({
    className,
    expectedCompletion,
    label,
    progress,
}) {
    return (
        <ProgressBarCenterVertical className={className}>
            <ProgressBarBase>
                <ProgressBarLayer>
                    <ProgressBarContent
                        expectedCompletion={expectedCompletion}
                        label={label}
                    />
                </ProgressBarLayer>
                <ProgressBarLayer
                    css={css`
                        clip-path: polygon(
                            0 0,
                            ${100 * progress}% 0,
                            ${100 * progress}% 100%,
                            0 100%
                        );
                    `}
                >
                    <ProgressBarContent
                        expectedCompletion={expectedCompletion}
                        invert={true}
                        label={label}
                    />
                </ProgressBarLayer>
            </ProgressBarBase>
        </ProgressBarCenterVertical>
    );
}

function ProgressBarContent({ expectedCompletion, invert, label }) {
    const parentRef = useRef();
    const marqueeRef = useRef();
    const ecRef = useRef();

    const [parentWidth] = useSize(parentRef);
    const [marqueeWidth] = useSize(marqueeRef);
    const [ecWidth] = useSize(ecRef);

    return (
        <div
            ref={parentRef}
            css={css`
                width: 100%;
            `}
        >
            <div
                css={(theme) => css`
                    position: absolute;
                    display: flex;
                    background-color: ${invert
                        ? theme.colors.secondary
                        : theme.colors.background};
                    color: ${invert ? 'white' : theme.colors.text};

                    width: 100%;
                `}
            >
                <Marquee
                    animate={marqueeWidth > parentWidth - ecWidth}
                    Component={ProgressBarLabel}
                    componentProps={{ label }}
                    componentRef={marqueeRef}
                />
            </div>
            <ExpectedCompletion
                ref={ecRef}
                css={(theme) => css`
                    background-color: ${invert
                        ? theme.colors.secondary
                        : theme.colors.card};
                    color: ${invert ? 'white' : theme.colors.text};
                `}
            >
                {expectedCompletion}
            </ExpectedCompletion>
            <p
                css={css`
                    visibility: hidden;
                `}
            >
                &nbsp;
            </p>
        </div>
    );
}

var ProgressBarLabel = forwardRef(function ProgressBarLabel({ label }, ref) {
    return (
        <p
            css={css`
                white-space: nowrap;
            `}
            ref={ref}
        >
            {label}
        </p>
    );
});

function Marquee({ animate = true, Component, componentProps, componentRef }) {
    return (
        <div
            css={css`
                display: flex;
                width: calc(200% + 200px);

                @keyframes slidein {
                    0% {
                        translate: 0 0;
                    }

                    15% {
                        translate: 0 0;
                    }

                    100% {
                        translate: calc(-50%) 0;
                    }
                }

                ${animate ? 'animation-name: slidein;' : ''}
                animation-duration: 10s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
            `}
        >
            <Component ref={componentRef} {...componentProps} />
            <div
                css={css`
                    width: 100px;
                `}
            />
            <When condition={animate}>
                <Component {...componentProps} />
                <div
                    css={css`
                        width: 100px;
                    `}
                />
            </When>
        </div>
    );
}
