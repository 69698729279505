import react from 'react';
import styled from '@emotion/styled';

const OverlayLayoutWrapper = styled.div`
    position: relative;
    display: grid;
    z-index: 0;

    & > * {
        grid-area: 1 / 1;
        pointer-events: none;
        z-index: 0;
    }
`;

const OverlayLayout = react.forwardRef(function OverlayLayout(
    { children, className },
    ref
) {
    return (
        <OverlayLayoutWrapper ref={ref} className={className}>
            {children}
        </OverlayLayoutWrapper>
    );
});

export default OverlayLayout;
