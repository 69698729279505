import Color from 'color';
import OverlayLayout from './OverlayLayout.jsx';
import react from 'react';
import Section from './Section.jsx';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { TiDelete } from 'react-icons/ti';
import { RiAlertFill as AlertIco } from 'react-icons/ri';
import { When } from 'react-if';
import { forwardRef } from 'react';

export { RiAlertFill as AlertIco } from 'react-icons/ri';

const buttonBase = css`
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    height: var(--height);
    padding-left: var(--short-padding);
    padding-right: var(--short-padding);
    box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);
    box-sizing: border-box;
`;

const cardBase = css`
    background-color: white;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    height: calc(3rem + 6px);
    line-height: 1rem;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 3px;
    border-radius: 5px;
    align-items: center;

    display: flex;
`;

const checkboxBase = (theme) => css`
    display: flex;
    padding-top: ${theme.metrics.targetableClearance};
    padding-bottom: ${theme.metrics.targetableClearance};

    & > input {
        margin-right: 15px;
    }
`;

const chipBase = css`
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
`;

const chipContents = css`
    display: flex;
    justify-items: center;
    padding-top: var(--tiny-padding);
    padding-bottom: calc(var(--tiny-padding) - 1px);
    padding-left: var(--short-padding);
    padding-right: var(--short-padding);
`;

const floatingButtonBase = (theme) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: var(--height);
    min-width: calc(var(--height) * 1.5);
    height: calc(var(--height) * 1.5);
    border-style: none;
    border-width: 0;
    background-color: ${theme.colors.primary};
    box-shadow: 3px 3px 15px rgb(0, 0, 0, 0.25);

    & > svg {
        width: var(--height);
        height: var(--height);
    }
`;

const iconButtonBase = css`
    border: none;
    background-color: unset;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: var(--height);

    height: calc(var(--height) + (var(--short-padding) * 2));
    width: calc(var(--height) + (var(--short-padding) * 2));
`;

const OverlayBackdrop = styled.div`
    background-color: rgb(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
`;

const selectBase = css`
    padding-top: var(--short-padding);
    padding-bottom: var(--short-padding);
    padding-left: var(--short-padding);
`;

const sizeCss = {
    large: css`
        --height: 3rem;

        --tiny-padding: 4px;
        --short-padding: 10px;
        --long-padding: 20px;

        font-size: 1.5rem;
        line-height: 200%;
    `,
    normal: css`
        --height: 1.5rem;

        --tiny-padding: 2px;
        --short-padding: 5px;
        --long-padding: 10px;

        font-size: 1rem;
    `,
    compact: css`
        --height: 1rem;
        --tiny-padding: 0px;
        --short-padding: 3px;
        --long-padding: 6px;

        font-size: 0.8rem;
        line-height: calc(0.8rem + 4px);
    `,
};

const tabBase = css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: var(--bold-if-primary);
`;

const tabVariants = {
    standard: (theme) => css`
        background-color: rgba(0, 0, 0, 0.1);
        border-color: rgba(0, 0, 0, 0.2);
        border-style: solid;
        border-width: 0;
        border-width: 1px;

        padding: calc(${theme.metrics.targetableClearance});
        padding-top: calc(${theme.metrics.targetableClearance} * 0.6);
        padding-bottom: calc(${theme.metrics.targetableClearance} * 0.6);
    `,
    primary: (theme) => css`
        background-color: ${theme.colors.primary};
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);

        padding: calc(${theme.metrics.targetableClearance});
        padding-top: calc(${theme.metrics.targetableClearance} * 0.8);
        padding-bottom: calc(${theme.metrics.targetableClearance} * 0.8);
    `,
};

const tabStripBase = css`
    display: flex;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
`;

const variantCss = {
    danger: () => css`
        --bold-if-primary: regular;

        background-color: rgb(240, 240, 240);
        border-color: rgb(200, 0, 0);
        color: rgb(200, 0, 0);
    `,
    primary: (theme) => css`
        --bold-if-primary: bold;

        background-color: ${theme.colors.primary};
        border-color: rgb(0, 0, 0);
    `,
    standard: css`
        --bold-if-primary: regular;

        background-color: unset;
        border-color: rgb(0, 0, 0, 0.5);
    `,
};

export function Button({
    children,
    className,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    return (
        <button
            {...others}
            className={className}
            css={[sizeCss[size], variantCss[variant], buttonBase]}
        >
            {children}
        </button>
    );
}

export function Card({ children, className }) {
    return (
        <Section className={className} css={[cardBase]}>
            {children}
        </Section>
    );
}

export function Checkbox({
    ariaLabel,
    checked,
    children,
    className,
    id,
    onChange,
}) {
    return (
        <label className={className} css={[checkboxBase]}>
            <input
                aria-label={ariaLabel}
                checked={checked}
                id={id}
                onChange={onChange}
                type='checkbox'
            />
            <div>{children}</div>
        </label>
    );
}

export function Chip({
    children,
    className,
    element = 'div',
    elementCss = css``,
    onRemove = () => {},
    removable = false,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    const Element = element;

    return (
        <div
            className={className}
            css={[variantCss[variant], sizeCss[size], chipBase]}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Element css={[chipContents, elementCss]} {...others}>
                    {children}
                </Element>
                <When condition={removable}>
                    <IconButton onClick={onRemove} size={size}>
                        <TiDelete />
                    </IconButton>
                </When>
            </div>
        </div>
    );
}

export const Div = react.forwardRef(function Div(props, ref) {
    return <div ref={ref} {...props} />;
});

export function Empty({ empty = 'empty' }) {
    return (
        <p
            css={css`
                font-style: oblique;

                &::after {
                    content: '>';
                }

                &::before {
                    content: '<';
                }
            `}
        >
            {empty}
        </p>
    );
}

export function FloatingButton({
    children,
    className,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    return (
        <button
            {...others}
            className={className}
            css={[variantCss[variant], floatingButtonBase, sizeCss[size]]}
        >
            {children}
        </button>
    );
}

export function HelpArticle({ children }) {
    return (
        <div
            css={css`
                & > p {
                    margin-bottom: 0.6rem;
                }
            `}
        >
            {children}
        </div>
    );
}

export const IconButton = forwardRef(function IconButton(
    {
        children,
        className,
        size = 'normal',
        variant = 'standard',
        onClick,
        ...others
    },
    ref,
) {
    return (
        <button
            ref={ref}
            {...others}
            className={className}
            onClick={onClick}
            css={[sizeCss[size], variantCss[variant], iconButtonBase]}
        >
            {children}
        </button>
    );
});

export const InvisibleButton = styled.button`
    background-color: unset;
    border: unset;
    padding: unset;
    margin: unset;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    text-align: inherit;
    color: unset;
`;

export function Overlayer({ over, under }) {
    return (
        <OverlayLayout>
            {under}
            <OverlayBackdrop />
            {over}
        </OverlayLayout>
    );
}

export function Select({
    children,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    return (
        <select
            {...others}
            css={[sizeCss[size], variantCss[variant], selectBase]}
        >
            {children}
        </select>
    );
}

export const Span = react.forwardRef(function Span(props, ref) {
    return <span ref={ref} {...props} />;
});

export function Tab({
    children,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    return (
        <div
            {...others}
            css={[
                sizeCss[size],
                variantCss[variant],
                tabBase,
                tabVariants[variant],
            ]}
        >
            {children}
        </div>
    );
}

export function TabStrip({
    children,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    return (
        <div
            {...others}
            css={[sizeCss[size], variantCss[variant], tabStripBase]}
        >
            {children}
        </div>
    );
}

export function Text({
    children,
    size = 'normal',
    variant = 'standard',
    ...others
}) {
    const El = others.element ?? react.useMemo(() => styled.p``, []);

    return (
        <El {...others} css={[sizeCss[size], variantCss[variant]]}>
            {children}
        </El>
    );
}

export function ValidationMessage({
    children: text,
    className,
    Component = Div,
}) {
    return (
        <Component
            className={className}
            css={(theme) => {
                const bg = Color(theme.colors.secondary).mix(
                    Color('white'),
                    0.9,
                );

                let fg = Color(theme.colors.secondary);
                while (bg.contrast(fg) < 5) {
                    fg = fg.mix(Color('black'), 0.1);
                }

                return css`
                    align-items: stretch;
                    background-color: ${bg.hex()};
                    border-color: ${fg.hex()};
                    border-radius: 5px;
                    border-style: solid;
                    border-width: 1px;
                    color: ${fg.hex()};
                    display: flex;
                    min-width: 0;
                    padding-left: 6px;
                    padding-right: 6px;
                `;
            }}
        >
            <AlertIco
                css={css`
                    font-size: 20px;
                    height: unset;
                    margin-right: 6px;
                    flex-grow: 0;
                    flex-shrink: 0;
                `}
            />
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    flex-grow: 1;
                    min-width: 0;
                `}
            >
                <p>{text}</p>
            </div>
        </Component>
    );
}
