import Ship from '../../../components/pages/Ship.jsx';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/ships/:shipId',
    Component: () => {
        const { shipId } = useParams();
        return <Ship shipId={shipId} />;
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: (params, model) => model.get(`/${params.shipId}`)?.name,
        upUrl: '/ships',
    },
};
