import Observable from '../../../../utils/Observable.mjs';
import react from 'react';
import * as shipUtil from '../../../../utils/utils.mjs';

import { buildUi } from './build-ui.jsx';
import {
    MyValidationMessage,
    TupleNodeSpacer,
    TupleNodeContainer,
} from './TupleNode.jsx';

export default function buildTupleNode(
    spec,
    value,
    expectedType,
    onChange,
    ctx,
) {
    value = [...value];

    const properties = {
        structured: true,
        elements: [],
    };

    const entries = [];
    const errorMessages = [];
    for (let i = 0; i < spec.$tuple.length; i++) {
        const [subspec, { expectedType: subExpectedType }] =
            shipUtil.effectiveSpec(ctx.context, expectedType, spec, i);

        const [c, p, d] = buildUi(
            subspec,
            value?.[i],
            subExpectedType,
            (v, opts) =>
                onChange([...value.slice(i), v, ...value.slice(i + 1)], opts),
            {
                ...ctx,

                displayContext: ctx.displayContext.child(i),
                displayHierarchy: [...ctx.displayHierarchy, 'TupleNode'],
                path: `${ctx.path}/${i}`,
                touched: shipUtil.removeDictLayer(ctx.touched, `${i}`),
                validationErrors: shipUtil.removeDictLayer(
                    ctx.validationErrors,
                    `${i}`,
                ),
            },
        );

        const fieldPointer = shipUtil.extendJsonPath(ctx.path, i);

        if (
            ctx.validationErrors[fieldPointer] &&
            ctx.touched.has(fieldPointer)
        ) {
            const error = ctx.validationErrors[fieldPointer];
            errorMessages.push(
                shipUtil.isNil(error.actual)
                    ? `${
                          p.label ?? spec.$tuple.$labels?.[i] ?? i
                      } must be provided.`
                    : `${p.label ?? spec.$tuple.$labels?.[i] ?? i} must be ${
                          error.expected
                      }.`,
            );
        }

        value[i] = d;

        properties.elements.push(p);

        if (p.committer) {
            if (!properties.committer) {
                properties.committer = new Observable();
            }

            p.committer.on('cancel', () => properties.committer.fire('cancel'));

            p.committer.on('commit', (v) => {
                properties.committer.fire('commit', [
                    ...value.slice(0, i),
                    v,
                    ...value.slice(i + 1),
                ]);
            });
        }

        entries.push(<react.Fragment key={i}>{c}</react.Fragment>);
    }

    return [
        // eslint-disable-next-line react/jsx-key
        <TupleNodeSpacer>
            <TupleNodeContainer>{entries}</TupleNodeContainer>
            {errorMessages.map((m, i) => (
                <MyValidationMessage key={i}>{m}</MyValidationMessage>
            ))}
        </TupleNodeSpacer>,
        properties,
        value,
    ];
}
