import { expression } from '../../../../specs.mjs';

export const advancedSearchSpec = {
    order: {
        $label: 'Sort Order',
        $expectedTypes: {
            order: 'number',
        },
        order: {
            $label: 'Ordering',
            $extend: expression,
        },
        direction: {
            $label: 'Direction',
            $descrim: {
                ascending: { $label: 'Ascending' },
                descending: { $label: 'Descending' },
            },
        },
    },
    filters: {
        $label: 'Filters',
        $array: {
            $expectedTypes: {
                elements: 'boolean',
            },
            elements: expression,
        },
    },
};

export const searchSpec = {
    ...advancedSearchSpec,

    text: { $string: { maxLength: 100 } },
};
