import react from 'react';
import useValue from './use-value.jsx';

import { useCallback, useMemo } from 'react';

export default function useStateMachine(inputs, initialState, def, ctx) {
    const [curState, setCurState] = react.useState(initialState);
    ([def] = react.useState(def));

    const inputsInstance = useValue(inputs);

    react.useEffect(() => {
        def[curState].onEnter?.(inputsInstance, ctx);
    }, [curState, def, ctx, inputsInstance]);

    react.useEffect(() => {
        def[curState].onChange?.(inputsInstance, ctx);
    }, [inputsInstance, def, curState, ctx]);

    const changeState = useCallback(
        (newState) => {
            newState =
                def[curState].onExit?.(newState, inputsInstance, ctx) ??
                newState;
            setCurState(newState);
        },
        [def, curState, inputsInstance, ctx, setCurState],
    );

    return useMemo(
        () => [def[curState].getValue?.(inputsInstance, ctx), changeState],
        [def, curState, inputsInstance, ctx, changeState],
    );
}
