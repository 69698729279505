import JobTile from '../fragments/JobTile.jsx';
import Link from '../fragments/Link.jsx';
import NotificationButton from '../fragments/NotificationButton.jsx';
import OptionalGroup from '../fragments/OptionalGroup.jsx';
import OrdersSummary from './utils/Ship/OrdersSummary.jsx';
import react from 'react';
import ResourceBoundSubsystem from '../fragments/ResourceBoundSubsystem.jsx';
import ShipConfiguration from '../fragments/ShipConfiguration.jsx';
import styled from '@emotion/styled';
import * as shipUtil from '../../utils/utils.mjs';
import Teaser from '../fragments/Teaser.jsx';
import useRegistration from '../../hooks/use-registration.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';

import { Empty } from '../fragments/standard.jsx';
import { css } from '@emotion/react';
import { GameStateContext } from '../../app-contexts.jsx';
import { LogEntry } from './Logs.jsx';
import { When } from 'react-if';
import {
    PropertyTable,
    Entry as PTEntry,
} from '../fragments/PropertyTable.jsx';
import {
    buildAnnotations,
    currentActionText,
    missingMessage,
} from './utils/Ship/ship.jsx';
import { useNavigateInitiator } from '../../hooks/task-initiators.jsx';

const ShipFlashBox = styled.div`
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
`;

export default function Ship(props) {
    const { shipId } = props;

    const { model } = react.useContext(GameStateContext);
    const shipData = shipUtil.unmessy(model.get(`/${shipId}`));

    const shipProps = shipUtil.synthesizeShipProps(shipData, model);
    const annotations = buildAnnotations(shipId, model);

    const urgentLogLines = filterUrgentLogLines(shipData?.log ?? []);

    const [jobAnnotations, setJobAnnotations] = react.useState([]);

    useRegistration('map', {
        annotations: [...annotations, ...jobAnnotations],
    });
    useRegistration('ui', { headline: currentActionText(shipData, model) });

    return useVolatileDataWrapper(
        props,
        shipData,
        missingMessage,
        ({ data: shipData }) => (
            <div>
                <QuickBar shipId={shipId} shipLog={shipData?.log ?? []} />
                <PropertyTable>
                    <ShipStatistic
                        statName={'cabinModularity'}
                        stats={shipProps}
                    />
                    <ShipStatistic
                        statName={'cargoThroughput'}
                        stats={shipProps}
                    />
                    <ShipStatistic statName={'mass'} stats={shipProps} />
                    <ShipStatistic
                        statName={'operatingRange'}
                        stats={shipProps}
                    />
                    <ShipStatistic statName={'speed'} stats={shipProps} />
                    <ShipStatistic statName={'thrust'} stats={shipProps} />
                </PropertyTable>
                <When condition={urgentLogLines.length > 0}>
                    <ShipFlashBox>
                        <LogEntry
                            entry={urgentLogLines[0]?.[1]}
                            path={`/${shipId}/log/${urgentLogLines[0]?.[0]}`}
                        />
                    </ShipFlashBox>
                </When>
                <Teaser
                    data={shipData?.orders}
                    linkTo={`/ships/${shipId}/orderQueue`}
                    title='Order Queue'
                >
                    <OrdersSummary shipData={shipData} shipId={shipId} />
                </Teaser>
                <OptionalGroup
                    title='Cargo and Passengers'
                    debugData={shipData?.storage}
                >
                    <ResourceBoundSubsystem
                        environment='stored'
                        groundResources={shipData?.groundShipResources}
                        stacksOrPath={shipData?.storage}
                        visibleProps={[
                            'unpressurizedStorage',
                            'pressurizedStorage',
                        ]}
                    />
                </OptionalGroup>
                <OptionalGroup title='Claimed Jobs' debugData={null}>
                    <JobList onAnnotate={setJobAnnotations} shipId={shipId} />
                </OptionalGroup>
                <OptionalGroup title='Loadout' debugData={shipData?.modules}>
                    <ShipConfiguration shipData={shipData} />
                </OptionalGroup>
            </div>
        ),
    );
}

function JobList({ onAnnotate, shipId }) {
    const { model } = react.useContext(GameStateContext);
    const ti = useNavigateInitiator();

    const jobs = model.getMatches({
        kind: 'job',
        assignee: { $ref: `/${shipId}` },
    });

    return (
        <react.Fragment>
            <When condition={jobs.length === 0}>
                <Empty key='empty' />
            </When>
            {jobs.map((jId) => (
                <JobTile
                    key={jId}
                    id={jId}
                    map={true}
                    onAnnotate={onAnnotate}
                    taskInitiator={ti}
                />
            ))}
        </react.Fragment>
    );
}

function ShipStatistic({ children, statName, stats }) {
    const value = children ?? stats[statName];

    return (
        <PTEntry label={shipUtil.humanSynthesizedPropName(statName)}>
            {shipUtil.humanQuantity(
                value,
                shipUtil.synthesizedPropUnits(statName),
                { missing: 'Unknown', round: true },
            )}
        </PTEntry>
    );
}

function QuickBar({ shipLog, shipId }) {
    const urgentLoglines = filterUrgentLogLines(shipLog);

    return (
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
                font-size: 1.5rem;
            `}
        >
            <Link to={`/ships/${shipId}/log`}>
                <NotificationButton count={urgentLoglines.length} />
            </Link>
        </div>
    );
}

function filterUrgentLogLines(shipLog = []) {
    return shipLog
        .map((e, i) => [i, e])
        .filter(([, { acknowledged, urgent }]) => urgent && !acknowledged);
}
