import EntitySelectorNode from './EntitySelectorNode.jsx';
import Observable from '../../../../utils/Observable.mjs';

// expectedType and ctx are unused here, but we use a standard interface for all
// buildXXX() methods, so...
export default function buildEntitySelectorNode(
    spec,
    value,
    // eslint-disable-next-line no-unused-vars
    expectedType,
    // eslint-disable-next-line no-unused-vars
    onChange,
    // eslint-disable-next-line no-unused-vars
    ctx,
) {
    const matcher =
        typeof spec.$ref === 'string' ? { kind: spec.$ref } : spec.$ref;

    const committer = new Observable();
    function onCommit(value) {
        committer.fire('commit', value);
    }

    return [
        // eslint-disable-next-line react/jsx-key
        <EntitySelectorNode {...{ onCommit, matcher, value }} />,
        { committer },
        value,
    ];
}
