import * as reactRouter from 'react-router-dom';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';
import useWhiteboard from '../../hooks/use-whiteboard.jsx';

import { Link as RrdLink } from 'react-router-dom';
import { wbToString } from '../../hooks/use-whiteboard.jsx';

const StyledLink = styled(RrdLink)`
    display: grid;
`;

export default function Link({
    children,
    className,
    onClick,
    preserveWbKeys,
    to,
    whiteboard,
}) {
    const location = reactRouter.useLocation();
    const [wbVars] = useWhiteboard(whiteboard);
    const preserved = {};

    for (const k of shipUtil.coerceToArray(preserveWbKeys)) {
        if (k in wbVars) {
            preserved[k] = wbVars[k];
        }
    }

    const preservedToString = wbToString(preserved);
    const qs = preservedToString ? `?${preservedToString}` : '';

    return (
        <StyledLink
            className={className}
            onClick={onClick}
            to={`${to}${qs}`}
            state={{
                back: location.pathname,
            }}
        >
            {children}
        </StyledLink>
    );
}
