import Mustache from 'mustache';
import react from 'react';
import * as shipUtil from '../utils/utils.mjs';
import walkSpec from '../shared/walk-spec.mjs';

import { GameStateContext } from '../app-contexts.jsx';
import { isZeroValue } from '../shared/model.mjs';

function indent(s) {
    return s
        ?.split('\n')
        ?.map((line) => `  ${line}`)
        ?.join('\n');
}

function defaultWalker(buildResult) {
    return {
        buildResult(s, v, ctx) {
            if (s?.$prettyPrint) {
                const result = s.$prettyPrint(v, {
                    model: ctx.model,
                    prettyPrint: ctx.prettyPrint,
                });
                if (typeof result !== 'string') {
                    throw new Error(
                        'Did not return string: ' +
                            shipUtil.debugString(result),
                    );
                }

                return result;
            }

            const result = buildResult(s, v, ctx);
            if (typeof result !== 'string') {
                throw new Error(
                    'Did not return string: ' + shipUtil.debugString(result),
                );
            }

            return result;
        },
    };
}

function handleMissing(spec, v, subwalkString, defaultString) {
    return v === undefined
        ? defaultString ?? (spec.$optional ? '<omitted>' : '<missing>')
        : v === null
          ? spec.$optional
              ? '<omitted>'
              : '<missing>'
          : subwalkString;
}

const walker = {
    $array: defaultWalker((s, v, { subwalk }) => {
        return handleMissing(
            s,
            v,
            '[\n' + indent(subwalk?.join(',\n')) + '\n]',
            '[]',
        );
    }),
    $boolean: defaultWalker((s, v) => {
        return handleMissing(s, v, `${!!v}`, 'false');
    }),
    $clickDuration: defaultWalker((s, v) => {
        return handleMissing(s, v, `${v} clicks`, '0 clicks');
    }),
    $descrim: defaultWalker((s, v, { model, prettyPrint, subwalk = {} }) => {
        const [type, value] = shipUtil.dnode(subwalk, '<not a dnode>');
        const sSubtype = s[type];

        if (sSubtype?.$prettyPrint) {
            const [, originalValue] = shipUtil.dnode(v);
            return s[type].$prettyPrint(originalValue, { model, prettyPrint });
        }

        if (sSubtype?.$humanize || sSubtype?.$shorthand) {
            if (typeof value !== 'string') {
                throw new Error('non string: ' + shipUtil.debugString(value));
            }

            return value;
        }

        const args =
            Object.keys(value ?? {}).length === 0
                ? ''
                : '(\n' + indent('' + value) + '\n)';

        return handleMissing(s, v, type + args);
    }),
    $int: defaultWalker((s, v) => {
        const result = handleMissing(s, v, `${v ?? 0}`, '0');
        if (typeof result !== 'string') {
            throw new Error('not string: ' + shipUtil.debugString(result));
        }

        return result;
    }),
    $ref: defaultWalker((s, v, { model }) => {
        return handleMissing(
            s,
            v,
            `"x ${shipUtil.debugString(v)} ${shipUtil.humanDescription(v, model)}"`,
        );
    }),
    $string: defaultWalker((s, v) => {
        return handleMissing(s, v, JSON.stringify(v), '""');
    }),
    $struct: defaultWalker((s, v, { model, prettyPrint, subwalk }) => {
        let result = '';

        if (s.$humanize && v) {
            // React is going to handle escaping for us, so this is just
            // going to wind up double-escaping.
            const template = s.$humanize.en.replace(
                /\{\{([^}]*)\}\}/g,
                '{{{$1}}}',
            );

            if (
                [...Object.values(subwalk ?? {})].some(
                    (v) => typeof v !== 'string',
                )
            ) {
                throw new Error(
                    'Contains non-string: ' + shipUtil.debugString(subwalk),
                );
            }

            result = Mustache.render(template, subwalk);
        } else if (s.$shorthand) {
            for (const c of s.$shorthand) {
                if (c?.placeholder) {
                    if (typeof subwalk[c.placeholder] !== 'string') {
                        throw new Error(
                            'Non string: ' +
                                shipUtil.debugString(subwalk[c.placeholder]),
                        );
                    }

                    result += subwalk[c.placeholder];
                } else if (c?.pivot) {
                    if (typeof c?.pivot !== 'string') {
                        throw new Error(
                            'Non string: ' +
                                shipUtil.debugString(subwalk[c?.pivot]),
                        );
                    }

                    result += c?.pivot;
                } else {
                    if (typeof c !== 'string') {
                        throw new Error(
                            'Non string: ' + shipUtil.debugString(c),
                        );
                    }

                    result += c;
                }
            }
        } else {
            for (let [k, v2] of Object.entries(subwalk)) {
                if (s[k].$prettyPrint) {
                    v2 = s[k].$prettyPrint(v?.[k], { model, prettyPrint });
                }

                if (typeof v2 !== 'string') {
                    throw new Error(
                        'Not a string: ' + shipUtil.debugString(v2),
                    );
                }

                if (s[k]?.$optional && isZeroValue(v?.[k])) {
                    continue;
                }

                if (result.length > 0) {
                    result += ',\n';
                }

                if (v?.includes?.('=') || v?.includes?.(',')) {
                    v2 = `(\n${indent(v2)}\n)`;
                }

                result += `${k}=${v2}`;
            }
        }

        result = handleMissing(s, v, result);

        if (typeof result !== 'string') {
            throw new Error(
                'Non-string result? ' + shipUtil.debugString(result),
            );
        }

        return result;
    }),
    $tuple: defaultWalker((s, v, { subwalk = [] }) => {
        if (subwalk.some((v) => typeof v !== 'string')) {
            throw new Error('Contains non-string: ' + shipUtil.debugString(v));
        }

        return `<${subwalk.join(', ')}>`;
    }),
};

export default function usePrettyPrint() {
    const { model } = react.useContext(GameStateContext);
    return (label, spec, data) =>
        prettyPrint(label, spec, data, model).unbounded;
}

export function buildPrettyPrint(model) {
    return (label, spec, data) =>
        prettyPrint(label, spec, data, model).unbounded;
}

function prettyPrint(label, spec, data, model) {
    function innerPrettyPrint(spec, value) {
        return prettyPrint('', spec, value, model);
    }

    const blah = walkSpec(walker, spec, data, {
        model,
        prettyPrint: innerPrettyPrint,
    });

    return { bounded: blah, unbounded: blah };
}
