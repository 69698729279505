import styled from '@emotion/styled';

export const Ui = styled.div`
    z-index: 0;
    position: relative;

    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow: hidden;

    color: ${(props) => props.theme.colors.text};
    --color: ${(props) => props.theme.colors.text};
`;
