import { createPortal } from 'react-dom';
import { useOutletContext } from 'react-router-dom';

export default function TitlePortal({ children }) {
    const { titleRef } = useOutletContext();

    if (!titleRef.current) {
        return;
    }

    return createPortal(children, titleRef.current);
}
