import * as shipUtil from './utils.mjs';

export default class DynamicCounter {
    constructor(initialKeys, defaultValue = 0) {
        return new Proxy(
            { ...initialKeys },
            {
                get(target, field) {
                    if (!(field in target)) {
                        return defaultValue;
                    }

                    return target[field];
                },

                set(target, field, value) {
                    if (typeof value !== 'number') {
                        throw new Error(
                            'must be a number: ' + shipUtil.debugString(value),
                        );
                    }

                    if (value === 0) {
                        delete target[field];
                    } else {
                        target[field] = value;
                    }

                    return true;
                },
            },
        );
    }
}
