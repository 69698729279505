import clone from 'clone';
import Model from '../shared/model.mjs';
import ms from 'ms';
import react from 'react';
import serverMessageHandler from '../utils/server-message-handler.mjs';
import * as shipUtil from '../utils/utils.mjs';
import useAccumulateCalls from './use-accumulate-calls.jsx';
import useLocalStorageState from 'use-local-storage-state';
import useWebsocket from 'react-use-websocket';

import { generateId, stripZeroValues } from '../shared/model.mjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useGameServer(backendUrl) {
    const inFlightRequests = react.useRef({});

    let sendToServer;

    const [click, setClick] = useState();
    const [lastClickAt, setLastClickAt] = useState();
    const [model, setModel] = useState(new Model({}));
    const [session, setSession] = useState();
    const [extras, setExtras] = useState();
    const [account, setAccount] = useLocalStorageState('aegisAccountInfoV1');
    const [optimisticMasks, setOptimisticMasks] = useState([]);

    window.aegisModel = model;
    window.aegisSession = session;

    const handleMessage = useCallback(
        (msg) => {
            if (msg.click) {
                setClick(msg.click);
            }

            if (msg.lastClickAt) {
                setLastClickAt(msg.lastClickAt);
            }

            if (msg.requestId) {
                if (inFlightRequests.current[msg.requestId]) {
                    const [timeoutHandle, resolve, reject] =
                        inFlightRequests.current[msg.requestId];

                    clearTimeout(timeoutHandle);

                    if (
                        msg.type === 'ClientError' ||
                        msg.type === 'ServerError'
                    ) {
                        const e = new Error(msg.message);
                        e.details = msg;
                        reject(e);
                    } else if (msg.type === 'Response') {
                        resolve(msg.payload);
                    } else {
                        const e = new Error('Weird response type: ' + msg.type);
                        Object.assign(e, msg);
                        reject(e);
                    }
                } else {
                    console.error('dropping response', msg);
                }
            } else {
                if (serverMessageHandler[msg.type]) {
                    serverMessageHandler[msg.type](msg, {
                        optimisticMasks,
                        sendToServer,
                        setClick,
                        setExtras,
                        setModel,
                        setOptimisticMasks,
                        setSession,
                    });
                } else {
                    console.error('unknown server message type', msg);
                }
            }

            setOptimisticMasks((masks) =>
                masks.filter(
                    ({ disabled, createdAt }) =>
                        !disabled && createdAt > Date.now() - ms('10s'),
                ),
            );
        },
        [
            inFlightRequests,
            optimisticMasks,
            sendToServer,
            setClick,
            setExtras,
            setModel,
            setOptimisticMasks,
            setSession,
        ],
    );

    const handleBatch = useCallback(
        (ms) => {
            for (const m of ms) {
                handleMessage(m);
            }
        },
        [handleMessage],
    );

    const queueMessage = useAccumulateCalls(
        500,
        [],
        (a, v) => [...a, v],
        handleBatch,
        {
            debounceOpts: {
                leading: true,
            },
        },
    );

    const createAnonymousAccount = useCallback(
        async function createAnonymousAccount() {
            const { id, secret } = await sendToServer({
                type: 'CreateAnonymousAccount',
            });

            setAccount({ id, secret });
            setSession({ accountId: id });
        },
        [sendToServer, setAccount],
    );

    const onWsOpen = useCallback(async () => {
        if (!account) {
            await createAnonymousAccount();
        } else {
            try {
                await sendToServer({
                    type: 'AuthenticateAnonymousAccount',
                    accountId: account.id,
                    secret: account.secret,
                });
                setSession({ accountId: account.id });
            } catch (e) {
                console.error(e);

                if (e.type === 'ClientError') {
                    await createAnonymousAccount();
                }
            }
        }
    }, [account, createAnonymousAccount, sendToServer]);

    const { sendJsonMessage: rawSendMessage } = useWebsocket(backendUrl, {
        onMessage(msgEnvelope) {
            try {
                queueMessage(JSON.parse(msgEnvelope.data));
            } catch (e) {
                console.error(e);
            }
        },
        retryOnError: true,
        shouldReconnect: () => true,
        onOpen: onWsOpen,
    });

    sendToServer = useCallback(
        (msg, optimisticMask = {}) => {
            console.log('send', clone(msg));

            msg.requestId = generateId('req');

            let [promise, resolve, reject] = shipUtil.promise();
            const maskEntry = {
                requestId: msg.requestId,
                createdAt: Date.now(),
            };

            if (msg.type === 'Edit') {
                const mCopy = model.copy();
                for (const edit of msg.edits) {
                    shipUtil.applyEdit(mCopy, edit);
                }

                maskEntry.mask = mCopy.dict();
            } else {
                maskEntry.mask = shipUtil.transformObject(optimisticMask, {
                    transform: (k, v) => [k, stripZeroValues(v)],
                });
            }

            setOptimisticMasks([...(optimisticMasks ?? []), maskEntry]);

            const oldReject = reject;
            const forwardError = new Error();

            reject = (r) => {
                maskEntry.disabled = true;

                forwardError.message = r.message;
                forwardError.cause = r;

                if (r.details) {
                    Object.assign(forwardError, r.details);
                }

                oldReject(forwardError);
            };

            const oldResolve = resolve;
            resolve = (r) => {
                maskEntry.disabled = true;
                oldResolve(r);
            };

            const timeoutHandle = setTimeout(() => {
                delete inFlightRequests.current[msg.requestId];
                reject(new Error('timeout'));
            }, ms('30s'));

            inFlightRequests.current[msg.requestId] = [
                timeoutHandle,
                resolve,
                reject,
            ];

            rawSendMessage(msg);

            return promise;
        },
        [
            inFlightRequests,
            model,
            optimisticMasks,
            rawSendMessage,
            setOptimisticMasks,
        ],
    );

    useEffect(() => {
        const intervalHandle = setInterval(async () => {
            const { click, lastClickAt } = await Promise.resolve(
                sendToServer?.({ type: 'Ping' }),
            );

            if (click) {
                setClick(click);
                setLastClickAt(lastClickAt);
            }
        }, ms('10s'));

        return () => clearInterval(intervalHandle);
    }, [sendToServer, setClick, setLastClickAt]);

    const terminal = useMemo(
        () => gameStateTerminal(model, sendToServer),
        [model, sendToServer],
    );

    const effectiveModel = useMemo(() => {
        const m = model.copy();

        for (const { mask } of optimisticMasks) {
            for (const [k, v] of Object.entries(mask)) {
                m.set(k, v);
            }
        }

        return m;
    }, [model, optimisticMasks]);

    const toWallTime = useMemo(() => {
        return (qClick) => {
            if (click === undefined || lastClickAt === undefined) {
                return null;
            }

            const clickDelta = qClick - click;
            const wallEpoch =
                lastClickAt +
                clickDelta * model.get('/config/clickSpeed', 1) * 1000;

            return new Date(wallEpoch);
        };
    }, [click, lastClickAt, model]);

    return useMemo(
        () => ({
            click,
            lastClickAt,
            model: effectiveModel,
            sendToServer,
            session,
            terminal,
            toWallTime,

            ...extras,
        }),
        [
            click,
            lastClickAt,
            effectiveModel,
            sendToServer,
            session,
            terminal,
            toWallTime,
            extras,
        ],
    );
}

function gameStateTerminal(model, send) {
    function doCmd(cmdObj) {
        const [cmd, arg] = shipUtil.dnode(cmdObj);
        switch (cmd) {
            case 'appendOrder': {
                const { ship, order } = arg;
                send({
                    type: 'Edit',
                    edits: [
                        {
                            op: 'set',
                            key: `/${ship}/orders/-`,
                            value: order,
                        },
                    ],
                });

                break;
            }
            case 'deleteOrder': {
                const { ship, order } = arg;
                send({
                    type: 'Edit',
                    edits: [
                        {
                            op: 'pop',
                            key: `/${ship}/orders`,
                            index: order,
                        },
                    ],
                });

                break;
            }
            default: {
                throw new Error('Unknown terminal command: ' + cmd);
            }
        }
    }

    return doCmd;
}
