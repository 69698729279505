export default class Observable {
    static concat(es, os) {
        const concatenated = new Observable();

        for (const o of os) {
            for (const eventName of es) {
                o.on(eventName, (event) => concatenated.fire(eventName, event));
            }
        }

        return concatenated;
    }

    constructor() {
        this.listeners = {};
    }

    _ensureEntry(eventName) {
        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }

        return this.listeners[eventName];
    }

    fire(eventName, e) {
        for (const l of this.listeners[eventName] ?? []) {
            l(e);
        }
    }

    on(eventName, l) {
        this._ensureEntry(eventName).push(l);
    }

    removeListener(eventName, l) {
        const listRef = this._ensureEntry(eventName).indexOf(l);
        const i = listRef.indexOf(l);

        if (i !== -1) {
            listRef.splice(i, 1);
        }

        if (listRef.length === 0) {
            delete this.listeners[eventName];
        }
    }
}
