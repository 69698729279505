import Link from './Link.jsx';
import OptionalGroup from './OptionalGroup.jsx';
import OverflowIndicator from './OverflowIndicator.jsx';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';

const TeaserContainer = styled(OptionalGroup)`
    height: 150px;
`;

export default function Teaser({
    children,
    data,
    linkTo = '#',
    noteVerb = 'Edit',
    overflowOverlay,
    preserveWbKeys,
    title = '',
    whiteboard,
}) {
    return (
        <Link
            preserveWbKeys={preserveWbKeys}
            to={linkTo}
            whiteboard={whiteboard}
        >
            <TeaserContainer
                title={title}
                note={
                    shipUtil.isTouchDevice()
                        ? `Tap to ${noteVerb}`
                        : `Click to ${noteVerb}`
                }
                debugValue={data}
            >
                <OverflowIndicator overlay={overflowOverlay}>
                    {children}
                </OverflowIndicator>
            </TeaserContainer>
        </Link>
    );
}
