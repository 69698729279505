import ResourceBoundSubsystem from './ResourceBoundSubsystem.jsx';

const hiddenProps = [
    'cabinModularity',
    'cargoThroughput',
    'mass',
    'operatingRange',
    'speed',
    'thrust',
];

export default function ShipConfiguration({ shipData }) {
    return (
        <ResourceBoundSubsystem
            empty='none'
            environment='installed'
            groundResources={shipData?.groundShipResources}
            hiddenProps={hiddenProps}
            stacksOrPath={shipData?.modules}
        />
    );
}
