import MarketOrder from '../../../components/pages/MarketOrder.jsx';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/stations/:stationId/marketOrders/:orderId',
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    Component: () => {
        const { stationId, orderId } = useParams();
        return <MarketOrder stationId={stationId} orderId={orderId} />;
    },
    handle: {
        title: (params, model) =>
            model.get(`/${params.stationId}`)?.name + ' Market Order',
        upUrl: (params) => `/stations/${params.stationId}/marketOrders`,
    },
};
