import styled from '@emotion/styled';

const PropTableContainer = styled.table`
    font-size: 0.8rem;
    line-height: 0.7rem;
    letter-spacing: -1px;

    & tbody {
        display: flex;
        flex-wrap: wrap;
        column-gap: 5px;
    }

    & tr {
        display: inline;
        align-items: center;
    }

    & td {
        padding-left: 5px;
    }
`;

export function PropertyTable({ children, className }) {
    return (
        <PropTableContainer className={className}>
            <tbody>{children}</tbody>
        </PropTableContainer>
    );
}

export function Entry({ children, label }) {
    return (
        <tr key={label}>
            <th scope='row'>{label}</th>
            <td>{children}</td>
        </tr>
    );
}
