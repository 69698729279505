import useRegistryRoot from '../../hooks/use-registry-root.jsx';

import { registryContext } from '../../hooks/use-registry-root.jsx';

export default function GameUiProvider({ children }) {
    const registry = useRegistryRoot();

    return (
        <registryContext.Provider value={registry}>
            {children}
        </registryContext.Provider>
    );
}
