import Job from '../../../components/pages/Job.jsx';
import * as shipUtil from '../../../utils/utils.mjs';

import { useParams, useRouteError } from 'react-router-dom';

export default {
    path: '/jobs/:jobId',
    Component: () => {
        const { jobId } = useParams();
        return <Job jobId={jobId} />;
    },
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    handle: {
        title: (params, model) =>
            shipUtil.humanJobDescription(params.jobId, model),
        upUrl: '/jobs',
    },
};
