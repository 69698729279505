import * as shipUtil from './utils.mjs';

import { buildDNode, debugString, dnode, isNil, set } from './utils.mjs';
import { isZeroValue } from './model.mjs';

export class ValidationError extends Error {
    constructor(map) {
        super('Validation error: ' + JSON.stringify(map));

        this.errors = map;
    }
}

export default function validate(spec, value, opts) {
    const errorList = [];
    const sanitized = accumulateValidationErrors(
        0,
        opts,
        spec,
        value,
        '',
        errorList,
    );

    if (errorList.length > 0) {
        const errorMap = {};
        for (const [path, desc, actual] of errorList) {
            errorMap[path] = {
                message: `expected ${desc}, found: ${debugString(actual)}`,
                path,
                expected: desc,
                actual,
                $error: true,
            };
        }

        throw new ValidationError(errorMap);
    }

    return sanitized;
}

function accumulateValidationErrors(
    depth,
    opts,
    spec,
    value,
    path = '',
    accum = [],
) {
    opts = { descend: true, ...opts };

    if (!opts.descend && depth > 0) {
        return value;
    }

    if (typeof spec !== 'object') {
        throw new Error('Bad spec: ' + debugString(spec) + ' ' + path);
    }

    const nonAnnotationCount = Object.keys(spec).filter(
        (k) => !k.startsWith('$'),
    ).length;

    spec = shipUtil.extendSpec(spec);

    if (spec.$optional && value === undefined) {
        return;
    }

    let result;

    if (spec.$array) {
        value = nullOrDefault(value, []);

        if (Array.isArray(value)) {
            if (
                '$minLength' in spec.$array &&
                value.length < spec.$array.$minLength
            ) {
                accum.push([
                    path,
                    'an array with length no less than ' +
                        spec.$array.$minLength,
                    value,
                ]);
            } else if (
                '$maxLength' in spec.$array &&
                value.length > spec.$array.$maxLength
            ) {
                accum.push([
                    path,
                    'an array with length no more than ' +
                        spec.$array.$maxLength,
                    value,
                ]);
            } else {
                result = value.map((el, i) =>
                    accumulateValidationErrors(
                        depth + 1,
                        opts,
                        spec.$array.elements,
                        el,
                        `${path}/${i}`,
                        accum,
                    ),
                );
            }
        } else {
            accum.push([path, 'an array', value]);
        }
    }

    if (spec.$boolean) {
        value = nullOrDefault(value, false);

        if (typeof value === 'boolean') {
            result = value;
        } else {
            accum.push([path, 'a boolean', value]);
        }
    }

    if (spec.$clickDuration) {
        value = nullOrDefault(value, 0);

        if (Number.isInteger(value)) {
            if (Array.isArray(spec.$clickDuration)) {
                const [min, max] = spec.$clickDuration;

                if (value < min) {
                    accum.push([
                        path,
                        'an integer number of clicks no less than ' + min,
                        value,
                    ]);
                } else if (value > max) {
                    accum.push([
                        path,
                        'an integer number of clicks no more than ' + max,
                        value,
                    ]);
                } else {
                    result = value;
                }
            } else {
                result = value;
            }
        } else {
            accum.push([path, 'an integer number of clicks', value]);
        }
    }

    if (spec.$descrim) {
        const [dtype, dvalue] = dnode(value, null);

        if (spec.$descrim[dtype]) {
            // We don't increment `depth` here. We are still only logically
            // validating this level.
            result = buildDNode(
                dtype,
                accumulateValidationErrors(
                    depth,
                    opts,
                    spec.$descrim[dtype],
                    dvalue,
                    path,
                    accum,
                ),
            );
        } else {
            accum.push([path, describeDiscrimOptions(spec), value]);
        }
    }

    if (spec.$int) {
        value = nullOrDefault(value, 0);

        if (Number.isInteger(value)) {
            if (Array.isArray(spec.$int)) {
                const [min, max] = spec.$int;

                if (value < min) {
                    accum.push([path, 'an integer no less than ' + min, value]);
                } else if (value > max) {
                    accum.push([path, 'an integer no more than ' + max, value]);
                } else {
                    result = value;
                }
            } else {
                result = value;
            }
        } else {
            accum.push([path, 'an integer', value]);
        }
    }

    if (spec.$ref) {
        if (
            typeof value !== 'object' ||
            value === null ||
            Object.keys(value).length !== 1 ||
            typeof value.$ref !== 'string'
        ) {
            accum.push([path, 'a $ref', value]);
        } else {
            result = value;
        }
    }

    if (spec.$string) {
        value = nullOrDefault(value, '');

        if (typeof value !== 'string') {
            accum.push([path, 'text', value]);
        } else if (
            'minLength' in spec.$string &&
            value.length < spec.$string.minLength
        ) {
            accum.push([
                path,
                'text with length no less than ' + spec.$string.minLength,
                value,
            ]);
        } else if (
            'maxLength' in spec.$string &&
            value.length > spec.$string.maxLength
        ) {
            accum.push([
                path,
                'text with length no more than ' + spec.$string.maxLength,
                value,
            ]);
        } else {
            result = value;
        }
    }

    if (spec.$tuple) {
        value = nullOrDefault(value, []);

        if (Array.isArray(value) && value.length === spec.$tuple.length) {
            result = value.map((el, i) =>
                accumulateValidationErrors(
                    depth + 1,
                    opts,
                    spec.$tuple[i],
                    value[i],
                    `${path}/${i}`,
                    accum,
                ),
            );
        } else {
            accum.push([
                path,
                `a tuple of exactly ${spec.$tuple.length} elements`,
                value,
            ]);
        }
    }

    for (const [k, v] of Object.entries(spec).filter(
        ([k]) => !k.startsWith('$'),
    )) {
        if (result === undefined) {
            result = {};
        }

        if (typeof result !== 'object' || result === null) {
            throw new Error();
        }

        result[k] = accumulateValidationErrors(
            depth + 1,
            opts,
            v,
            value?.[k],
            `${path}/${k}`,
            accum,
        );
    }

    if (
        opts.forbidUnexpected &&
        !Array.isArray(value) &&
        typeof value === 'object' &&
        value !== null &&
        !spec.$ref &&
        !spec.$descrim
    ) {
        const f = spec.$descrim ? (k) => k !== '$d' : () => true;

        for (const k of Object.keys(value).filter(f)) {
            if (k.startsWith('$') || !spec[k]) {
                accum.push([path, `an object with no field '${k}'`, value]);
            }
        }
    }

    return result ?? value;
}

function nullOrDefault(v, d) {
    return v === null ? null : isZeroValue(v) ? d : v;
}

function describeDiscrimOptions(spec) {
    const keys = Object.keys(spec.$descrim).filter((k) => !k.startsWith('$'));

    if (keys.length === 1) {
        return `a "${keys[0]}" discriminator node`;
    }

    if (keys.length === 2) {
        return `a discriminator node of type "${keys[0]}" or "${keys[1]}`;
    }

    let result = '';
    for (const key of keys.slice(0, keys.length - 1)) {
        if (result !== '') {
            result += ', ';
        }

        result += `"${key}"`;
    }

    result += `, or "${keys[keys.length - 1]}"`;

    return `a discriminator node of type ${result}`;
}
