import ListItem from '../fragments/ListItem.jsx';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { Chip, FloatingButton } from '../fragments/standard.jsx';
import { GameStateContext } from '../../app-contexts.jsx';
import { Else, If, Then, When } from 'react-if';
import { LiaMapMarkedSolid } from 'react-icons/lia';
import { BiCoinStack } from 'react-icons/bi';
import { RiPinDistanceLine } from 'react-icons/ri';
import { FiNavigation } from 'react-icons/fi';
import {
    BsFillBoxFill,
    BsInfoCircle,
    BsSortDown,
    BsSortDownAlt,
} from 'react-icons/bs';

const chips = css`
    background-color: #ddd;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 0;
`;

const JobTileButtonPanel = styled.div`
    display: flex;
    gap: 5px;
`;

const JobTilePropertiesPanel = styled.div`
    display: flex;
    gap: 3px;

    overflow-x: scroll;
    overflow-y: hidden;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const JobTile = react.memo(function JobTile({
    id,
    mainAction,
    map,
    score,
    onAnnotate,
    sortAscending,
    taskInitiator,
}) {
    const { model } = react.useContext(GameStateContext);
    const [annotationsOn, setAnnotationsOn] = react.useState(false);

    const jobDetails = model.get(`/${id}`);

    if (!jobDetails) {
        return;
    }

    const fromDetails = model.get(jobDetails.from);
    const toDetails = model.get(jobDetails.to);

    if (!fromDetails || !toDetails) {
        return null;
    }

    const dX = fromDetails.x - toDetails.x;
    const dY = fromDetails.y - toDetails.y;
    const dist = Math.sqrt(dX * dX + dY * dY);

    function showInfo(jId) {
        showMap(jId);
        taskInitiator.jobDetail(jId);
    }

    function showMap() {
        if (annotationsOn) {
            onAnnotate?.([]);
        } else {
            onAnnotate?.([
                {
                    start: jobDetails.from,
                    end: jobDetails.to,
                    style: 'arrow',
                    parameters: {
                        color: 'rgb(0, 98, 255)',
                    },
                },
                {
                    location: jobDetails.from,
                    label: fromDetails.name,
                },
                {
                    location: jobDetails.to,
                    label: toDetails.name,
                },
            ]);
        }

        setAnnotationsOn(!annotationsOn);
    }

    const cargoAmt = model.get(`/${id}/cargo/0/1`);
    const reward = shipUtil.jobReward(jobDetails, model);

    return (
        <ListItem
            onClick={() => mainAction(id)}
            key={id}
            title={shipUtil.humanJobDescription(id, model)}
            info={[
                <JobTilePropertiesPanel key='only'>
                    <When condition={score !== undefined}>
                        <Chip
                            size='compact'
                            css={css`
                                background-color: rgb(235, 255, 255);
                                border-color: blue;
                                color: blue;
                            `}
                        >
                            <If condition={sortAscending}>
                                <Then>
                                    <BsSortDownAlt />
                                </Then>
                                <Else>
                                    <BsSortDown />
                                </Else>
                            </If>
                            {` ${Math.round(score)}`}
                        </Chip>
                    </When>
                    <Chip css={chips} size='compact'>
                        <BsFillBoxFill />
                        {` ${cargoAmt}`}
                    </Chip>
                    <Chip css={chips} size='compact'>
                        <RiPinDistanceLine />
                        {shipUtil.humanQuantity(dist, 'Mm', { round: true })}
                    </Chip>
                    <Chip css={chips} size='compact'>
                        <BiCoinStack />
                        {Intl.NumberFormat().format(reward)}
                    </Chip>
                </JobTilePropertiesPanel>,
            ]}
            icon={<FiNavigation />}
        >
            <JobTileButtonPanel>
                <FloatingButton onClick={() => showInfo(id)}>
                    <BsInfoCircle />
                </FloatingButton>
                <When condition={map}>
                    <FloatingButton onClick={() => showMap()}>
                        <LiaMapMarkedSolid />
                    </FloatingButton>
                </When>
            </JobTileButtonPanel>
        </ListItem>
    );
});

export default JobTile;
