import LoadingOverlay from '../components/fragments/LoadingOverlay.jsx';
import MissingOverlay from './utils/use-volatile-data-wrapper/MissingOverlay.jsx';
import ms from 'ms';
import react from 'react';

import { Else, If, Then } from 'react-if';
import { Overlayer } from '../components/fragments/standard.jsx';

export default function useVolatileDataWrapper(
    props = {},
    data,
    missing,
    Component,
) {
    Component = react.useMemo(() => Component, []);

    const lastData = react.useRef(data);
    lastData.current = data ?? lastData.current;

    const displayState = react.useRef('needTimeout');

    if (displayState.current === 'needTimeout') {
        setTimeout(() => {
            displayState.current = 'shouldBeLoaded';
        }, ms('3s'));
        displayState.current = 'initialLoad';
    }

    const component = <Component {...props} data={data ?? lastData.current} />;

    return data ? (
        component
    ) : (
        <If
            condition={['needTimeout', 'initialLoad'].includes(
                displayState.current,
            )}
        >
            <Then>
                <LoadingOverlay under={component} />
            </Then>
            <Else>
                <Overlayer
                    over={<MissingOverlay>{missing}</MissingOverlay>}
                    under={component}
                />
            </Else>
        </If>
    );
}
