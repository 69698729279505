import App from './App.jsx';
import react from 'react';
import ReactDOM from 'react-dom/client';
import * as reactRouter from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { captureConsoleIntegration } from '@sentry/integrations';

import './index.css';

// Note there is further Sentry integration in package.json
if (import.meta.env.PROD) {
    Sentry.init({
        dsn: 'https://94c6019ddf8ce7f580c289b12eb8ef58@o4506990512963584.ingest.us.sentry.io/4506990515060736',
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: react.useEffect,
                useLocation: reactRouter.useLocation,
                useNavigationType: reactRouter.useNavigationType,
                createRoutesFromChildren: reactRouter.createRoutesFromChildren,
                matchRoutes: reactRouter.matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            captureConsoleIntegration({
                levels: ['error'],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <react.StrictMode>
        <App
            createBrowserRouter={Sentry.wrapCreateBrowserRouter(
                reactRouter.createBrowserRouter
            )}
        />
    </react.StrictMode>
);
