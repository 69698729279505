import Ships from '../../../../components/pages/Ships.jsx';

import { useRouteError, useSearchParams } from 'react-router-dom';
import { Fragment } from 'react';

export default {
    path: '/ships',
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    Component: () => {
        const [params] = useSearchParams();
        return (
            <Fragment>
                <Ships corpId={JSON.parse(params.get('corp') ?? 'null')} />
            </Fragment>
        );
    },
    handle: {
        //title: () => 'Ships',
    },
};
