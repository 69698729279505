import styled from '@emotion/styled';

import { forwardRef } from 'react';

const CenterLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

const CenterLayoutChildWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CenterLayout = forwardRef(function CenterLayout(
    { className, children },
    ref,
) {
    return (
        <CenterLayoutContainer className={className} ref={ref}>
            <CenterLayoutChildWrapper>{children}</CenterLayoutChildWrapper>
        </CenterLayoutContainer>
    );
});

export default CenterLayout;
