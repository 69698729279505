import styled from '@emotion/styled';

import { css } from '@emotion/react';

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: min-content minmax(0, 1fr) min-content;

    min-height: 0;
    min-width: 0;

    overflow: hidden;
`;

export default function VerticalMainLayout({
    className,
    header,
    footer,
    children,
}) {
    return (
        <Wrapper className={className}>
            {header ?? <div />}
            <div
                css={css`
                    display: grid;
                    overflow: scroll;
                `}
            >
                {children}
            </div>
            {footer ?? <div />}
        </Wrapper>
    );
}
