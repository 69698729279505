import styled from '@emotion/styled';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const FlexWidth = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;

    display: flex;
    & > * {
        flex-grow: 1;
        align-items: stretch;
    }
`;

export default function HorizontalMainLayout({
    className,
    left,
    right,
    children,
}) {
    return (
        <Wrapper className={className}>
            {left}
            <FlexWidth>{children}</FlexWidth>
            {right}
        </Wrapper>
    );
}
