import { useEffect, useMemo, useState } from 'react';

function defaultPostProcess(x) {
    return x;
}

export default function useBody(url, postProcess = defaultPostProcess) {
    const [body, setBody] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(url);
                const text = await response.text();
                setBody(postProcess(text));
            } catch (e) {
                console.error(e);
                setError(e);
            }
        })();
    }, [postProcess, setBody, setError, url]);

    return useMemo(() => [body, error], [body, error]);
}
