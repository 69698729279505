import react from 'react';
import Section, { Headline as BaseHeadline } from './Section.jsx';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { ViewportList } from 'react-viewport-list';
import { When } from 'react-if';

const Card = styled(Section)`
    background-color: white;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    min-height: calc(3rem + 6px);
    line-height: 1rem;
    padding: 3px;
    border-radius: 5px;

    display: grid;
    grid-template-columns: min-content minmax(0, 1fr) min-content;
`;

const IconDiv = styled.div`
    display: grid;

    min-width: 3rem;
    min-height: 3rem;
    margin-right: 7px;

    & > * {
        grid-area: 1 / 1;
        min-width: 100%;
        min-height: 100%;
    }
`;

const Headline = styled(BaseHeadline)`
    font-size: 0.85rem;
    font-weight: 600;
    overflow: hidden;
`;

const OthersDiv = styled.div`
    display: flex;
`;

const MiddleDiv = styled.div`
    display: grid;
    grid-template-rows: 2.1rem minmax(0, 1fr);
`;

const InfoDiv = styled.div`
    align-self: stretch;

    display: flex;
    align-items: flex-end;
    gap: 3px;
    overflow: hidden;
`;

const InvisibleButton = styled.button`
    background-color: unset;
    border: unset;
    padding: unset;
    margin: unset;
    font-size: unset;
    font-weight: unset;
    font-family: unset;
    text-align: unset;
    overflow: hidden;
`;

const LIListItemContainer = styled.div`
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
`;

const liListCss = css`
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 7px;
    row-gap: 3px;
`;

export function LIList({ children, className }) {
    return (
        <ul className={className} css={liListCss}>
            {children}
        </ul>
    );
}

export function LIViewportList({ className, children, renderChild }) {
    const scrollRef = react.useRef();

    return (
        <div
            className={className}
            css={css`
                padding-top: 7px;
                overflow-y: scroll;
                overflow-x: hidden;
            `}
            ref={scrollRef}
        >
            <ViewportList viewportRef={scrollRef} items={children}>
                {(item, index, ...others) => (
                    <LIListItemContainer key={index}>
                        {renderChild(item, index, ...others)}
                    </LIListItemContainer>
                )}
            </ViewportList>
        </div>
    );
}

export default function ListItem({
    children,
    disableIconClickableArea,
    icon,
    info,
    middleDivWrapper = (x) => x,
    onClick,
    title,
}) {
    let iconComponent = (
        <When condition={icon}>
            <IconDiv>{icon}</IconDiv>
        </When>
    );

    if (!disableIconClickableArea && onClick) {
        iconComponent = (
            <InvisibleButton onClick={onClick}>{iconComponent}</InvisibleButton>
        );
    }

    let headlineComponent = <Headline>{title}</Headline>;

    if (onClick) {
        headlineComponent = (
            <InvisibleButton onClick={onClick}>
                {headlineComponent}
            </InvisibleButton>
        );
    }

    return (
        <Card>
            {iconComponent}
            {middleDivWrapper(
                <MiddleDiv>
                    {headlineComponent}
                    <InfoDiv>{info}</InfoDiv>
                </MiddleDiv>
            )}
            <OthersDiv>{children}</OthersDiv>
        </Card>
    );
}
