'use client';

import icons from '../../../../utils/icons.jsx';
import OverflowIndicator from '../../../fragments/OverflowIndicator.jsx';
import react from 'react';
import ShipErrorBoundary from '../../../fragments/ShipErrorBoundary.jsx';
import * as shipUtil from '../../../../utils/utils.mjs';
import * as specs from '../../../../specs.mjs';
import styled from '@emotion/styled';

import { dnode } from '../../../../utils/utils.mjs';
import { GameStateContext } from '../../../../app-contexts.jsx';
import { Else, If, Then } from 'react-if';

const OrdersSummaryIdleMessage = styled.p`
    font-style: italic;
    &::before {
        content: '<';
    }
    &::after {
        content: '>';
    }
`;

const OrdersSummaryList = styled.ol`
    margin-left: 20px;
`;

const OrdersSummaryTileContainer = styled.div`
    display: flex;
    align-items: center;
`;

const OrdersSummaryTileText = styled.p`
    padding-left: 5px;
`;

export default function OrdersSummary({ className = '', shipData = {} }) {
    const { orders = [] } = shipData;

    if (!Array.isArray(orders)) {
        console.log('Not an array?', orders);
    }

    return (
        <OverflowIndicator className={className}>
            <If condition={orders.length === 0}>
                <Then>
                    <OrdersSummaryIdleMessage>Idle</OrdersSummaryIdleMessage>
                </Then>
                <Else>
                    <OrdersSummaryList>
                        {orders.map((o, n) => (
                            <li key={n}>
                                <ShipErrorBoundary json={o}>
                                    <OrderSummaryTile orderData={o} />
                                </ShipErrorBoundary>
                            </li>
                        ))}
                    </OrdersSummaryList>
                </Else>
            </If>
        </OverflowIndicator>
    );
}

function OrderSummaryTile({ orderData }) {
    const [type] = dnode(orderData);

    const Icon =
        icons[specs.shipOrder.$descrim[type]?.$icon ?? 'icons.jsx/unknown'];
    return (
        <OrdersSummaryTileContainer>
            <Icon />
            <OrderSummaryText orderData={orderData} />
        </OrdersSummaryTileContainer>
    );
}

function OrderSummaryText({ orderData }) {
    const { model } = react.useContext(GameStateContext);
    return (
        <OrdersSummaryTileText>
            {shipUtil.humanOrder(orderData, specs.shipOrder, model)}
        </OrdersSummaryTileText>
    );
}
