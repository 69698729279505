// To prevent a confusing and arbitrarily-nested UI, some Usernode inputs spawn
// a separate Usernode input dialog to reset UI nesting. When this happens, we
// often don't actually want the resulting spawned dialog to be rooted in the
// input itself. For example, if ArraySummaryNode spawned a dialog rooted in the
// array field itself, the resulting dialog would just be a blank list with no
// label--we've lost a lot of informative labeling context about what the array
// represents. A display context maintains a "display root" that should be used
// to display that useful labeling context.

import clone from 'clone';
import jsonPointer from 'json-pointer';

import * as shipUtil from '../../../../utils/utils.mjs';

const copyFields = ['$label', '$shorthand'];
const justAnnotations = { filter: (k) => copyFields.includes(k) };

export default class DisplayContext {
    constructor(spec) {
        this.root = spec;
        this.path = '';
    }

    child(pathElement) {
        if (
            typeof pathElement !== 'string' &&
            typeof pathElement !== 'number'
        ) {
            throw new Error(
                'pathElement must be string or number, got: ' +
                    shipUtil.debugString(pathElement),
            );
        }

        const result = new DisplayContext(this.root);
        result.path = `${this.path}/${pathElement}`;
        return result;
    }

    // Returns a [<spec>, <data>] array that should be used instead of the given
    // actualRootData.
    buildDisplayRoot(actualRootData) {
        if (this.path === '') {
            return [this.root, actualRootData];
        }

        const parsedPath = jsonPointer.parse(this.path);
        const effectiveSpec = shipUtil.transformObject(
            this.root,
            justAnnotations,
        );
        const effectiveData = {};

        let curOriginalSpecNode = this.root;
        let curSpecNode = effectiveSpec;
        let curDataNode = effectiveData;

        const levels = [];
        while (parsedPath.length > 1) {
            const nextLevel = parsedPath.shift();
            levels.push(nextLevel);

            curOriginalSpecNode = getNextLevel(curOriginalSpecNode, nextLevel);

            curSpecNode[nextLevel] = shipUtil.transformObject(
                curOriginalSpecNode,
                justAnnotations,
            );
            curDataNode[nextLevel] = /^\d+$/.test(parsedPath[0]) ? [] : {};

            curSpecNode = curSpecNode[nextLevel];
            curDataNode = curDataNode[nextLevel];
        }

        const lastLevel = parsedPath.shift();
        levels.push(lastLevel);

        curSpecNode[lastLevel] = getNextLevel(curOriginalSpecNode, lastLevel);
        curDataNode[lastLevel] = actualRootData;

        return [effectiveSpec, effectiveData, levels];
    }

    // Returns the actual piece of embedded data intended from the result of
    // querying for spec+data as previously returned from buildDisplayRoot().
    recoverIntendedData(displayData) {
        return shipUtil.get(displayData, this.path);
    }
}

function getNextLevel(spec, field) {
    while (true) {
        spec = shipUtil.extendSpec(spec);

        if (spec.$descrim) {
            return spec.$descrim[field];
        } else if (spec.$array) {
            return spec.$array.elements;
        } else {
            return spec[field];
        }
    }
}
