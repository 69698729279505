import * as shipUtil from '../utils/utils.mjs';

import { registryContext } from './use-registry-root.jsx';
import { useContext } from 'react';

export default function useRegistration(rName, data) {
    if (typeof rName !== 'string') {
        throw new Error(
            'Registry name must be a string: ' + shipUtil.debugString(rName),
        );
    }

    const { useRegistration: useInnerReg } = useContext(registryContext);
    useInnerReg(rName, data);
}
