import CredSym from '../fragments/CredSym.jsx';
import Link from '../fragments/Link.jsx';
import ListItem from '../fragments/ListItem.jsx';
import ModuleTile from '../fragments/ModuleTile.jsx';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';
import useWhiteboard from '../../hooks/use-whiteboard.jsx';

import { Chip, ValidationMessage } from '../fragments/standard.jsx';
import { css } from '@emotion/react';
import { GameStateContext } from '../../app-contexts.jsx';
import { LIList } from '../fragments/ListItem.jsx';

export default function Market(props) {
    const { stationId } = props;
    const { model } = react.useContext(GameStateContext);
    const stationData = model.get(`/${stationId}`);

    return useVolatileDataWrapper(
        props,
        stationData?.market,
        <p>This station no longer exists.</p>,
        ({ data: market }) => (
            <LIList>
                {market?.map(({ id, value: subdata }) => (
                    <li key={id}>
                        <Entry
                            stationId={stationId}
                            orderId={id}
                            whiteboard={props.whiteboard}
                            data={subdata}
                        />
                    </li>
                ))}
            </LIList>
        )
    );
}

function Entry({ orderId, stationId, whiteboard, data = {} } = {}) {
    const { quantity, item, price } = data;
    const { model } = react.useContext(GameStateContext);
    const [wbVars] = useWhiteboard(whiteboard);

    if (quantity !== 1) {
        throw new Error(shipUtil.debugString(data));
    }

    const headline = (
        <react.Fragment>
            <Chip
                css={css`
                    font-weight: 600;
                    margin-right: 4px;
                `}
                variant='primary'
                size='compact'
            >
                for-sale
            </Chip>
            <span>{`${quantity} ${model.get(item)?.name}, `}</span>
            <CredSym>{price}</CredSym>
        </react.Fragment>
    );

    const blockReason = wbVars.corp
        ? shipUtil.purchaseBlocker(model, stationId, wbVars.corp, orderId)
        : undefined;

    const blockerDisplay = blockReason && (
        <ValidationMessage>{blockReason}</ValidationMessage>
    );

    return (
        <ListItem
            title={headline}
            icon={<ModuleTile data={model.get(item)} />}
            info={blockerDisplay}
            disableIconClickableArea={true}
            middleDivWrapper={(x) => (
                <Link
                    to={`/stations/${stationId}/marketOrders/${orderId}`}
                    whiteboard={whiteboard}
                    preserveWbKeys='corp'
                >
                    {x}
                </Link>
            )}
        />
    );
}
