import MapDisplay from '../../../components/fragments/MapDisplay.jsx';
import styled from '@emotion/styled';

export const MainContent = styled.div`
    z-index: 1;
    position: relative;

    display: grid;
    grid-template-rows: minmax(0, 1fr);

    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
    overflow: scroll;
`;

export const SizedMapDisplay = styled(MapDisplay)`
    z-index: 0;
    position: relative;

    max-height: 30vh;
    min-height: 30vh;

    background-color: rgb(0, 0, 0, 0.33);
`;
