import styled from '@emotion/styled';

import { BsCardList } from 'react-icons/bs';
import { css } from '@emotion/react';
import { IconButton } from './standard.jsx';
import { When } from 'react-if';

const inactiveCss = (theme) => css`
    color: ${theme.colors.disabledText};
`;

const Badge = styled.span`
    position: absolute;
    bottom: -0.2rem;
    background-color: ${(props) => props.theme.colors.primary};
    font-size: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 4px;
    font-weight: 600;
`;

const MyIconButton = styled(IconButton)`
    position: relative;
`;

export default function NotificationButton({
    children = <BsCardList />,
    count = 0,
}) {
    return (
        <MyIconButton size='normal' css={count === 0 ? inactiveCss : []}>
            {children}
            <When condition={count !== 0}>
                <Badge>{count > 100 ? '99+' : count}</Badge>
            </When>
        </MyIconButton>
    );
}
