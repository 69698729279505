import CenterLayout from '../CenterLayout.jsx';
import LinesEllipsis from 'react-lines-ellipsis';
import Link from '../Link.jsx';
import react from 'react';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosMenu } from 'react-icons/io';
import { FloatingButton } from '../standard.jsx';
import { Else, If, Then, When } from 'react-if';
import { GameStateContext } from '../../../app-contexts.jsx';
import { useMatches, useParams } from 'react-router-dom';

import './index.css';

const ButtonSlot = styled(CenterLayout)`
    width: 3rem;
    height: 3rem;
`;

const Rows = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    button {
        margin: 5px;
        box-shadow: unset;
    }
`;

const TitleRow = styled.div`
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.25);

    height: 3rem;

    background-color: ${(props) => props.theme.colors.primary};
`;

export default function CardNavigator({
    onMenuClick = () => {},
    titleRef,
    topRightRef,
}) {
    const { model } = react.useContext(GameStateContext);

    const matches = useMatches();
    const params = useParams();

    const title =
        matches[matches.length - 1].handle?.title?.(params, model) ?? null;
    //location.pathname.split('/').pop();

    const upUrl = (() => {
        let result = matches[matches.length - 1].handle?.upUrl;

        if (typeof result === 'function') {
            result = result(params);
        }

        return result;
    })();

    return (
        <Rows>
            <TitleRow>
                <ButtonSlot>
                    <If condition={upUrl}>
                        <Then>
                            <Link to={upUrl}>
                                <FloatingButton
                                    css={css`
                                        margin: 5px;
                                    `}
                                    variant='primary'
                                >
                                    <IoIosArrowBack />
                                </FloatingButton>
                            </Link>
                        </Then>
                        <Else>
                            <FloatingButton onClick={onMenuClick}>
                                <IoIosMenu />
                            </FloatingButton>
                        </Else>
                    </If>
                </ButtonSlot>
                <CenterLayout ref={titleRef}>
                    <When condition={title}>
                        <LinesEllipsis
                            css={css`
                                font-size: 1.2rem;
                                line-height: 1.2rem;
                            `}
                            text={title}
                            maxLine={2}
                            component='h1'
                        />
                    </When>
                </CenterLayout>
                <ButtonSlot>
                    <div ref={topRightRef} />
                </ButtonSlot>
            </TitleRow>
        </Rows>
    );
}
