import react from 'react';
import styled from '@emotion/styled';

import { GoPlus } from 'react-icons/go';
import { ValidationMessage } from '../../standard.jsx';
import { When } from 'react-if';
import { FaTrashAlt } from 'react-icons/fa';

const AddButton = styled.button`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-style: none;
    border-width: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 3px 3px 15px rgb(0, 0, 0, 0.25);
    & > svg {
        width: 30px;
        height: 30px;
    }
    pointer-events: auto;
`;

const ArrayItem = styled.div`
    position: relative;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(0, 0, 0);
    box-shadow: 3px 3px 5px rgb(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.colors.card};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ArrayItemContent = styled.div`
    flex-grow: 1;
`;

const TopLevelArrayNodeContainer = styled.ol`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    min-height: 75px;

    position: relative;
    background-color: ${({ theme }) => theme.colors.background};

    overflow: hidden;

    & > * {
        grid-area: 1 / 1 / 1 / 1;
    }
`;

const EmbeddedArrayNodeContainer = styled.ol`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    min-height: 75px;

    border-color: rgb(0, 0, 0, 0.2);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;

    position: relative;
    background-color: ${({ theme }) => theme.colors.background};

    & > * {
        grid-area: 1 / 1 / 1 / 1;
    }
`;

const DeleteButton = styled.button`
    border: none;
    background-color: unset;
    align-self: start;
    color: rgb(0, 0, 0, 0.33);
    margin-left: 5px;
    font-size: x-large;
    margin-top: 10px;

    &:hover {
        color: cyan;
    }
`;

const NoPointerEventDiv = styled.div`
    pointer-events: none;
`;

const ScrollDiv = styled.div`
    overflow: scroll;
    padding-bottom: 15px;
    padding-left: 7px;
    padding-right: 7px;
`;

const ShadowDiv = styled.div`
    width: 150%;
    transform: translate(-25%, 0%);
    box-shadow:
        inset 0px 5px 5px rgb(0, 0, 0, 0.2),
        inset 0px -3px 5px rgb(0, 0, 0, 0.2);
    pointer-events: none;
`;

export default function ArrayNode({
    children,
    createElement,
    deleteElement,
    ctx,
    data,
}) {
    const [removed, setRemoved] = react.useState([]);
    const topLevel = ctx.displayHierarchy.length === 0;

    function addElement() {
        createElement(removed[removed.length - 1]);
        setRemoved(removed.slice(0, -1));
    }

    function doDeleteElement(i) {
        setRemoved([...removed, data?.[i]]);
        deleteElement(i);
    }

    let errorMessage;
    if (
        ctx.validationErrors[ctx.path] &&
        (ctx.allErrors || ctx.touched.as(ctx.path))
    ) {
        errorMessage = `Item must be ${
            ctx.validationErrors[ctx.path].expected
        }.`;
    }

    const Container = topLevel
        ? TopLevelArrayNodeContainer
        : EmbeddedArrayNodeContainer;

    return (
        <Container>
            <ScrollDiv>
                {children.map((child, i) => (
                    <ArrayItem key={`${ctx.path}/${i}`}>
                        <ArrayItemContent>{child}</ArrayItemContent>
                        <DeleteButton onClick={() => doDeleteElement(i)}>
                            <FaTrashAlt />
                        </DeleteButton>
                        <When condition={errorMessage}>
                            <ValidationMessage>
                                {errorMessage}
                            </ValidationMessage>
                        </When>
                    </ArrayItem>
                ))}
            </ScrollDiv>
            <When condition={topLevel}>
                <ShadowDiv />
            </When>
            <NoPointerEventDiv>
                <AddButton aria-label='Add Item' onClick={() => addElement()}>
                    <GoPlus />
                </AddButton>
            </NoPointerEventDiv>
        </Container>
    );
}
