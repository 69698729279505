import entrypointLayout from './entrypoint-layout/entrypoint-layout.jsx';
import jobPageRoute from './pages/job-page.jsx';
import shipLogPageRoute from './pages/ship-log-page.jsx';
import shipOrderPageRoute from './pages/ship-order-page.jsx';
import shipOrderQueuePageRoute from './pages/ship-order-queue-page.jsx';
import shipPageRoute from './pages/ship-page.jsx';
import stationMarketOrderPageRouter from './pages/station-market-order-page.jsx';
import stationMarketPageRouter from './pages/station-market-page.jsx';
import stationPageRoute from './pages/station-page.jsx';

import { Fragment, memo } from 'react';
import { Outlet, useOutletContext, useRouteError } from 'react-router-dom';

import { MainContent, SizedMapDisplay } from './utils/components.jsx';

// Map layout provides a title/subtitle block, navigation buttons, and an
// annotatable map.
export default {
    Component: memo(function MapLayoutComponent() {
        const baseLayout = useOutletContext();

        return (
            <Fragment>
                <SizedMapDisplay />
                <MainContent>
                    <Outlet context={baseLayout} />
                </MainContent>
            </Fragment>
        );
    }),
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    children: [
        entrypointLayout,
        jobPageRoute,
        shipLogPageRoute,
        shipOrderPageRoute,
        shipOrderQueuePageRoute,
        shipPageRoute,
        stationMarketOrderPageRouter,
        stationMarketPageRouter,
        stationPageRoute,
    ],
};
