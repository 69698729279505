import jobsPageRoute from './pages/jobs-page.jsx';
import RootPage from '../../../components/pages/EntrypointLayout.jsx';
import shipsPageRoute from './pages/ships-page.jsx';
import stationsPageRoute from './pages/stations-page.jsx';

import { useRouteError } from 'react-router-dom';

export default {
    Component: RootPage,
    ErrorBoundary: () => {
        const e = useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    children: [jobsPageRoute, shipsPageRoute, stationsPageRoute],
};
