import Model from '../shared/model.mjs';

export default {
    ClientError: (msg) => {
        console.error('out of band ClientError', msg);
    },
    FullModel: (msg, { setExtras, setModel, setOptimisticMasks }) => {
        setModel(new Model(msg.model));
        setExtras((extras) => ({ ...extras, resync: false }));
        setOptimisticMasks([]);
    },
    ModelUpdate: (msg, { sendToServer, setExtras, setModel }) => {
        setModel((curState) => {
            if (!curState) {
                return;
            }

            const nextModel = curState.shallowCopy();
            try {
                nextModel.applyDiff(msg.diff);
                nextModel.mask.clear();
            } catch (e) {
                console.error('ModelUpdate failed', e);

                setExtras((extras) => ({ ...extras, resync: true }));

                sendToServer({ type: 'Sync' })
                    .then((m) => {
                        setModel(new Model(m.model));
                        setExtras((extras) => ({ ...extras, resync: false }));
                    })
                    .catch((e) => {
                        console.log('sync error', e);
                    });
            }

            return nextModel;
        });
    },
};
