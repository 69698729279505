import clone from 'clone';
import jsonPointer from 'json-pointer';
import react from 'react';
import * as shipUtil from '../utils/utils.mjs';

export default function useFlexState() {
    const [state, dispatch] = react.useReducer((state, actions) => {
        if (!Array.isArray(actions)) {
            actions = [actions];
        }

        let newValue = clone(state);

        for (const update of actions) {
            if (update.path === '') {
                newValue = update.value;
            } else {
                if (update.value === undefined) {
                    if (jsonPointer.has(newValue, update.path)) {
                        jsonPointer.remove(newValue, update.path);
                    }
                } else {
                    shipUtil.set(newValue, update.path, clone(update.value));
                }
            }
        }

        return newValue;
    }, {});

    const initialValues = react.useRef({});

    return react.useMemo(
        () => ({
            buildWhiteboard(path) {
                return [
                    clone(this.get(path) ?? {}),
                    (update) => {
                        const newValue = { ...this.get(path) };

                        for (const [k, v] of Object.entries(update)) {
                            if (v === undefined) {
                                delete newValue[k];
                            } else {
                                newValue[k] = clone(v);
                            }
                        }

                        this.set(path, newValue);
                    },
                ];
            },

            get(path) {
                return shipUtil.get(state, path);
            },

            set(path, value) {
                if (typeof value === 'function') {
                    const newValue = value(shipUtil.get(state, path));
                    dispatch({ path, value: newValue });
                } else {
                    dispatch({ path, value });
                }
            },

            toString() {
                return JSON.stringify(state, null, 4);
            },

            useState(path, defaultValue) {
                if (!(path in initialValues.current)) {
                    initialValues.current[path] = defaultValue;
                }

                return [
                    this.get(path) === undefined
                        ? initialValues.current[path]
                        : this.get(path),
                    (v) => this.set(path, v),
                ];
            },
        }),
        [state, dispatch],
    );
}
