import clone from 'clone';
import Observable from '../../../../utils/Observable.mjs';
import OptionalGroup from '../../OptionalGroup.jsx';
import react from 'react';
import ShipErrorBoundary from '../../ShipErrorBoundary.jsx';
import * as shipUtil from '../../../../utils/utils.mjs';

import { buildUi } from './build-ui.jsx';
import { css } from '@emotion/react';
import { When } from 'react-if';
import {
    Container,
    MyValidationMessage,
    SimpleFieldTable,
    SimpleFieldRow,
    SimpleFieldRowHeader,
    SimpleFieldData,
    SimpleFieldLabel,
} from './StructNode.jsx';

const wideMarginCss = css`
    margin-left: 30px;
    margin-right: 30px;
`;

export default function buildStructNode(
    spec,
    value,
    expectedType,
    onChange,
    ctx,
) {
    value = { ...value };

    const subComponents = [];
    const simpleSubComponents = [];

    // In some case we can't distinguish between an intentionally absent
    // optional value ("I don't want to provide an array...") and its equivalent
    // default value ("An empty array"). We use this state to track which one we
    // "mean".
    const [optionalChecked, setOptionalChecked] = ctx.flexState.useState(
        `${ctx.path}/_optionals`,
        Object.fromEntries(Object.entries(value).map(([k, v]) => [k, !!v])),
    );

    const properties = {
        fields: {},
    };
    for (const [field] of Object.entries(spec).filter(
        ([k]) => !k.startsWith('$'),
    )) {
        properties.structured = true;

        let errorMessage;

        const [
            subSpec,
            {
                context: subContext,
                expectedType: subExpectedType,
                lexicalContextStack: newLexicalContextStack,
            },
        ] = shipUtil.effectiveSpec(
            ctx.context,
            expectedType,
            spec,
            field,
            ctx.lexicalContextStack,
        );

        const debugText = `expectedType: ${expectedType}, context: ${ctx.context} ${subContext ? `-> ${subContext}` : ''}`;

        let c, p, d;

        try {
            [c, p, d] = buildUi(
                subSpec,
                value?.[field],
                subExpectedType,
                (v, opts) => {
                    const newVal = { ...value };

                    if (v === undefined) {
                        delete newVal[field];
                    } else {
                        newVal[field] = v;
                    }

                    onChange(newVal, opts);
                },
                {
                    ...ctx,

                    context: subContext ?? ctx.context,
                    extraComponents: ctx?.extraComponents?.[field],
                    displayContext: ctx.displayContext.child(field),
                    displayHierarchy: [...ctx.displayHierarchy, 'Struct'],
                    lexicalContextStack: newLexicalContextStack,
                    path: `${ctx.path}/${field}`,
                    touched: shipUtil.removeDictLayer(ctx.touched, field),
                    validationErrors: shipUtil.removeDictLayer(
                        ctx.validationErrors,
                        field,
                    ),
                },
            );
        } catch (e) {
            c = <ShipErrorBoundary force={e.message} json={value?.[field]} />;
            p = {};
            d = value?.[field];
        }

        if (
            ctx.validationErrors[`/${field}`] &&
            (ctx.touched.has(`/${field}`) || ctx.touched.has(''))
        ) {
            const error = ctx.validationErrors[`/${field}`];
            errorMessage = shipUtil.isNil(error.actual)
                ? `${p.label ?? field} must be provided.`
                : `${p.label ?? field} must be ${error.expected}.`;
        }

        value[field] = d;
        properties.fields[field] = p;

        if (p?.committer) {
            if (!properties.committer) {
                properties.committer = new Observable();
            }

            p.committer.on('cancel', () => properties.committer.fire('cancel'));

            p.committer.on('commit', (v) => {
                properties.committer.fire('commit', {
                    ...value,
                    [field]: v,
                });
            });
        }

        if (p.structured) {
            let maybeReplacedC = c;

            if (
                ctx.displayHierarchy.filter((x) =>
                    ['Struct', 'DiscriminatorNode'].includes(x),
                ).length > 3
            ) {
                const [, , breadcrumbs] = ctx.displayContext.buildDisplayRoot(
                    value?.[field],
                );
                const title = breadcrumbs.join(' > ');

                maybeReplacedC = <pre>{ctx.prettyPrint('', subSpec, d)}</pre>;
                p.note = shipUtil.isTouchDevice()
                    ? 'Tap to Edit'
                    : 'Click to Edit';
                p.onClick = async () => {
                    const newValue = await ctx.queryUser(subSpec, d, {
                        context: subContext,
                        expectedType: subExpectedType,
                        title,
                        lexicalContextStack: newLexicalContextStack,
                    });

                    if (newValue !== undefined) {
                        onChange({
                            ...d,
                            [field]: newValue,
                        });
                    }
                };
            }

            let groupComponent = (
                <OptionalGroup
                    checked={
                        subSpec?.$optional
                            ? optionalChecked[field] ?? false
                            : undefined
                    }
                    debugText={debugText}
                    key={`${ctx.path}_${field}`}
                    note={p.note}
                    onCheckedChange={
                        !subSpec?.$optional
                            ? null
                            : () => {
                                  if (optionalChecked[field]) {
                                      ctx.flexState.set(
                                          `/nodeimpl/struct${ctx.path}/${field}`,
                                          clone(value[field]),
                                      );

                                      const newValue = { ...value };
                                      delete newValue[field];
                                      onChange(newValue);

                                      setOptionalChecked({
                                          ...optionalChecked,
                                          [field]: false,
                                      });
                                  } else {
                                      onChange({
                                          ...value,
                                          [field]: ctx.flexState.get(
                                              `/nodeimpl/struct${ctx.path}/${field}`,
                                          ),
                                      });

                                      setOptionalChecked({
                                          ...optionalChecked,
                                          [field]: true,
                                      });
                                  }
                              }
                    }
                    onContentClick={p.onClick}
                    title={subSpec?.$label ?? field}
                    variant={
                        ctx.displayHierarchy.length > 1 ? 'embedded' : 'top'
                    }
                >
                    {!subSpec?.$optional || optionalChecked[field] ? (
                        <react.Fragment>
                            {maybeReplacedC}
                            <When condition={errorMessage}>
                                <MyValidationMessage>
                                    {errorMessage}
                                </MyValidationMessage>
                            </When>
                        </react.Fragment>
                    ) : undefined}
                </OptionalGroup>
            );

            subComponents.push([
                { ...p, optional: !!subSpec?.$optional },
                groupComponent,
            ]);
        } else {
            // Simple value.
            if (!subSpec?.$optional) {
                if (p.selfContained) {
                    simpleSubComponents.push([
                        p,
                        <react.Fragment key={`${ctx.path}_${field}`}>
                            <SimpleFieldRow
                                css={css`
                                    padding-left: 10px;
                                    padding-right: 10px;
                                `}
                            >
                                {c}
                            </SimpleFieldRow>
                            <When condition={errorMessage}>
                                <MyValidationMessage>
                                    {errorMessage}
                                </MyValidationMessage>
                            </When>
                        </react.Fragment>,
                    ]);
                } else {
                    simpleSubComponents.push([
                        p,
                        <react.Fragment key={`${ctx.path}_${field}`}>
                            <SimpleFieldRow>
                                <SimpleFieldRowHeader
                                    scope='row'
                                    htmlFor={`${ctx.path}/${field}`}
                                >
                                    <SimpleFieldLabel popover={debugText}>
                                        {subSpec.$label ?? field}
                                    </SimpleFieldLabel>
                                </SimpleFieldRowHeader>
                                <SimpleFieldData>{c}</SimpleFieldData>
                            </SimpleFieldRow>
                            <When condition={errorMessage}>
                                <MyValidationMessage>
                                    {errorMessage}
                                </MyValidationMessage>
                            </When>
                        </react.Fragment>,
                    ]);
                }
            }
        }
    }

    simpleSubComponents.sort(
        ([{ optional: optional1 } = {}], [{ optional: optional2 } = {}]) => {
            // Non-optional above optional.
            if (!!optional1 !== !!optional2) {
                return optional1 ? 1 : -1;
            }

            return 0;
        },
    );

    subComponents.sort(
        (
            [{ structured: structured1, optional: optional1 } = {}],
            [{ structured: structured2, optional: optional2 } = {}],
        ) => {
            // Non-structured above structured.
            if (!!structured1 !== !!structured2) {
                return structured1 ? 1 : -1;
            }

            // Non-optional above optional.
            if (!!optional1 !== !!optional2) {
                return optional1 ? 1 : -1;
            }

            return 0;
        },
    );

    return [
        // eslint-disable-next-line react/jsx-key
        <Container>
            <When condition={simpleSubComponents.length > 0}>
                <SimpleFieldTable
                    css={ctx.displayHierarchy.length <= 1 ? wideMarginCss : []}
                >
                    {simpleSubComponents.map(([, c]) => c)}
                </SimpleFieldTable>
            </When>
            {subComponents.map(([, c]) => c)}
        </Container>,
        properties,
        value,
    ];
}
