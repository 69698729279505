import CenterLayout from '../fragments/CenterLayout.jsx';
import CredSym from '../fragments/CredSym.jsx';
import ModuleTile from '../fragments/ModuleTile.jsx';
import ms from 'ms';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';
import useNavigate from '../../hooks/use-navigate.jsx';
import useVolatileDataWrapper from '../../hooks/use-volatile-data-wrapper.jsx';
import useWhiteboard from '../../hooks/use-whiteboard.jsx';
import VerticalMainLayout from '../fragments/VerticalMainLayout.jsx';

import { Button, ValidationMessage } from '../fragments/standard.jsx';
import { css } from '@emotion/react';
import { GameStateContext } from '../../app-contexts.jsx';

const MarketOrderMessageStack = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 150px;
    padding: 15px;
    gap: 5px;
`;

export default function MarketOrder(props) {
    const { stationId, orderId } = props;

    const { model } = react.useContext(GameStateContext);
    const orderData = shipUtil.unmessy(
        model.get(`/${stationId}/market/${orderId}`)
    );

    const [purchased, setPurchased] = react.useState();
    const navigate = useNavigate(props.whiteboard, 'corp');

    const redirect = react.useRef();
    react.useEffect(() => {
        if (purchased && !redirect.current) {
            redirect.current = setTimeout(
                () => navigate(`/stations/${stationId}`),
                ms('2s')
            );
        }
    }, [purchased, navigate, redirect.current]);

    react.useEffect(() => {
        return () => clearTimeout(redirect.current);
    }, []);

    return useVolatileDataWrapper(
        props,
        purchased ? undefined : orderData,
        purchased ? (
            <p>Purchase successful!</p>
        ) : (
            <p>This market order no longer exists.</p>
        ),
        ({ data }) =>
            data && (
                <LoadedMarketOrder
                    data={data}
                    setPurchased={setPurchased}
                    stationId={stationId}
                    orderId={orderId}
                    purchased={purchased}
                    whiteboard={props.whiteboard}
                />
            )
    );
}

function LoadedMarketOrder({
    data: orderData,
    orderId,
    purchased,
    setPurchased,
    stationId,
    whiteboard,
}) {
    const { model, sendToServer } = react.useContext(GameStateContext);
    const moduleData = model.get(orderData.item);
    const [wbVars] = useWhiteboard(whiteboard);
    const navigate = useNavigate();

    const [error, setError] = react.useState();

    async function onBuyClick() {
        try {
            await sendToServer({
                type: 'Transact',
                action: 'buy',
                stationId,
                orderId,
                corpId: wbVars.corp,
                count: 1,
            });

            setPurchased(true);
        } catch (e) {
            if (e.code === 'INSUFFICIENT_FUNDS') {
                setError("Couldn't buy: not enough credits.");
            } else if (e.code === 'INSUFFICIENT_RESOURCE') {
                setError(
                    `Couldn't buy: not enough ` +
                        `${shipUtil
                            .humanSynthesizedPropName(e.resource)
                            .toLowerCase()}.`
                );
            } else if (e.code === 'NO_SUCH_ORDER') {
                setError("Couldn't buy: this order no longer exists.");
            } else if (e.code === 'COUNT_EXCEEDS_AVAILABLE') {
                setError("Couldn't buy: insufficient stock.");
            } else {
                console.error(e);
                setError('Something went wrong. Try again.');
            }
        }
    }

    const buttons = (
        <div
            css={css`
                display: flex;

                & > * {
                    flex-grow: 1;
                    flex-basis: 0;
                }
            `}
        >
            <Button size='large' onClick={() => navigate(-1)}>
                Cancel
            </Button>
            <Button variant='primary' size='large' onClick={onBuyClick}>
                Buy
            </Button>
        </div>
    );

    const blockReason = wbVars.corp
        ? shipUtil.purchaseBlocker(model, stationId, wbVars.corp, orderId)
        : undefined;

    const messages = [];

    if (!purchased) {
        if (blockReason) {
            messages.push(blockReason);
        }

        if (error) {
            messages.push(error);
        }
    }

    const infoStack = (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}
        >
            <ModuleTile data={moduleData} />
            <p
                css={css`
                    font-weight: 600;
                    font-size: small;
                `}
            >
                x1
            </p>
            <p
                css={css`
                    margin-top: 1rem;
                `}
            >
                Final price:&nbsp;
                <CredSym>{orderData.price}</CredSym>
            </p>
        </div>
    );

    return (
        <VerticalMainLayout
            css={css`
                overflow: hidden;
            `}
            footer={buttons}
        >
            <VerticalMainLayout
                css={css`
                    overflow: hidden;
                `}
                footer={
                    <MarketOrderMessageStack>
                        {messages.map((m, i) => (
                            <ValidationMessage key={i}>{m}</ValidationMessage>
                        ))}
                    </MarketOrderMessageStack>
                }
            >
                <CenterLayout>{infoStack}</CenterLayout>
            </VerticalMainLayout>
        </VerticalMainLayout>
    );
}
