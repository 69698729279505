import HorizontalMainLayout from '../../HorizontalMainLayout.jsx';
import react from 'react';
import styled from '@emotion/styled';
import VerticalMainLayout from '../../VerticalMainLayout.jsx';

import { GameStateContext } from '../../../../app-contexts.jsx';

const SelectableList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: scroll;
    height: 100%;
`;

const SelectableListItem = styled.li`
    display: flex;
    flex-direction: row;
`;

const SelectButton = styled.button`
    flex-grow: 1;
    height: 60px;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.3);
    margin: 3px;
`;

export default function EntitySelectorNode({ onCommit, matcher }) {
    const { model } = react.useContext(GameStateContext);

    const matches = model
        .getMatches(matcher)
        .map((id) => [id, model.get(`/${id}`)]);

    const [filter, setFilter] = react.useState('');

    return (
        <VerticalMainLayout
            header={<SelectHeader matcher={matcher} />}
            footer={
                <Search
                    onChange={(e) =>
                        setFilter(e.target.value.trim().toLowerCase())
                    }
                />
            }
        >
            <SelectableList>
                {matches
                    .filter(
                        ([, v]) =>
                            !v.name || v.name.toLowerCase().includes(filter)
                    )
                    .map(([id, v]) => (
                        <SelectableListItem key={`SelectEntityModal_${id}`}>
                            <SelectButton
                                onClick={() => onCommit({ $ref: `/${id}` })}
                            >
                                {v.name}
                            </SelectButton>
                        </SelectableListItem>
                    ))}
            </SelectableList>
        </VerticalMainLayout>
    );
}

function Search({ onChange }) {
    const [value, setValue] = react.useState('');

    function handleChange(e) {
        setValue(e.target.value);
        onChange(e);
    }

    return (
        <HorizontalMainLayout left={<label htmlFor='search'>Search</label>}>
            <input
                value={value}
                onChange={handleChange}
                css={{ fontSize: '1.5rem', width: '100%' }}
                id='search'
            />
        </HorizontalMainLayout>
    );
}

function SelectHeader({ matcher }) {
    return <h2>{matcher.kind ? `Select a ${matcher.kind}` : 'Select'}</h2>;
}
