import hash from 'object-hash';

import { useRef } from 'react';

export default function useValue(v) {
    const instanceRef = useRef(v);

    if (
        v !== instanceRef.current &&
        myHash(instanceRef.current) !== myHash(v)
    ) {
        instanceRef.current = v;
    }

    return instanceRef.current;
}

function myHash(v) {
    return v === undefined
        ? '0000000000000000000000000000000000000000'
        : hash(v);
}
