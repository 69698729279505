import { createPortal } from 'react-dom';
import { useOutletContext } from 'react-router-dom';

export default function TopRightPortal({ children }) {
    const { topRightRef } = useOutletContext();

    if (!topRightRef.current) {
        return;
    }

    return createPortal(children, topRightRef.current);
}
