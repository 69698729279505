import styled from '@emotion/styled';
import react from 'react';

import { Popover } from 'react-tiny-popover';

const InlineDiv = styled.div`
    display: inline-block;
`;

const PopoverText = styled.p`
    background-color: white;
    box-shadow: 0px 0px 5px rgb(0, 0, 0);
`;

export default function DebugPopover({ children, className, popover }) {
    const [show, setShow] = react.useState();

    if (typeof popover === 'string') {
        popover = <PopoverText>{popover}</PopoverText>;
    }

    return (
        <Popover
            isOpen={show}
            content={popover}
            positions={['top', 'bottom', 'left', 'right']}
        >
            <InlineDiv
                className={className}
                onMouseOver={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {children}
            </InlineDiv>
        </Popover>
    );
}
